import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import AddCarrierDisplay from "./AddCarrierDisplay"
import { requestCarrierList } from "./requests"
import GlobalSpinner from "../common/GlobalSpinner"

const AddCarrier = ({
    allCarriers,
    history,
    onboardingLocation,
    carrierListLoading,
    addableCarriers,
}) => {
    const onCarrierSelect = carrier => {
        history.push({
            pathname: `/carrier/add/${carrier?.capacityProviderIdentifiers?.[0]?.value}`,
            state: history.location.state,
        })
    }

    return carrierListLoading ? (
        <GlobalSpinner />
    ) : (
        <Grid container>
            {addableCarriers && (
                <AddCarrierDisplay
                    onCarrierSelect={onCarrierSelect}
                    carrierList={addableCarriers}
                />
            )}
        </Grid>
    )
}

AddCarrier.propTypes = {
    history: PropTypes.object.isRequired,
}

export default AddCarrier
