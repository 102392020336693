import React, { Component } from "react"
import { Grid, Typography, Button, Chip, Avatar } from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import PlaceIcon from "@material-ui/icons/Place"
import { connect } from "react-redux"
import { get, isArray } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { Field, SubmissionError, reduxForm, change } from "redux-form"
import AddRecipients from "./addRecipients"
import { addUsersToLocation, trackGAEvent } from "../../../actions/user"
import Permissions from "./permissions"
import RoleCopy from "./roleCopy"
import FormSelect from "../../form/form-select"
import CircularProgress from "@material-ui/core/CircularProgress"
import { combineValidators } from "revalidate"
import { emailValidator, isRequired } from "../../../actions/validation"
import { FormattedMessage, injectIntl, defineMessages } from "react-intl"
import {
    preferenceLanguageOptions,
    defaultLanguage,
} from "../../constants/preferenceLanguageOptions"
import formSelectBillTo from "../../form/form-select-bill-to"
import { constructLabel, constructSubLabel } from "../../util/index"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
        overflowY: "scroll",
    },
    location__information: {
        padding: "10px 0",
    },
    addUser__permissions: {
        padding: "15px 5px 5px 5px",
        minHeight: "325px",
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    addUser__error: {
        color: "red",
    },
    chip_container: {
        maxHeight: "185px",
        overflow: "auto",
    },
    chip: {
        margin: "5px 5px",
        backgroundColor: theme.palette.primary.light,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
})

const roleOptionsMessages = defineMessages({
    chooseARole: {
        id: "locations.users__chooseARole",
        defaultMessage: "Choose a role",
    },
    owner: { id: "locations.users__owner", defaultMessage: "Owner" },
    companyUser: {
        id: "locations.users__companyUser",
        defaultMessage: "Company User",
    },
    thirdPartyVendor: {
        id: "locations.users__thirdPartyVendor",
        defaultMessage: "Third Party Vendor",
    },
})

const roleOptions = [
    {
        value: undefined,
        label: roleOptionsMessages.chooseARole,
        disabled: true,
    },
    {
        value: "owner",
        label: roleOptionsMessages.owner,
    },
    {
        value: "member",
        label: roleOptionsMessages.companyUser,
    },
    {
        value: "thirdParty",
        label: roleOptionsMessages.thirdPartyVendor,
    },
]

class AddUser extends Component {
    onLocationDelete(location) {
        let newSelectedLocations = this.props.selectedLocations.filter(
            s => s.id !== location.id
        )
        this.props.changeField("selectedLocations", newSelectedLocations)
    }

    render() {
        const {
            classes,
            handleClose,
            confirmAddUser,
            index,
            allLocations,
            changeField,
            information,
            currentRole,
            handleSubmit,
            submitting,
            form,
            error,
            gaCategory,
            intl,
            trackGA,
            selectedLocations,
        } = this.props

        const formattedLocations = allLocations?.map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                id: item._id,
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                thirdparty: item.is3rdPartyEnabled,
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item.locationType === "FEDEX_FREIGHT_BOX",
                fedexFreightAccountType: item.fedexFreightAccountType,
                fedexBillToAccountType: item.fedexBillToAccountType,
            }
        })

        const availableOptions = formattedLocations?.filter(
            location =>
                !this.props.selectedLocations?.some(
                    loc => loc.id === location.id
                ) && location.id !== information._id
        )

        const { city, state: addressState } =
            information?.shippingAddress?.address ?? {}
        const defaultNickname = `${information?.shippingAddress?.name} - ${city}, ${addressState}`

        const defaultLocationLabel = constructLabel(
            information?.cpgName,
            defaultNickname
        )

        return (
            <Grid className={classes.paper}>
                <form>
                    <Grid item container>
                        <Typography variant="h5">
                            <FormattedMessage
                                id="locations.addUserModal__title"
                                defaultMessage="Add user(s) to location(s)"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.location__information}
                    >
                        <Typography variant="subtitle1">{`${get(
                            information,
                            "shippingAddress.name"
                        )}`}</Typography>
                        <Typography variant="subtitle1">
                            {`${information?.shippingAddress?.address?.street1}, ${information?.shippingAddress?.address?.city} ${information?.shippingAddress?.address?.state} ${information?.shippingAddress?.address?.postalCode}`}
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Grid
                            item
                            container
                            xs={7}
                            style={{ flexDirection: "column" }}
                        >
                            <Grid item container>
                                <Field
                                    component={formSelectBillTo}
                                    name="cpg"
                                    label={
                                        <FormattedMessage
                                            id="locations.addUserModal__SelectLocation"
                                            defaultMessage="Select Locations"
                                        />
                                    }
                                    onChange={option => {
                                        let currentSelections =
                                            this.props.selectedLocations ?? []
                                        let newSelections
                                        if (isArray(currentSelections)) {
                                            newSelections = [
                                                ...currentSelections,
                                            ]
                                            newSelections.push(option)
                                        } else {
                                            newSelections = [option]
                                        }

                                        changeField(
                                            "selectedLocations",
                                            newSelections
                                        )
                                    }}
                                    InputTextProps={{ value: "" }}
                                    options={availableOptions}
                                    billTo
                                    autoFocus
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.chip_container}
                            >
                                <Chip
                                    tabIndex={-1}
                                    avatar={
                                        <Avatar
                                            className={classes.chip__avatar}
                                        >
                                            <PlaceIcon />
                                        </Avatar>
                                    }
                                    label={defaultLocationLabel}
                                    className={classes.chip}
                                />
                                {selectedLocations?.map((location, i) => (
                                    <Chip
                                        tabIndex={-1}
                                        avatar={
                                            <Avatar
                                                className={classes.chip__avatar}
                                            >
                                                <PlaceIcon />
                                            </Avatar>
                                        }
                                        key={i}
                                        label={location.label}
                                        className={classes.chip}
                                        deleteIcon={<CancelIcon />}
                                        onDelete={() => {
                                            this.onLocationDelete(location)
                                            trackGA(
                                                gaCategory,
                                                "Delete Location Chip Click"
                                            )
                                        }}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item container xs={5} direction="column">
                            <AddRecipients
                                formName={form}
                                gaCategory={gaCategory}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.addUser__permissions}
                        justify="space-between"
                    >
                        <Grid
                            item
                            container
                            xs={4}
                            id="addUser__selectRole__container"
                        >
                            <Field
                                component={FormSelect}
                                name="role"
                                id={"addUser__selectRole"}
                                label={[
                                    "* ",
                                    <FormattedMessage
                                        id="locations.addUserModal__role"
                                        defaultMessage="Role"
                                    />,
                                ]}
                                options={roleOptions}
                                category={gaCategory}
                            />
                            <RoleCopy currentRole={currentRole} />
                        </Grid>
                        <Grid item container xs={7}>
                            <Permissions
                                classes={classes}
                                index={index}
                                gridSize={12}
                                defaultMode
                                location={information}
                                gaCategory={gaCategory}
                            />
                            <Grid item container xs={6}>
                                <Field
                                    name="language"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__languagePreference"
                                            defaultMessage="Language Preference"
                                        />
                                    }
                                    component={FormSelect}
                                    options={preferenceLanguageOptions}
                                    category={gaCategory}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justify="space-between"
                        className={classes.button__container}
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                handleClose()
                            }}
                            className={classes.leftButton}
                        >
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        {error && (
                            <Typography
                                variant="body2"
                                className={classes.addUser__error}
                            >
                                {error}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(confirmAddUser)}
                            id="addUser__confirm"
                        >
                            <FormattedMessage
                                id="locations.addUserModal__confirm"
                                defaultMessage="Add User(s)"
                            />
                        </Button>
                        {submitting && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={48}
                                color="secondary"
                            />
                        )}
                    </Grid>
                </form>
            </Grid>
        )
    }
}

const userValidation = combineValidators({
    role: isRequired({
        field: {
            id: "locations.addUserModal__role",
            defaultMessage: "Role",
        },
    }),
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = (state, props) => {
    const formName = "addUser"

    return {
        permissions: get(state, ["form", formName, "values", "permissions"]),
        role: get(state, ["form", formName, "values", "role"]),
        initialValues: {
            selectedRecipients: [],
            selectedLocations: [],
            language:
                state?.user?.profile?.preferences?.language || defaultLanguage,
        },
        currentRole: get(state, "form.addUser.values.role", ""),
        selectedLocations: get(state, [
            "form",
            formName,
            "values",
            "selectedLocations",
        ]),
        formName,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
    changeField: (field, value) => dispatch(change("addUser", field, value)),
    confirmAddUser: async values => {
        const { information } = props
        const {
            selectedRecipients,
            permissions,
            role,
            newEmailRecipient,
            language,
            selectedLocations,
        } = values

        if (!selectedRecipients.length) {
            throw new SubmissionError({
                _error: (
                    <FormattedMessage
                        id="locations.addUserModal__noUsersAddedError"
                        defaultMessage="Please add at least one user"
                    />
                ),
            })
        }
        if (get(newEmailRecipient, "length")) {
            throw new SubmissionError({
                newEmailRecipient: (
                    <FormattedMessage
                        id="locations.addUserModal__lastEmailError"
                        defaultMessage="It looks like you forgot to add the last email address"
                    />
                ),
            })``
        }
        await dispatch(
            addUsersToLocation({
                selectedRecipients,
                permissions,
                role,
                locationId: information._id,
                language,
            })
        )
        selectedLocations?.forEach(async location => {
            await dispatch(
                addUsersToLocation({
                    selectedRecipients,
                    permissions,
                    role,
                    locationId: location.id,
                    language,
                })
            )
        })
        dispatch(
            trackGAEvent(props.gaCategory, "Add User Modal - Add User Click")
        )
        props.handleClose()
    },
})

export const AddUserModal = withStyles(styles)(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                form: "addUser", // a unique identifier for this form
                enableReinitialize: true,
                validate: userValidation,
            })(AddUser)
        )
    )
)
