import React, { useEffect, useState, useRef } from "react"
import Grid from "@material-ui/core/Grid"
import BasicInfoSummary from "../summaryCards/BasicInfoSummary"
import { useIntl, FormattedMessage } from "react-intl"
import FormDatePicker from "../../../form/form-datepicker"
import { Field, reduxForm } from "redux-form"
import FormSelectBillTo from "../../../form/form-select-bill-to"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { nextBusinessDay } from "../selector"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import FormRadio from "../../../form/form-radio"
import { modeOptions } from "../atoms/modeOptions"
import Typography from "@material-ui/core/Typography"
import FormCheckbox from "../../../form/form-checkbox"
import { searchShipmentByProNumber } from "../../../../actions/quote-request"
import SearchIcon from "@material-ui/icons/Search"
import FormField from "../../../form/form-field"
import ProNumberResult from "../../../quotesPage/proNumberResult/proNumberResult"
import { bookShipmentFormValidation } from "../validators"
import LocationLabel from "../../../common/LocationLabel"
import {
    allPaymentOptions,
    inboundPaymentOptions,
} from "../atoms/paymentOptions"
import { useFlags } from "launchdarkly-react-client-sdk"
import { Box } from "@material-ui/core"

const useStyles = makeStyles({
    basicInfoContainer: {
        padding: "0 15%",
    },
    cancelButton: {
        marginRight: "10px",
    },
    section: {
        paddingTop: "10px",
    },
    search__icon: {
        fontSize: "40px",
        color: "#4d148c",
        "&:hover": {
            color: "#ff6200",
            cursor: "pointer",
        },
        "&:active": {
            color: "#4d148c",
            cursor: "pointer",
        },
        padding: "3%",
        marginTop: "18px",
    },
    search__icon__disabled: {
        fontSize: "40px",
        padding: "3%",
        marginTop: "18px",
        color: "#BDBDBD",
    },
    text: {
        paddingLeft: "3px",
    },
    locationLabel: {
        paddingLeft: "3px",
    },
    radioLabels: {
        fontSize: ".835rem",
        color: "#636363",
    },
})

const GA_CATEGORY = "Book Shipment - Basic Info"

const BasicInfo = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formattedLocations,
    changeField,
    locations = [],
    inputProps,
    formValues = {},
    initialValues,
    invalid,
    trackGA,
    isBookingQuickRate,
    setIsControlledFlow,
    IntercomAPI,
    isBookingOrder,
}) => {
    useEffect(() => {
        if (currentStep === 0) {
            if (!isBookingQuickRate && !isBookingOrder) {
                IntercomAPI("trackEvent", "book-shipment-basic-info")
            }
        }
        if (!editMode) {
            trackGA(
                GA_CATEGORY,
                "Start Book Shipment",
                `${initialValues?.selectedLocation?.locationType} - ${
                    initialValues?.selectedLocation?.is3rdPartyEnabled
                        ? "THIRD_PARTY"
                        : "SHIPPER"
                } - ${initialValues?.selectedLocation?.fedexBillToAccountType ??
                    initialValues?.selectedLocation?.fedexFreightAccountType}`
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { singleAccountWorkflow, singleAccountWorkflowReturns } = useFlags()
    const intl = useIntl()
    const classes = useStyles()
    const [isProNumberSearch, setIsProNumberSearch] = useState(false)
    const [shipmentsProNumberSearch, setShipmentsProNumberSearch] = useState({
        error: null,
        result: null,
        loading: false,
    })
    const [isReturning, setIsReturning] = useState(false)
    const [returnsTrackingId, setReturnsTrackingId] = useState(false)
    const shipDateRef = useRef(null)

    const {
        proNumber,
        isFreightDirectReturns,
        isFreightDirect,
        isFreightBox,
        mode,
        selectedLocation = {
            pickupAccessorials: [{ value: "DOCKPU" }],
            deliveryAccessorials: [{ value: "DOCKDEL" }],
        },
        shippingContact,
    } = formValues

    const isShipperOnlyFreightDirect =
        selectedLocation.isShipperEnabled &&
        !selectedLocation.is3rdPartyEnabled &&
        isFreightDirect

    const isDoubleAccountFreightDirect =
        selectedLocation.isShipperEnabled &&
        selectedLocation.is3rdPartyEnabled &&
        isFreightDirect

    const isDoubleAccountFreightDirectReturns =
        selectedLocation.isShipperEnabled &&
        selectedLocation.is3rdPartyEnabled &&
        isFreightDirectReturns

    const canSearchPro = proNumber && proNumber?.length >= 3

    const isFreightDirectReturnsLocation =
        selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

    const isFreightDirectLocation =
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isFreightBoxLocation =
        selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

    const isValidSingleAccountFlow =
        (singleAccountWorkflow || singleAccountWorkflowReturns) &&
        !isFreightDirectReturnsLocation &&
        !isFreightDirectLocation &&
        !isFreightBoxLocation &&
        selectedLocation?.fedexFreightAccountType !== "FXFM" &&
        selectedLocation?.fedexBillToAccountType !== "FXFM"

    //functions
    const searchProNumber = async () => {
        const proNumber = formValues?.proNumber
        if (!proNumber || proNumber.length < 3) return
        setIsProNumberSearch(true)
        trackGA(GA_CATEGORY, "FXFD Returns Pro Search")
        try {
            setShipmentsProNumberSearch({ loading: true })
            const shipments = await searchShipmentByProNumber(proNumber)
            if (shipments.length > 0) {
                setShipmentsProNumberSearch({
                    result: shipments[0],
                    loading: false,
                })
                changeField("proNumberSearchResult", true)
            } else {
                setShipmentsProNumberSearch({ result: null, loading: false })
                changeField("proNumberSearchResult", false)
            }
        } catch (err) {
            setShipmentsProNumberSearch({ error: err?.message })
            changeField("proNumberSearchResult", false)
        }
    }

    const beforeNextStep = () => {
        if (isBookingQuickRate) {
            if (mode === "inbound") {
                changeField("destination", {
                    ...selectedLocation,
                    contact: shippingContact,
                })
            } else if (mode === "outbound") {
                changeField("origin", {
                    ...selectedLocation,
                    contact: shippingContact,
                })
            }

            return
        }

        if (isFreightDirectReturns || isFreightDirect) {
            changeField("pickupAccessorials", [])
            changeField("deliveryAccessorials", [])
        }

        if (mode === "outbound") {
            changeField(
                "pickupAccessorials",
                selectedLocation?.pickupAccessorials?.map(entry => entry.value)
            )
        } else if (mode === "inbound") {
            changeField(
                "deliveryAccessorials",
                selectedLocation?.deliveryAccessorials?.map(
                    entry => entry.value
                )
            )
        }
    }

    const adjustBasedOnMode = (
        m,
        selectedLocation,
        isFreightDirectReturns,
        isFreightDirectOption
    ) => {
        const isFreightDirect =
            selectedLocation?.locationType === "FEDEX_DIRECT" ||
            isFreightDirectOption

        const isFreightBox =
            selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"
        if (
            isFreightBox ||
            isFreightDirect ||
            isFreightDirectReturns ||
            m === "thirdParty"
        ) {
            changeField("paymentType", null)
        }

        if (
            selectedLocation?.isShipperEnabled &&
            !isFreightDirect &&
            !isFreightBox &&
            !isFreightDirectReturns
        ) {
            if (m === "inbound") {
                changeField("paymentType", "COLLECT")
            } else if (m === "outbound") {
                changeField("paymentType", "PREPAID")
            }
        }

        if (m === "inbound") {
            if (!isFreightDirectReturns) {
                changeField("origin", {
                    shippingAddress: null,
                })
                changeField(
                    "pickupAccessorials",
                    initialValues?.pickupAccessorials
                )
            }

            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
            changeField("destination", {
                ...selectedLocation,
                contact: shippingContact,
            })
        } else if (m === "outbound") {
            if (!initialValues?.shouldDisPlayContact) {
                changeField("destination", {
                    shippingAddress: null,
                })
            } else {
                changeField("destination", {
                    ...initialValues?.destination,
                })
            }

            changeField("pickupAccessorials", initialValues?.pickupAccessorials)
            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
            changeField("origin", {
                ...selectedLocation,
                contact: shippingContact,
            })
        } else if (m === "thirdParty") {
            changeField("origin", { country: initialValues?.origin?.country })
            changeField("destination", {
                country: initialValues?.destination?.country,
            })
            changeField("pickupAccessorials", initialValues?.pickupAccessorials)
            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
        }
    }

    const onModeChange = (
        m,
        selectedLocation,
        isFreightDirectReturns,
        isFreightDirect
    ) => {
        setIsControlledFlow(true)
        adjustBasedOnMode(
            m,
            selectedLocation,
            isFreightDirectReturns,
            isFreightDirect
        )
    }

    const onBillToLocationChange = option => {
        if (editMode) {
            setIsControlledFlow(true)
        }

        const selectedLocation = locations.find(
            location => location?.cpgCode === option?.value
        )

        const isThirdPartyOnly =
            !selectedLocation?.isShipperEnabled &&
            selectedLocation?.is3rdPartyEnabled

        if (
            formValues?.selectedLocation?.cpgCode !== selectedLocation?.cpgCode
        ) {
            trackGA(
                GA_CATEGORY,
                "Bill to Location Change",
                `${selectedLocation?.locationType} - ${
                    selectedLocation?.is3rdPartyEnabled
                        ? "THIRD_PARTY"
                        : "SHIPPER"
                } - ${selectedLocation?.fedexBillToAccountType ??
                    selectedLocation?.fedexFreightAccountType}`
            )
        }

        const isFreightDirectReturns =
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        const isFreightDirect =
            selectedLocation?.locationType === "FEDEX_DIRECT"

        const isFreightBox =
            selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

        const newMode = isFreightDirectReturns
            ? "inbound"
            : isThirdPartyOnly
            ? "thirdParty"
            : "outbound"

        changeField("selectedLocation", selectedLocation)
        changeField("mode", newMode)
        onModeChange(newMode, selectedLocation, isFreightDirectReturns)
        changeField("isFreightDirect", isFreightDirect)
        changeField("isFreightDirectReturns", isFreightDirectReturns)
        changeField("isFreightBox", isFreightBox)
        changeField("pickupDate", moment.utc(moment().format("YYYY-MM-DD")))

        if (isFreightDirectReturns) {
            changeField("pickupDate", nextBusinessDay())
        } else {
            changeField("proNumberSearchResult", false)
        }
    }

    useEffect(() => {
        if (shipDateRef.current) {
            shipDateRef.current.addEventListener("keypress", event => {
                if (event.code === "Enter") {
                    event.preventDefault()
                    shipDateRef.current.click()
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipDateRef.current])

    if (currentStep === 0) {
        return (
            <Grid
                container
                justify="center"
                className={classes.basicInfoContainer}
            >
                <Grid item container justify="center">
                    <Typography id="basicInfoTitle" variant="h6">
                        <FormattedMessage
                            id="bookShipment.basicInfo__title"
                            defaultMessage="Tell us about your shipment."
                        />
                    </Typography>
                </Grid>
                <Grid item container className={classes.section}>
                    <Field
                        component={FormSelectBillTo}
                        name="cpg"
                        label={
                            <FormattedMessage
                                id="getRates.form__billToLocation"
                                defaultMessage="Bill to Location"
                            />
                        }
                        onChange={option => {
                            onBillToLocationChange(option)
                        }}
                        options={formattedLocations}
                        billTo
                        InputProps={inputProps}
                        autoFocus
                    />
                </Grid>
                {!isValidSingleAccountFlow && (
                    <Grid item container className={classes.locationLabel}>
                        <LocationLabel
                            isFreightBox={isFreightBox}
                            isFreightDirect={isFreightDirect}
                            isFreightDirectReturns={isFreightDirectReturns}
                        />
                    </Grid>
                )}
                {isValidSingleAccountFlow && singleAccountWorkflow && (
                    <Grid item container className={classes.section}>
                        <Field
                            component={FormCheckbox}
                            name="isFreightDirect"
                            label={
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirect"
                                    defaultMessage="FedEx Freight Direct"
                                />
                            }
                            onChange={(e, option) => {
                                if (option) {
                                    changeField("isFreightDirectReturns", false)
                                    changeField("mode", "outbound")
                                    onModeChange(
                                        "outbound",
                                        selectedLocation,
                                        false,
                                        option
                                    )
                                }
                            }}
                        />
                    </Grid>
                )}
                {(isFreightDirectLocation ||
                    (isValidSingleAccountFlow &&
                        singleAccountWorkflowReturns &&
                        (mode === "inbound" ||
                            formValues?.selectedLocation
                                ?.is3rdPartyEnabled))) && (
                    <Grid item container className={classes.section}>
                        <Field
                            component={FormCheckbox}
                            name="isFreightDirectReturns"
                            label={
                                <FormattedMessage
                                    id="getRates.form__freightDirectReturns"
                                    defaultMessage="Freight Direct Returns"
                                />
                            }
                            onChange={(e, option) => {
                                trackGA(
                                    GA_CATEGORY,
                                    "Freight Direct Returns Click",
                                    option ? "Select" : "Deselect"
                                )
                                changeField("isFreightDirect", false)

                                if (option) {
                                    if (formValues?.mode !== "thirdParty") {
                                        changeField("mode", "inbound")
                                    }
                                    changeField("pickupDate", nextBusinessDay())
                                    onModeChange(
                                        "inbound",
                                        selectedLocation,
                                        option
                                    )
                                } else {
                                    if (formValues?.mode !== "thirdParty") {
                                        changeField("mode", "outbound")
                                    }
                                    onModeChange(
                                        "outbound",
                                        selectedLocation,
                                        option
                                    )
                                }
                            }}
                        />
                    </Grid>
                )}

                {isFreightDirectReturns && (
                    <Grid item container direction="row">
                        <Grid xs={11} item container direction="row">
                            <Field
                                component={FormField}
                                name="proNumber"
                                label={
                                    <FormattedMessage
                                        id="getRates.form_searchForReturn"
                                        defaultMessage="Search for a Return (Optional)"
                                    />
                                }
                                placeholder={intl.formatMessage({
                                    id: "getRates.form__proNumber",
                                    defaultMessage: "Tracking Number",
                                })}
                            />
                        </Grid>
                        <Grid
                            xs={1}
                            item
                            container
                            justify="center"
                            alignItems="flex-end"
                        >
                            <SearchIcon
                                id="searchProNumberIcon"
                                className={
                                    canSearchPro
                                        ? classes.search__icon
                                        : classes.search__icon__disabled
                                }
                                onClick={searchProNumber}
                            />
                        </Grid>
                        {isReturning && (
                            <Grid item container className={classes.text}>
                                <Typography
                                    variant="body2"
                                    id="returnTrackingID"
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="bookShipment.basicInfo__returning"
                                        defaultMessage="Return Tracking ID #{returnsTrackingId}"
                                        values={{ returnsTrackingId }}
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item container className={classes.section}>
                    <Field
                        component={FormDatePicker}
                        name="pickupDate"
                        label={[
                            "* ",
                            <FormattedMessage
                                id="bookShipment.basicInfo__shipDate"
                                defaultMessage="Ship Date"
                            />,
                        ]}
                        placeholder={intl.formatMessage({
                            id: "getRates.form__date",
                            defaultMessage: "Date",
                        })}
                        minDate={
                            isFreightDirectReturns
                                ? nextBusinessDay()
                                : moment.utc(
                                      moment()
                                          .tz("Pacific/Honolulu")
                                          .format("YYYY-MM-DD")
                                  )
                        }
                        maxDate={
                            isFreightDirectReturns
                                ? moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(7, "days")
                                : moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(1, "year")
                        }
                        InputProps={inputProps}
                        inputRef={shipDateRef}
                        onChange={(e, value) => {
                            trackGA(
                                GA_CATEGORY,
                                "Ship Date Change",
                                `${moment(value).diff(moment(), "d")} days out`
                            )
                        }}
                    />
                </Grid>
                {!isFreightDirectReturns &&
                    !isShipperOnlyFreightDirect &&
                    formValues?.selectedLocation?.isShipperEnabled && (
                        <Grid item container className={classes.section}>
                            <Grid item container>
                                <Field
                                    name="mode"
                                    component={FormRadio}
                                    options={
                                        isDoubleAccountFreightDirect
                                            ? modeOptions.doubleAccountFreightDirect
                                            : isDoubleAccountFreightDirectReturns
                                            ? modeOptions.doubleAccountFreightDirectReturns
                                            : formValues?.selectedLocation
                                                  ?.is3rdPartyEnabled
                                            ? modeOptions.shipperAndThirdParty
                                            : modeOptions.shipperOnly
                                    }
                                    required
                                    onChange={(e, value) => {
                                        onModeChange(
                                            value,
                                            selectedLocation,
                                            isFreightDirectReturns
                                        )
                                        trackGA(
                                            GA_CATEGORY,
                                            "Direction Change",
                                            value
                                        )
                                    }}
                                    label={
                                        <Typography
                                            variant="body2"
                                            className={classes.radioLabels}
                                        >
                                            <FormattedMessage
                                                id="bookShipment.basicInfo__direction"
                                                defaultMessage="Direction"
                                            />
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                {!isFreightBox &&
                !isFreightDirect &&
                !isFreightDirectReturns &&
                mode !== "thirdParty" ? (
                    <Grid item container className={classes.section}>
                        <Grid item container>
                            <Field
                                name="paymentType"
                                component={FormRadio}
                                options={
                                    mode === "inbound"
                                        ? inboundPaymentOptions
                                        : allPaymentOptions
                                }
                                required
                                onChange={(e, value) =>
                                    trackGA(
                                        GA_CATEGORY,
                                        "Payment Terms Change",
                                        value
                                    )
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        className={classes.radioLabels}
                                    >
                                        <FormattedMessage
                                            id="bookShipment.basicInfo__paymentTerms"
                                            defaultMessage="Payment Terms"
                                        />
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                ) : null}

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            beforeNextStep()
                            handleUpdate()
                        }}
                        invalid={invalid}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                handleComplete(isInvalidNextButton)
                            } else {
                                beforeNextStep()
                                handleComplete()
                            }
                        }}
                        invalid={invalid}
                    />
                )}
                <ProNumberResult
                    isProNumberSearch={isProNumberSearch}
                    handleCloseProSearchModal={() =>
                        setIsProNumberSearch(false)
                    }
                    shipmentsProNumberSearch={shipmentsProNumberSearch}
                    proNumber={formValues?.proNumber}
                    changeField={changeField}
                    newFlow={true}
                    setIsReturning={setIsReturning}
                    setReturnsTrackingId={setReturnsTrackingId}
                />
            </Grid>
        )
    } else if (currentStep > 0) {
        return (
            <Grid item container>
                <BasicInfoSummary
                    handleEdit={handleEdit}
                    formValues={formValues}
                    locations={locations}
                />
            </Grid>
        )
    }
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(BasicInfo)
