import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Box } from "@material-ui/core"
import { requestCarrierOnboardingGuide } from "./requests"

const Tutorial = ({ code, classes }) => {
    const [content, setContent] = useState()

    useEffect(() => {
        const fetchTutorialContent = async () => {
            const { body } = await requestCarrierOnboardingGuide(code)
            setContent(body)
        }
        fetchTutorialContent()
    }, [code])

    return (
        <Box>
            <Typography variant="h6">
                <FormattedMessage
                    id="tutorial.title"
                    defaultMessage="Carrier Setup Instructions"
                />
            </Typography>
            <Box
                sx={{
                    width: "100%",
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    height: "70vh",
                }}
            >
                {content ? (
                    <div
                        dangerouslySetInnerHTML={{ __html: content }}
                        style={{
                            width: "100%",
                            height: "100%",
                            overflow: "scroll",
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="tutorial.undefined"
                        defaultMessage="No tutorial found"
                    />
                )}
            </Box>
        </Box>
    )
}

Tutorial.propTypes = {
    classes: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
}

export default Tutorial
