import React, { Fragment, useEffect, useState } from "react"
import FreightBoxHandlingUnit from "./FreightBoxHandlingUnit"
import HandlingUnit from "./HandlingUnit"
import { dateIsInPFFRange } from "../../../util"
import moment from "moment"

export const BookHandlingUnits = ({
    fields,
    handlePanel,
    isQuickRate,
    isOpen,
    origin,
    originAddress,
    setHandlingUnitMode,
    determineEstimatedLinearFeet,
    isFreightDirect,
    isFreightDirectReturns,
    isFreightBox,
    editMode,
    formValues,
    changeField,
    ...props
}) => {
    const [showPFF, setShowPFF] = useState(true)

    useEffect(() => {
        setShowPFF(!isFreightDirectReturns && dateIsInPFFRange(moment()))
    }, [])

    const updatePFFValues = pff => {
        formValues?.handlingUnits?.forEach((_, index) => {
            changeField(`handlingUnits[${index}].protectFromFreezing`, pff)
        })
    }

    const updateInbondValues = pff => {
        formValues?.handlingUnits?.forEach((_, index) => {
            changeField(`handlingUnits[${index}].isInBondShipment`, pff)
        })
    }

    return (
        <Fragment>
            {isFreightBox
                ? fields.map((hu, index) => (
                      <FreightBoxHandlingUnit
                          {...props}
                          isFreightDirect={isFreightDirect}
                          isFreightDirectReturns={isFreightDirectReturns}
                          isQuickRate={isQuickRate}
                          prefix={hu}
                          key={index}
                          index={index}
                          origin={origin}
                          originAddress={originAddress}
                          onRemoveClick={e => {
                              e.stopPropagation()
                              fields.remove(index)
                              // setHandlingUnitMode(true)
                              // determineEstimatedLinearFeet()
                          }}
                          handlePanel={() => handlePanel(index)}
                          setHandlingUnitMode={setHandlingUnitMode}
                          isOpen={isOpen === index}
                          updatePFFValues={updatePFFValues}
                          updateInbondValues={updateInbondValues}
                          showPFF={showPFF}
                          changeField={changeField}
                      />
                  ))
                : fields.map((hu, index) => (
                      <HandlingUnit
                          canBeDeleted={index !== 0}
                          {...props}
                          isFreightDirect={isFreightDirect}
                          isQuickRate={isQuickRate}
                          isFreightDirectReturns={isFreightDirectReturns}
                          prefix={hu}
                          key={index}
                          index={index}
                          origin={origin}
                          originAddress={originAddress}
                          onRemoveClick={e => {
                              e.stopPropagation()
                              fields.remove(index)
                              // setHandlingUnitMode(true)
                              // determineEstimatedLinearFeet()
                          }}
                          handlePanel={() => handlePanel(index)}
                          setHandlingUnitMode={setHandlingUnitMode}
                          isOpen={isOpen === index}
                          editMode={editMode}
                          updatePFFValues={updatePFFValues}
                          updateInbondValues={updateInbondValues}
                          showPFF={showPFF}
                          changeField={changeField}
                      />
                  ))}
        </Fragment>
    )
}
