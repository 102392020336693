import React, { useEffect, useState, useRef } from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMore from "@material-ui/icons/ExpandMore"
import {
    Button,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import DeleteIcon from "@material-ui/icons/Delete"
import { Field, FieldArray, formValues } from "redux-form"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormCheckbox from "../../../form/form-checkbox"
import FormSwitch from "../../../form/form-switch"
import { join } from "../../../../misc"
import { connect } from "react-redux"
import { itemSearchHash } from "../../../../actions/item"
import { newFreightDirectItem } from "../../../../reducers/search"
import { get } from "lodash"
import FormSelectItemSearch from "../../../form/form-select-item-search"
import { BookItems } from "./BookItems"
import { providePackageTypes } from "../../../item/constants"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline",
    },
    expansionPanel: {
        width: "100%",
    },
    item__icon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    switchesContainer: {
        paddingLeft: "30px",
    },
    itemsContainer: {
        paddingTop: "15px",
    },
})

export const composeHandlingUnitSummary = (
    hu,
    weightUnit,
    intl,
    isUsDimWeightEnabled
) => {
    const { multiCarrierSpacePaceWorkflow } = useFlags()

    const freightClassIsEnable = multiCarrierSpacePaceWorkflow
        ? true
        : !isUsDimWeightEnabled
    const { count, isIndividualHUWeight, isMultiClass, packageType, items } = hu
    const huCount = intl.formatMessage(
        {
            id: "items.handlingUnit__huCount",
            defaultMessage: "H/U Count: {count}",
        },
        { count }
    )

    const huInfo = [packageType, huCount].filter(x => x).join(", ")

    const itemInfo = items
        .map(item => {
            const itemDescription = item.description
                ? `${item.description}`
                : ""
            let itemWeight = item.weight ? `${item.weight}${weightUnit}` : ""
            const itemClass =
                item?.freightClass && freightClassIsEnable
                    ? intl.formatMessage(
                          {
                              id: "items.handlingUnit__class",
                              defaultMessage: "Class {class}",
                          },
                          { class: item?.freightClass }
                      )
                    : ""
            return [itemDescription, itemWeight, itemClass]
                .filter(x => x)
                .join(", ")
        })
        .join("  |  ")

    return [itemInfo, huInfo].filter(x => x).join("  -  ")
}

const HandlingUnitContainer = ({
    canBeDeleted,
    isOpen,
    isQuickRate,
    handlePanel,
    onRemoveClick,
    classes,
    prefix,
    formValues = {},
    changeField,
    index,
    items = [],
    count = 1,
    weightUnit,
    lengthUnit,
    adjustEstimatedLinearFeet,
    adjustEstimatedLinearFeetOnSearchChange,
    adjustEstimatedVolumenCubicMeter,
    adjustEstimatedCubicVolumenMeterOnSearchChange,
    isMultiClass,
    totalWeight,
    intl,
    isIntl,
    isIndividualHUWeight,
    favoriteItems,
    doSearch,
    hu,
    trackGA,
    isIntraMexico,
    isCanadaMexico,
    isMXRate,
    isUSDomesticOffshoreWithItemCustoms,
    editMode,
    isUsDimWeightEnabled,
    isFreightDirectReturns,
    updatePFFValues,
    updateInbondValues,
    showPFF,
}) => {
    const [itemSearchTerm, setItemSearchTerm] = useState(0)

    const huCountRef = useRef(null)
    const [itemRounded, setItemRounded] = useState(false)
    const [preselectedCommoditySat, setPreselectedCommoditySat] = useState(
        false
    )

    const originCountry = formValues?.origin?.shippingAddress?.address?.country

    const GA_CATEGORY = isQuickRate ? "Quick Rate" : "Book Shipment - Items"

    useEffect(() => {
        if (isMXRate || isCanadaMexico) {
            changeField(`${prefix}.isMultiClass`, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMXRate, isCanadaMexico])

    useEffect(() => {
        if (isMultiClass && items.length > 1) {
            if (isIndividualHUWeight) {
                updateTotalHUWeightFromItems()
            } else {
                updateTotalWeightFromItems()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    useEffect(() => {
        if (editMode && isIndividualHUWeight) {
            updateTotalHUWeightFromItems()
        }
    }, [editMode])

    const onDoNotStackChange = e => {
        changeField(`${prefix}.stackable`, !e.target.checked)
    }

    const updateTotalHUWeightFromItems = () => {
        const result = items?.reduce((acc, item) => {
            if (item.weight) {
                return (acc += Number(item?.weight))
            } else return acc
        }, 0)
        changeField(`${prefix}.totalHUWeight`, result)
    }

    const updateTotalWeightFromItems = (newCount = count) => {
        const huCountWithDefault = newCount ? newCount : 1
        const result = items?.reduce((acc, item) => {
            if (item.weight) {
                return (acc += Number(item?.weight))
            } else return acc
        }, 0)
        changeField(`${prefix}.totalWeight`, result * huCountWithDefault)
    }

    const updateItemWeight = (newWeight, newCount = count) => {
        const huCountWithDefault = newCount ? newCount : 1
        !isIndividualHUWeight && Number(newWeight % newCount) !== 0
            ? setItemRounded(true)
            : setItemRounded(false)
        changeField(
            `${prefix}.items[0].weight`,
            isIndividualHUWeight
                ? newWeight
                : Math.ceil(Number(newWeight / huCountWithDefault))
        )
    }

    const handlePackageType = value => {
        if (value === "PLT4848") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 48)
        } else if (value === "PLT4840") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 40)
        }
    }

    const addItem = () => {
        trackGA(GA_CATEGORY, "Add Item to MultiClass HU")
        changeField(`${prefix}.items`, [...items, newFreightDirectItem()])
    }

    const onRemoveItemClick = itemIndex => {
        trackGA(GA_CATEGORY, "Remove Item from MultiClass HU")
        const modifiedItems =
            items.length === 1
                ? [newFreightDirectItem()]
                : [...items].filter((value, index) => index !== itemIndex)

        changeField(`${prefix}.items`, modifiedItems)
        items = modifiedItems
        updateTotalWeightFromItems()
        updateTotalHUWeightFromItems()
    }

    const onMultiClassChange = value => {
        trackGA(GA_CATEGORY, "Multi Class Change", value)
        if (!value) {
            changeField(`${prefix}.items`, [items[0]])
            items = items.slice(0, 1)
            updateTotalHUWeightFromItems()
            updateTotalWeightFromItems()
        }
    }

    const onIsIndividualHUWeightChange = value => {
        updateTotalHUWeightFromItems()
        updateTotalWeightFromItems()
    }

    const handleItemSearchChange = option => {
        const {
            packageType,
            weight,
            count,
            length,
            width,
            height,
            description,
            countryOfManufacture,
            harmonizedCode,
            unitPrice,
            isHazMat,
            hazClass,
            hazContainer,
            pkgGrp,
            unNumber,
            nmfc1,
            nmfc2,
            freightClass,
            pieces,
            freightDirectPieces,
            stackable,
            isIndividualWeight,
            commodityCode,
            packagingCode,
        } = option

        trackGA(GA_CATEGORY, "Item Search Change")

        const index = items?.length - 1 ?? 0

        changeField(`${prefix}.items[${index}].weight`, weight)
        changeField(`${prefix}.items[${index}].description`, description)
        changeField(
            `${prefix}.items[${index}].countryOfManufacture`,
            countryOfManufacture
        )
        changeField(`${prefix}.items[${index}].harmonizedCode`, harmonizedCode)
        changeField(`${prefix}.items[${index}].unitPrice`, unitPrice)

        changeField(`${prefix}.items[${index}].nmfcCode`, nmfc1)
        changeField(`${prefix}.items[${index}].nmfcSubclass`, nmfc2)

        changeField(`${prefix}.items[${index}].pieces`, pieces)
        changeField(
            `${prefix}.items[${index}].freightDirectPieces`,
            freightDirectPieces
        )
        if (commodityCode) {
            setPreselectedCommoditySat(true)
        }
        changeField(`${prefix}.items[${index}].commodityCode`, commodityCode)
        changeField(`${prefix}.items[${index}].packagingCode`, packagingCode)
        changeField(`${prefix}.items[${index}].freightClass`, freightClass)

        changeField(`${prefix}.stackable`, stackable)
        changeField(`${prefix}.doNotStack`, !stackable)

        changeField(`${prefix}.count`, count ? count : 1)
        changeField(`${prefix}.length`, length)
        changeField(`${prefix}.width`, width)
        changeField(`${prefix}.height`, height)
        changeField(`${prefix}.packageType`, packageType)
        changeField(`${prefix}.isIndividualHUWeight`, isIndividualWeight)

        if (!isMXRate) {
            changeField(`${prefix}.items[${index}].isHazMat`, isHazMat)
            changeField(`${prefix}.items[${index}].hazMatClass`, hazClass)
            changeField(
                `${prefix}.items[${index}].hazMatContainer`,
                hazContainer
            )
            changeField(`${prefix}.items[${index}].hazMatPkgGrp`, pkgGrp)
            changeField(`${prefix}.items[${index}].hazMatUnNumber`, unNumber)
        }

        if (isIntraMexico || (isCanadaMexico && originCountry === "MX")) {
            adjustEstimatedCubicVolumenMeterOnSearchChange(
                prefix,
                length,
                width,
                height,
                count,
                !stackable
            )
        } else {
            adjustEstimatedLinearFeetOnSearchChange(
                prefix,
                length,
                width,
                height,
                count,
                !stackable
            )
        }

        if (huCountRef.current) {
            huCountRef.current.focus()
        }
        items[index].weight = weight
        updateTotalHUWeightFromItems()
        updateTotalWeightFromItems(count ? count : 1)
    }

    const onItemSearchType = term => {
        if (term) {
            setItemSearchTerm(term)
            doSearch(term, undefined, undefined)
        }
    }

    return (
        <ExpansionPanel
            expanded={isOpen}
            onChange={(_, open) => handlePanel(open)}
            className={classes.expansionPanel}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore id={`${prefix}.expandMoreIcon`} />}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid container item alignItems="center" xs={9}>
                        {!isOpen && (
                            <Typography variant="caption">
                                {composeHandlingUnitSummary(
                                    hu,
                                    weightUnit,
                                    intl
                                )}
                            </Typography>
                        )}
                        {isOpen && (
                            <Grid
                                item
                                container
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                            >
                                <Field
                                    component={FormSelectItemSearch}
                                    name={`${prefix}.itemSearch`}
                                    label={
                                        <FormattedMessage
                                            id="bookShipment.items__itemSearch"
                                            defaultMessage="Search"
                                        />
                                    }
                                    options={favoriteItems}
                                    onChange={option => {
                                        handleItemSearchChange(option)
                                    }}
                                    onBlur={() => setItemSearchTerm(0)}
                                    onItemSearchType={onItemSearchType}
                                    term={itemSearchTerm}
                                    autoFocus
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={3}
                        alignItems="center"
                        justify="flex-end"
                    >
                        {canBeDeleted ? (
                            <IconButton
                                id={`${prefix}.removeIcon`}
                                onClick={e => {
                                    onRemoveClick(e)
                                    trackGA(GA_CATEGORY, "Delete Line Item")
                                }}
                                className={classes.item__icon}
                            >
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid item container>
                    <Grid item container>
                        <Grid item container>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="bookShipment.items__handlingUnitInfo"
                                    defaultMessage="Handling Unit Information"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container xs={9}>
                            <Grid item xs={6}>
                                <Field
                                    category={GA_CATEGORY}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.packageType`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__packageType"
                                            defaultMessage="Package Type"
                                        />
                                    }
                                    required
                                    options={providePackageTypes(intl)}
                                    onChange={(e, value) =>
                                        handlePackageType(value)
                                    }
                                />
                            </Grid>
                            <Grid item container xs={3}>
                                <Field
                                    category={GA_CATEGORY}
                                    component={FormField}
                                    name={`${prefix}.count`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__huCount"
                                            defaultMessage="H/U Count"
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        isIntraMexico ||
                                        (isCanadaMexico &&
                                            originCountry === "MX")
                                            ? adjustEstimatedVolumenCubicMeter(
                                                  prefix,
                                                  "count",
                                                  newValue
                                              )
                                            : adjustEstimatedLinearFeet(
                                                  prefix,
                                                  "count",
                                                  newValue
                                              )
                                        if (
                                            !isIndividualHUWeight &&
                                            isMultiClass &&
                                            items.length > 1
                                        ) {
                                            updateTotalWeightFromItems(newValue)
                                        } else {
                                            if (!isIndividualHUWeight) {
                                                updateItemWeight(
                                                    totalWeight,
                                                    newValue
                                                )
                                            }
                                        }
                                    }}
                                    inputRef={huCountRef}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {isIndividualHUWeight ? (
                                    <Field
                                        category={GA_CATEGORY}
                                        component={FormField}
                                        name={`${prefix}.totalHUWeight`}
                                        label={
                                            <FormattedMessage
                                                id="getRates.items__individualWeight"
                                                defaultMessage="Weight per H/U ({weightUnit})"
                                                values={{
                                                    weightUnit,
                                                }}
                                            />
                                        }
                                        onChange={(e, newValue) => {
                                            if (
                                                !(
                                                    isMultiClass &&
                                                    items.length > 1
                                                )
                                            ) {
                                                updateItemWeight(newValue)
                                            }
                                        }}
                                        required={isIndividualHUWeight}
                                        disabled={
                                            isMultiClass && items.length > 1
                                        }
                                    />
                                ) : (
                                    <Field
                                        category={GA_CATEGORY}
                                        component={FormField}
                                        name={`${prefix}.totalWeight`}
                                        label={
                                            <FormattedMessage
                                                id="getRates.items__totalWeight"
                                                defaultMessage="Total Weight ({weightUnit})"
                                                values={{
                                                    weightUnit,
                                                }}
                                            />
                                        }
                                        onChange={(e, newValue) => {
                                            if (
                                                !(
                                                    isMultiClass &&
                                                    items.length > 1
                                                )
                                            ) {
                                                updateItemWeight(newValue)
                                            }
                                        }}
                                        required={!isIndividualHUWeight}
                                        disabled={
                                            isMultiClass && items.length > 1
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    category={GA_CATEGORY}
                                    component={FormField}
                                    name={`${prefix}.length`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__length"
                                            defaultMessage="Length ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        isIntraMexico ||
                                        (isCanadaMexico &&
                                            originCountry === "MX")
                                            ? adjustEstimatedVolumenCubicMeter(
                                                  prefix,
                                                  "length",
                                                  newValue
                                              )
                                            : adjustEstimatedLinearFeet(
                                                  prefix,
                                                  "length",
                                                  newValue
                                              )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    category={GA_CATEGORY}
                                    component={FormField}
                                    name={`${prefix}.width`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__width"
                                            defaultMessage="Width ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        isIntraMexico ||
                                        (isCanadaMexico &&
                                            originCountry === "MX")
                                            ? adjustEstimatedVolumenCubicMeter(
                                                  prefix,
                                                  "width",
                                                  newValue
                                              )
                                            : adjustEstimatedLinearFeet(
                                                  prefix,
                                                  "width",
                                                  newValue
                                              )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    category={GA_CATEGORY}
                                    component={FormField}
                                    name={`${prefix}.height`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__height"
                                            defaultMessage="Height ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        isIntraMexico ||
                                        (isCanadaMexico &&
                                            originCountry === "MX")
                                            ? adjustEstimatedVolumenCubicMeter(
                                                  prefix,
                                                  "height",
                                                  newValue
                                              )
                                            : adjustEstimatedLinearFeet(
                                                  prefix,
                                                  "height",
                                                  newValue
                                              )
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={3}
                                justify="center"
                                alignItems="flex-end"
                                className={classes.stackableCheckboxContainer}
                            >
                                <Field
                                    name={`${prefix}.doNotStack`}
                                    label={
                                        <FormattedMessage
                                            id="bookShipment.items__doNotStack"
                                            defaultMessage="Do Not Stack"
                                        />
                                    }
                                    component={FormCheckbox}
                                    onChange={(e, newValue) => {
                                        adjustEstimatedLinearFeet(
                                            prefix,
                                            "doNotStack",
                                            newValue
                                        )
                                        onDoNotStackChange(e)
                                    }}
                                    category={GA_CATEGORY}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={3}
                            direction="column"
                            justify={
                                !isMXRate && !isUsDimWeightEnabled
                                    ? "center"
                                    : "flex-start"
                            }
                            className={classes.switchesContainer}
                        >
                            {showPFF && (
                                <Grid item>
                                    {
                                        <Field
                                            component={FormSwitch}
                                            name={`${prefix}.protectFromFreezing`}
                                            label={
                                                <FormattedMessage
                                                    id="getRates.form__freezing"
                                                    defaultMessage="Protect From Freezing"
                                                />
                                            }
                                            onChange={(e, newValue) => {
                                                trackGA(
                                                    GA_CATEGORY,
                                                    "Protect from Freezing Click",
                                                    newValue
                                                        ? "Select"
                                                        : "Deselect"
                                                )
                                                updatePFFValues(newValue)
                                            }}
                                            category={GA_CATEGORY}
                                        />
                                    }
                                </Grid>
                            )}

                            {!isFreightDirectReturns && (
                                <Grid item>
                                    {
                                        <Field
                                            component={FormSwitch}
                                            name={`${prefix}.isInBondShipment`}
                                            label={
                                                <FormattedMessage
                                                    id="getRates.form__inBondShipment"
                                                    defaultMessage="In-Bond Shipment (Additional surcharges apply)"
                                                />
                                            }
                                            onChange={(e, newValue) => {
                                                trackGA(
                                                    GA_CATEGORY,
                                                    "In-bond Shipment Click",
                                                    newValue
                                                        ? "Select"
                                                        : "Deselect"
                                                )
                                                updateInbondValues(newValue)
                                            }}
                                            category={GA_CATEGORY}
                                        />
                                    }
                                </Grid>
                            )}

                            <Grid item>
                                {
                                    <Field
                                        component={FormSwitch}
                                        name={`${prefix}.isIndividualHUWeight`}
                                        label={
                                            <FormattedMessage
                                                id="bookShipment.items__weightPerHU"
                                                defaultMessage="Specify Weight per H/U"
                                            />
                                        }
                                        onChange={(e, newValue) =>
                                            onIsIndividualHUWeightChange(
                                                newValue
                                            )
                                        }
                                        category={GA_CATEGORY}
                                    />
                                }
                            </Grid>
                            {!isMXRate && !isUsDimWeightEnabled ? (
                                <Grid item>
                                    <Field
                                        component={FormSwitch}
                                        name={`${prefix}.isMultiClass`}
                                        label={
                                            <FormattedMessage
                                                id="getRates.items__multiClassHU"
                                                defaultMessage="MultiClass H/U"
                                            />
                                        }
                                        onChange={(event, value) =>
                                            onMultiClassChange(value)
                                        }
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid item container className={classes.itemsContainer}>
                            <FieldArray
                                name={`${prefix}.items`}
                                category={GA_CATEGORY}
                                component={BookItems}
                                huIndex={index}
                                formValues={formValues}
                                onRemoveItemClick={onRemoveItemClick}
                                isMultiClass={isMultiClass}
                                weightUnit={weightUnit}
                                isIntl={isIntl}
                                intl={intl}
                                getHUWeight={get}
                                isUSDomesticOffshoreWithItemCustoms={
                                    isUSDomesticOffshoreWithItemCustoms
                                }
                                showIndividualHUWeight={items.length > 1}
                                isIntraMexico={isIntraMexico}
                                isMXRate={isMXRate}
                                isCanadaMexico={isCanadaMexico}
                                isUsDimWeightEnabled={isUsDimWeightEnabled}
                                preselectedCommoditySat={
                                    preselectedCommoditySat
                                }
                                setPreselectedCommoditySat={
                                    setPreselectedCommoditySat
                                }
                            ></FieldArray>
                        </Grid>
                        <Grid item container xs={6}>
                            {isMultiClass && (
                                <Button
                                    color="primary"
                                    onClick={() => addItem()}
                                    id="multiclassAddItem"
                                    type="button"
                                >
                                    +{" "}
                                    <FormattedMessage
                                        id="getRates.form__addItem"
                                        defaultMessage="Add Item"
                                    />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const mapStateToProps = (
    state,
    { description, unNumber, origin, originAddress }
) => ({
    itemsDescription:
        state.item.search[itemSearchHash(description, origin, originAddress)],
    itemsUN: state.item.search[itemSearchHash(unNumber, origin, originAddress)],
    searchInProgress: state.item.searchInProgress,
})

const HandlingUnit = formValues(({ prefix }) => ({
    count: join(prefix, "count"),
    totalWeight: join(prefix, "totalWeight"),
    items: join(prefix, "items"),
    packageType: join(prefix, "packageType"),
    length: join(prefix, "length"),
    width: join(prefix, "width"),
    height: join(prefix, "height"),
    isMultiClass: join(prefix, "isMultiClass"),
    totalHUWeight: join(prefix, "totalHUWeight"),
    isIndividualHUWeight: join(prefix, "isIndividualHUWeight"),
    hu: prefix,
}))(connect(mapStateToProps)(injectIntl(HandlingUnitContainer)))

export default injectIntl(withStyles(styles)(HandlingUnit))
