import { Grid, IconButton, Typography, withStyles } from "@material-ui/core"
import React, { useEffect, useState, useCallback } from "react"
import FormSwitch from "../../../form/form-switch"
import { FormattedMessage } from "react-intl"
import { Field, FieldArray, formValues } from "redux-form"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import CloseIcon from "@material-ui/icons/Close"
import FreightDirectPiece from "./FreightDirectPiece"
import {
    countriesOfOrigin,
    join,
    provideLocaleSortedOptionsList,
} from "../../../../misc"
import { MXSATPackagingCodeOptions } from "../../../constants/MXSATInformation"
import { goFetch } from "../../../../http"
import { get } from "lodash"
import { useFlags } from "launchdarkly-react-client-sdk"
import {
    freightClasses,
    provideHazMatClasses,
    provideHazMatContainers,
    provideHazMatPkgGrp,
} from "../../../item/constants"
import FormHelperText from "@material-ui/core/FormHelperText"
import HazMatModal from "./share/HazMatModal"
const styles = theme => ({
    itemInfo: {
        paddingLeft: "5%",
    },
    switchesContainer: {
        paddingLeft: "30px",
        paddingTop: "15px",
    },
    itemContainer: {
        borderTop: "solid 1px #D4D4D4",
        padding: "15px 0",
    },
    underline: {
        textDecoration: "underline",
    },
    item__icon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    piecesContainerFXFD: { paddingTop: "10px" },
    button__add_item: {
        marginLeft: "20px",
    },
})

const ItemContainer = ({
    category,
    classes,
    prefix,
    itemIndex,
    onRemoveItemClick,
    isMultiClass,
    weightUnit,
    formValues,
    intl,
    isIntl,
    isUSDomesticOffshoreWithItemCustoms,
    getHUWeight,
    weight,
    isHazMat,
    showIndividualHUWeight,
    isIntraMexico,
    isMXRate,
    isCanadaMexico,
    isUsDimWeightEnabled,
    preselectedCommoditySat,
    setPreselectedCommoditySat,
}) => {
    const {
        isFreightDirect,
        isFreightDirectReturns,
        preferredCurrencyCode,
        origin = {},
        destination = {},
    } = formValues
    useEffect(() => {
        getHUWeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weight])

    const { singleWorkflowLtlAndLtlDirect } = useFlags()

    const isFreightDirectPiecesToggleOn = get(
        formValues,
        `${prefix}.freightDirectPiecesToggle`,
        false
    )

    const usDomesticOffshoreStates = ["AK", "PR", "HI"]

    const isMultiCarrier = formValues?.selectedLocation?.carriers?.length > 0

    const showFreightClass =
        (isUsDimWeightEnabled && isMultiCarrier) || !isUsDimWeightEnabled
    const showFreightClassMessage = isUsDimWeightEnabled && isMultiCarrier

    const shouldShowFreightDirectPiecesToggle =
        origin?.shippingAddress?.address?.country === "US" &&
        destination?.shippingAddress?.address?.country === "US" &&
        formValues?.mode !== "inbound" &&
        !isFreightDirect &&
        !isFreightDirectReturns &&
        formValues?.selectedLocation?.address?.country === "US" &&
        !usDomesticOffshoreStates.includes(
            origin?.shippingAddress?.address?.state
        ) &&
        !usDomesticOffshoreStates.includes(
            destination?.shippingAddress?.address?.state
        ) &&
        singleWorkflowLtlAndLtlDirect

    const piecesLabel = isMultiClass ? (
        <FormattedMessage
            id="getRates.items__piecesPerHU"
            defaultMessage="Pieces per H/U"
        />
    ) : (
        <FormattedMessage
            id="getRates.items__totalPieces"
            defaultMessage="Total Pieces"
        />
    )

    const [MXSATCommodityCodeOptions, setMXSATCommodityCodeOptions] = useState(
        []
    )

    const debounce = (func, delay = 500) => {
        let timer
        return (...args) => {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                timer = null
                func.apply(this, args)
            }, delay)
        }
    }

    const handleCommoditySearch = async value => {
        const params = {
            q: value,
            size: 25,
        }

        const result = await goFetch(
            "/quotes/mexico-commodities",
            {
                params,
            },
            true
        )

        setMXSATCommodityCodeOptions(result.data)
    }

    const debouncedHandleCommoditySearch = useCallback(
        debounce(handleCommoditySearch),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => {
        setMXSATCommodityCodeOptions([])
        const { commodityCode } = get(formValues, prefix, {})
        if (commodityCode) {
            handleCommoditySearch(commodityCode)
        }
    }, [])

    const isAlaskaShipment = formValues => {
        const { destination, origin } = formValues
        return (
            destination?.shippingAddress.address.state === "AK" ||
            origin?.shippingAddress.address.state === "AK"
        )
    }
    const [openHazMatModal, setOpenHazMatModal] = useState(false)
    const [hasInitialTrue, setHasInitialTrue] = useState(false)

    useEffect(() => {
        setOpenHazMatModal(
            isHazMat && isAlaskaShipment(formValues) && hasInitialTrue
        )
        setHasInitialTrue(true)
    }, [isHazMat])

    return (
        <>
            <HazMatModal
                classes={classes}
                open={openHazMatModal}
                setClose={setOpenHazMatModal}
            />
            <Grid item container className={classes.itemContainer}>
                <Grid item container xs={9} className={classes.itemInfo}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="bookShipment.items__itemLevelInfo"
                            defaultMessage="Item Information"
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={3}
                    justify="flex-end"
                    alignItems="center"
                >
                    {isMultiClass && itemIndex !== 0 ? (
                        <IconButton
                            onClick={() => onRemoveItemClick(itemIndex)}
                            className={classes.item__icon}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </Grid>

                <Grid item container xs={9} className={classes.itemInfo}>
                    {isHazMat && !(isMXRate || isCanadaMexico) && (
                        <Grid item container xs={4}>
                            <Field
                                component={FormField}
                                name={`${prefix}.hazMatUnNumber`}
                                label={
                                    <FormattedMessage
                                        id="items__unNumber"
                                        defaultMessage="UN #"
                                    />
                                }
                                required
                                category={category}
                                // onChange={e => doSearch(e)}
                            />
                            {/* <Popper
                            onMouseDown={() => this.handleContainerMouseDown()}
                            onMouseUp={() => this.handleContainerMouseUp()}
                            open={showItemOverlayUN}
                            anchorEl={anchorEl}
                            placement="bottom-end"
                            disablePortal
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                },
                            }}
                            className={classes.popper}
                        >
                            {get(this.props, "item.hazMatUnNumber.length") > 2 && (
                                <SuggestionBox
                                    term={item.hazMatUnNumber}
                                    origin={origin}
                                    originAddress={originAddress}
                                    onItemClick={this.onItemClick}
                                />
                            )}
                        </Popper> */}
                        </Grid>
                    )}
                    {isHazMat && !(isMXRate || isCanadaMexico) && (
                        <Grid item container xs={4}>
                            <Field
                                category={category}
                                component={FormSelectAutocomplete}
                                name={`${prefix}.hazMatPkgGrp`}
                                label={
                                    <FormattedMessage
                                        id="items__pkgGroup"
                                        defaultMessage="Pkg Group"
                                    />
                                }
                                options={provideHazMatPkgGrp(intl)}
                                required
                            />
                        </Grid>
                    )}
                    {isHazMat && !(isMXRate || isCanadaMexico) && (
                        <Grid item container xs={4}>
                            <Field
                                category={category}
                                component={FormSelectAutocomplete}
                                name={`${prefix}.hazMatContainer`}
                                label={
                                    <FormattedMessage
                                        id="items__containerType"
                                        defaultMessage="Container Type"
                                    />
                                }
                                options={provideHazMatContainers(intl)}
                                required
                            />
                        </Grid>
                    )}
                    {isHazMat && !(isMXRate || isCanadaMexico) && (
                        <Grid item container xs={12}>
                            <Field
                                category={category}
                                component={FormSelectAutocomplete}
                                name={`${prefix}.hazMatClass`}
                                label={
                                    <FormattedMessage
                                        id="items__hazClass"
                                        defaultMessage="Haz Class"
                                    />
                                }
                                options={provideHazMatClasses(intl)}
                                required
                            />
                        </Grid>
                    )}
                    <Grid item container xs={8}>
                        <Field
                            category={category}
                            component={FormField}
                            name={`${prefix}.description`}
                            label={
                                <FormattedMessage
                                    id="items__description"
                                    defaultMessage="Description"
                                />
                            }
                            // onChange={e => doSearch(e)}
                            required
                        />
                    </Grid>
                    {showFreightClass ? (
                        <Grid item container xs={4}>
                            {!isUsDimWeightEnabled && !isIntraMexico ? (
                                <Field
                                    category={category}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.freightClass`}
                                    label={
                                        <FormattedMessage
                                            id="items__freightClass"
                                            defaultMessage="Freight Class"
                                        />
                                    }
                                    options={freightClasses}
                                    required
                                />
                            ) : (
                                <Field
                                    category={category}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.freightClass`}
                                    label={
                                        <FormattedMessage
                                            id="items__freightClass"
                                            defaultMessage="Freight Class"
                                        />
                                    }
                                    options={freightClasses}
                                />
                            )}
                            {showFreightClassMessage ? (
                                <FormHelperText>
                                    <FormattedMessage
                                        id="items__freightClass_multiCarrier_spaceAndPace"
                                        defaultMessage="Class entry required for non-FedEx rates"
                                    />
                                </FormHelperText>
                            ) : null}
                        </Grid>
                    ) : null}

                    {(isIntl || isUSDomesticOffshoreWithItemCustoms) && (
                        <Grid item container>
                            <Grid item container xs={4}>
                                <Field
                                    category={category}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.countryOfManufacture`}
                                    label={
                                        <FormattedMessage
                                            id="items__countryOfManufacture"
                                            defaultMessage="Country Of Manufacture"
                                        />
                                    }
                                    options={provideLocaleSortedOptionsList(
                                        intl,
                                        countriesOfOrigin,
                                        ["US", "CA", "MX"]
                                    )}
                                    required
                                />
                            </Grid>
                            <Grid item container xs={4}>
                                <Field
                                    category={category}
                                    type="number"
                                    component={FormField}
                                    name={`${prefix}.unitPrice`}
                                    label={
                                        <FormattedMessage
                                            id="items__unitPrice"
                                            defaultMessage="Unit Price ({preferredCurrencyCode})"
                                            values={{
                                                preferredCurrencyCode,
                                            }}
                                        />
                                    }
                                    required
                                    // onBlur={updateShouldAES}
                                />
                            </Grid>
                            <Grid item container xs={4}>
                                <Field
                                    category={category}
                                    component={FormField}
                                    name={`${prefix}.harmonizedCode`}
                                    label={
                                        <FormattedMessage
                                            id="items__hsCode"
                                            defaultMessage="HS Code"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    {isMultiClass && showIndividualHUWeight && (
                        <Grid item container xs={12}>
                            <Field
                                category={category}
                                component={FormField}
                                name={`${prefix}.weight`}
                                label={
                                    <FormattedMessage
                                        id="items__itemWeightPerHU"
                                        defaultMessage="Item Weight per H/U ({weightUnit})"
                                        values={{
                                            weightUnit,
                                        }}
                                    />
                                }
                                required
                            />
                        </Grid>
                    )}

                    {!isUsDimWeightEnabled ? (
                        <>
                            <Grid item xs={4}>
                                <Field
                                    category={category}
                                    component={FormField}
                                    name={`${prefix}.nmfcCode`}
                                    placeholder={"xxxxxx"}
                                    label={
                                        <FormattedMessage
                                            id="items__nmfc"
                                            defaultMessage="NMFC"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    category={category}
                                    component={FormField}
                                    label={
                                        <FormattedMessage
                                            id="items__subclass"
                                            defaultMessage="Subclass"
                                        />
                                    }
                                    name={`${prefix}.nmfcSubclass`}
                                    placeholder={"xx"}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {!isFreightDirect &&
                    !isFreightDirectReturns &&
                    !isFreightDirectPiecesToggleOn ? (
                        <Grid item container xs={4}>
                            <Field
                                category={category}
                                component={FormField}
                                name={`${prefix}.pieces`}
                                label={piecesLabel}
                                value="1"
                                required
                            />
                        </Grid>
                    ) : (
                        <Grid item container>
                            <FieldArray
                                name={`${prefix}.freightDirectPieces`}
                                category={category}
                                component={FreightDirectPiece}
                                prefix={prefix}
                                classes={classes}
                                weightUnit={weightUnit}
                            />
                        </Grid>
                    )}
                    {(isMXRate || isCanadaMexico) && (
                        <Grid item container xs={12}>
                            <Grid item container xs={6}>
                                <Field
                                    category={category}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.packagingCode`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__SATPackagingType"
                                            defaultMessage="MX SAT Packaging Type"
                                        />
                                    }
                                    required
                                    options={MXSATPackagingCodeOptions}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <Field
                                    category={category}
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.commodityCode`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__SATCommodityCode"
                                            defaultMessage="MX SAT Commodity Code"
                                        />
                                    }
                                    required
                                    options={
                                        preselectedCommoditySat
                                            ? []
                                            : MXSATCommodityCodeOptions ?? []
                                    }
                                    onInputChange={value => {
                                        if (value) {
                                            setPreselectedCommoditySat(false)
                                            debouncedHandleCommoditySearch(
                                                value
                                            )
                                        }
                                    }}
                                    noResultsOverride={
                                        <FormattedMessage
                                            id="getRtaes.items__SATCommodityCodeOverride"
                                            defaultMessage="Please search for a valid code"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    xs={3}
                    className={classes.switchesContainer}
                    alignContent="flex-start"
                    direction="column"
                    justifyContent="flex-start"
                >
                    {!(isMXRate || isCanadaMexico) &&
                    !isFreightDirectReturns ? (
                        <Grid item alignItems="center">
                            <Field
                                category={category}
                                component={FormSwitch}
                                name={`${prefix}.isHazMat`}
                                label={
                                    <FormattedMessage
                                        id="items__hazMat"
                                        defaultMessage="HazMat"
                                    />
                                }
                            />
                        </Grid>
                    ) : null}
                    {shouldShowFreightDirectPiecesToggle ? (
                        <Grid item alignItems="center">
                            <Field
                                category={category}
                                component={FormSwitch}
                                name={`${prefix}.freightDirectPiecesToggle`}
                                label={
                                    <FormattedMessage
                                        id="items__freightDirectPiecesSwitch"
                                        defaultMessage="Piece Weight"
                                    />
                                }
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </>
    )
}

const Item = formValues(({ prefix }) => ({
    weight: join(prefix, "weight"),
    isHazMat: join(prefix, "isHazMat"),
}))(ItemContainer)

export default withStyles(styles)(Item)
