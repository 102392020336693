import React, { useState, useEffect, useContext } from "react"
// Context
import { OrdersContext } from "../../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { useBookedShipmentContext } from "../../../context/providers/BookedShipmentProvider"
// Redux State
import { connect } from "react-redux"
import { change, getFormValues, reduxForm, reset, touch } from "redux-form"
import {
    changePath,
    checkOriginZipTable,
    checkRestrictionsByPostalCode,
} from "../../../actions"
import { requestAccessorials } from "../../../actions/accessorial"
import {
    createShipment,
    populateActiveRate,
    requestQuote,
} from "../../../actions/book-shipment-requests"
import { requestItems } from "../../../actions/item"
import { copyShipment } from "../../../actions/quote-request"
import { searchContacts } from "../../../actions/contact"
import { setIsBookingQuickRate } from "../../../actions/redesign"
import { trackGAEvent } from "../../../actions/user"
import {
    newFreightDirectHandlingUnit,
    newFreightDirectItem,
} from "../../../reducers/search" // Tech debt
// Components
import Grid from "@material-ui/core/Grid"
import BookStepper from "./BookStepper"
import BasicInfo from "./steps/BasicInfo"
import Origin from "./steps/Origin"
import Destination from "./steps/Destination"
import Items from "./steps/Items"
import Rates from "./steps/Rates"
import Finalize from "./steps/Finalize"
import Review from "./steps/Review"
import FreightBoxRestrictionDialog from "./atoms/FreightBoxRestrictionDialog"
import VagueCommodityDialog from "../../quotesPage/dialog/vagueCommodityDialog"
import CapLoadWarningDialog from "./atoms/CapLoadWarningDialog"
import GlobalSpinner from "../../common/GlobalSpinner"
import AlertDialog from "./atoms/AlertDialog"
// Utilities
import { freightClassCheck } from "../../util"
import { isObject, isArray } from "lodash"
import { Element, scroller } from "react-scroll"
import { FormattedMessage, injectIntl } from "react-intl"
import { constructSubLabel, constructLabel } from "../../util"
import { bookShipmentSteps } from "./values"
import { formSelector, orderToFormSelector } from "./selector"
import { useQueryParam } from "../../../misc"
import { getCurrentBSStep } from "./atoms/getCurrentBSStep"
import {
    sortByPriority,
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
} from "../../quotesPage/accessorials"
import { IntercomAPI } from "react-intercom"
// Validation
import {
    invalidInbondShipment,
    isCanadaAlaska,
} from "../../../actions/validation"
import { bookShipmentFormValidation } from "./validators"
import { useFlags } from "launchdarkly-react-client-sdk"

// Styles
import { useStyles } from "./styles"
import { getZipcodeSpecialHandling } from "../../../actions/offshoreRating"
import { closeModal, openModal } from "../../../actions/modal"
import { goFetch } from "../../../http"

const getErrorFieldNames = (errors, name = "", resultArray = []) => {
    Object.keys(errors).forEach(k => {
        const value = errors[k]

        if (value?.defaultMessage || value?.id) {
            resultArray.push(`${name}${k}`)
            return
        }

        if (isArray(value) && value.length > 0) {
            value.forEach((item, index) => {
                const nextName = `${name + k}[${index}].`
                getErrorFieldNames(item, nextName, resultArray)
            })
        } else if (isObject(value) && Object.keys(value).length > 0) {
            Object.keys(value).forEach(idx => {
                const innerValue = value[idx]
                if (innerValue?.defaultMessage || innerValue?.id) {
                    resultArray.push(`${name + k}.${idx}`)
                } else if (isArray(innerValue) && innerValue.length > 0) {
                    innerValue.forEach((item, index) => {
                        const nextName = `${name + k}.${idx}[${index}].`
                        getErrorFieldNames(item, nextName, resultArray)
                    })
                } else {
                    if (!innerValue) return
                    const nextName = `${name + k}.${idx}.`
                    getErrorFieldNames(innerValue, nextName, resultArray)
                }
            })
        }
    })
    return resultArray
}

function scrollToFirstError(errors) {
    const errorFields = getErrorFieldNames(errors)
    errorFields?.every(elementId => {
        const element = document.getElementById(elementId)
        if (element) {
            scroller.scrollTo(elementId, {
                offset: -200,
                smooth: true,
            })
            return false
        }
        return true
    })
}

let BookShipmentForm = ({
    locations,
    formattedLocations,
    changeField,
    initialValues,
    formValues = {},
    performContactSearch,
    contactSearchInProgress,
    originCities,
    destinationCities,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    vagueCommodities = [],
    getRatesRequest,
    getBookRequest,
    loadItems,
    changePath,
    guaranteedList,
    shipmentId,
    loadAccessorials,
    activeQuote,
    trackGA,
    invalid,
    touchField,
    isBookingQuickRate,
    quickRate,
    setIsBookingQR,
    loadRate,
    handleSubmit,
    reRunRates,
    order,
    bookShipmentSyncErrors,
    checkOriginZipCommercialInvoice,
    checkRestrictionsByPostalCode,
    offShoreZipcodeSpecialHandling,
    checkZipcodeSpecialHandling,
    openModal,
    closeModal,
}) => {
    const isReturnMode = initialValues?.isReturnMode
    const classes = useStyles()
    const { nextDayPickUpFlag, multiCarrierSpacePaceWorkflow } = useFlags()
    const [stepTimes, setStepTimes] = useState([0, 0, 0, 0, 0, 0, 0])
    const [stepEditTimes, setStepEditTimes] = useState([0, 0, 0, 0, 0, 0, 0])
    const [sessionSteps, setSessionSteps] = useState([])
    const [stepStartTime, setStepStartTime] = useState(null)
    const [currentStep, setCurrentStep] = useState(
        shipmentId && !isReturnMode ? 4 : 0
    )
    const [editMode, setEditMode] = useState(false)
    const [previousStep, setPreviousStep] = useState(0)
    const [isHUOpen, setIsHUOpen] = useState(0)
    const [vagueItemDescriptions, setVagueItemDescriptions] = useState([])
    const [isCapLoadDialogOpen, setIsCapLoadDialogOpen] = useState(false)
    const [isPieceWeightDialogOpen, setIsPieceWeightDialogOpen] = useState(
        false
    )
    const [showCapLoadWarning, setShowCapLoadWarning] = useState(false)
    const [
        isVagueCommodityDialogOpen,
        setIsVagueCommodityDialogOpen,
    ] = useState(false)
    const [isFreightBoxDialogOpen, setIsFreightBoxDialogOpen] = useState(false)

    const [requireNextDayPickUpModal, setRequireNextDayPickUpModal] = useState(
        false
    )

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRate, setIsLoadingRate] = useState(false)
    const [isControlledFlow, setIsControlledFlow] = useState(true)
    const [requiredAccessorialList, setRequiredAccessorialList] = useState([])

    const { shouldReset, origin, destination, handlingUnits = [] } = formValues
    const { handlingUnits: handlingUnitSyncErrors } =
        bookShipmentSyncErrors ?? {}

    const originCountry = origin?.shippingAddress?.address?.country
    const destinationCountry = destination?.shippingAddress?.address?.country
    const originState = origin?.shippingAddress?.address?.state
    const destinationState = destination?.shippingAddress?.address?.state

    const originPostalCode = origin?.shippingAddress?.address?.postalCode

    const isCanadaMexico =
        (originCountry === "MX" && destinationCountry === "CA") ||
        (originCountry === "CA" && destinationCountry === "MX")

    const isCAtoMX = originCountry === "CA" && destinationCountry === "MX"
    const isMXtoCA = originCountry === "MX" && destinationCountry === "CA"

    const isCanadaAlaskaShipment = isCanadaAlaska(
        originState,
        originCountry,
        destinationState,
        destinationCountry
    )

    const [oldFormValues, setOldFormValues] = useState({})

    const { setShouldOpen } = useBookedShipmentContext()
    const { isBookingOrder, setIsBookingOrder } = useContext(OrdersContext)

    const alaskaSpecialHandlingMessageMap = {
        "Not Serviced": (
            <FormattedMessage
                id="origin_specialHandling_notServiced"
                defaultMessage="FedEx Freight does not service shipments to this zipcode."
            />
        ),

        "Charter Service Only *": (
            <FormattedMessage
                id="origin_specialHandling_charterServiceOnly"
                defaultMessage="Call 800-351-5187 Ext. 6 to book shipment or email offshore-ops@fedex.com."
            />
        ),
    }

    const addSelectedRequiredAccessorial = (
        accessorialGroup,
        accessorialCode
    ) => {
        changeField(accessorialGroup, accessorialCode)
        setRequiredAccessorialList(
            Array.from(new Set([...requiredAccessorialList, accessorialCode]))
        )
    }

    const isAK = (country, state) => {
        return country === "US" && state === "AK"
    }

    const isHI = (country, state) => {
        return country === "US" && state === "HI"
    }

    const onClickReRunRates = async () => {
        setIsLoading(true)
        await reRunRates(shipmentId)
        setIsLoading(false)
        scroller.scrollTo("ratesTitle", {
            offset: -140,
        })
    }

    useEffect(() => {
        if (shouldReset) {
            setCurrentStep(0)
            setStepStartTime(Date.now())
            setStepTimes([0, 0, 0, 0, 0, 0, 0])
            setStepEditTimes([0, 0, 0, 0, 0, 0, 0])
            setSessionSteps([])
            setIsBookingQR(false)
            setIsBookingOrder(false)
            setOldFormValues({})
            setPreviousStep(0)
            setIsControlledFlow(true)
            setEditMode(false)
            setIsHUOpen(0)
            changeField("shouldReset", false)
            changeField("shouldReset", false)
            changeField("destination", {
                country: initialValues?.destination?.country,
            })
            changeField("handlingUnits[0].items[0].weight", 0)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldReset])

    useEffect(() => {
        if (shipmentId && !isReturnMode && !activeQuote) {
            const fetchData = async () => {
                setIsLoadingRate(true)
                trackGA("Book Shipment", "Load Existing Rate")
                await loadRate(shipmentId)
                setIsLoadingRate(false)
            }
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId])

    useEffect(() => {
        if (order && !shouldReset) {
            setIsControlledFlow(false)
            if (order.mode == "THIRDPARTY") {
                order.paymentType = "THIRD_PARTY"
            }
            async function getBSStep() {
                const step = await getCurrentBSStep({
                    bookShipmentFormValidation,
                    formValues: initialValues,
                    BSProps: props,
                    featureTransition: true,
                    getRates,
                    feature: "orders",
                })
                if (step) {
                    trackGA(
                        "Transition",
                        "Orders to Book Shipment",
                        "Book Shipment Validation Error Found",
                        step.stepName
                    )
                    setCurrentStep(step.stepValue)
                }
            }
            getBSStep()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    useEffect(() => {
        if (isBookingQuickRate && !shouldReset) {
            Object.keys(quickRate).forEach(k => {
                if (k !== "isQuickRate") {
                    changeField(k, quickRate[k])
                }
            })

            async function getBSStep() {
                const step = await getCurrentBSStep({
                    bookShipmentFormValidation,
                    formValues: quickRate,
                    BSProps: props,
                    featureTransition: true,
                    getRates,
                    isUpdate: false,
                    feature: "quickRate",
                })
                if (step) {
                    trackGA(
                        "Transition",
                        "Quick Rate to Book Shipment",
                        "Book Shipment Validation Error Found",
                        step.stepName
                    )
                    setCurrentStep(step.stepValue)
                }
            }
            getBSStep()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBookingQuickRate])

    useEffect(() => {
        trackGA("Book Shipment", "Page Mount")
        setStepStartTime(Date.now())
        loadAccessorials()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setRequireNextDayPickUpModal(false)

        if (
            originCountry === "MX" &&
            originPostalCode &&
            originPostalCode.length >= 3 &&
            nextDayPickUpFlag
        ) {
            const makeZipcodeApiCall = async () => {
                try {
                    const result = await checkRestrictionsByPostalCode(
                        originCountry,
                        originPostalCode
                    )
                    let restrictions = result?.data ?? []

                    if (
                        restrictions.find(
                            restriction =>
                                restriction?.restriction
                                    ?.requireExtraDayToPickUp === true
                        )
                    ) {
                        setRequireNextDayPickUpModal(true)
                        return result
                    }
                } catch (error) {
                    return
                }
            }
            makeZipcodeApiCall()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originPostalCode, originCountry])

    const zipCodeAKCheck = (zipcode, address, accessorialGroup) => {
        if (
            !offShoreZipcodeSpecialHandling["AK"]?.some(
                zipInfo => zipInfo?.zip === zipcode
            )
        ) {
            checkZipcodeSpecialHandling(zipcode, "AK")
        } else {
            const specialCase = checkLoadedAKSpecialCases(address)
            if (specialCase?.specialHandling === "Will Call Only") {
                addSelectedRequiredAccessorial(accessorialGroup, "DOCKDEL")
            }
        }
    }

    const zipCodeHICheck = (zipcode, address, accessorialGroup) => {
        if (
            !offShoreZipcodeSpecialHandling["HI"]?.some(
                zipInfo => zipInfo?.zipcode === zipcode
            )
        ) {
            checkZipcodeSpecialHandling(zipcode, "HI")
        } else {
            const specialHiCase = checkLoadedHISpecialCases(address)
            if (specialHiCase?.specialHandling === "Will Call Only") {
                addSelectedRequiredAccessorial(accessorialGroup, "DOCKDEL")
            }
        }
    }

    useEffect(() => {
        const originPostalCode =
            formValues?.origin?.shippingAddress?.address?.postalCode
        const city = formValues?.origin?.shippingAddress?.address?.city

        if (requiredAccessorialList.length > 0) {
            setRequiredAccessorialList(
                requiredAccessorialList.filter(acc => acc !== "DOCKDEL")
            )
        }

        if (originPostalCode && city) {
            if (
                isAK(
                    formValues?.origin?.shippingAddress?.address?.country,
                    formValues?.origin?.shippingAddress?.address?.state
                )
            ) {
                zipCodeAKCheck(
                    originPostalCode,
                    formValues?.origin?.shippingAddress?.address,
                    "deliveryAccessorials"
                )
            } else if (
                isHI(
                    formValues?.origin?.shippingAddress?.address?.country,
                    formValues?.origin?.shippingAddress?.address?.state
                )
            ) {
                zipCodeHICheck(
                    originPostalCode,
                    formValues?.origin?.shippingAddress?.address,
                    "deliveryAccessorials"
                )
            }
        }
    }, [
        formValues?.origin?.shippingAddress?.address?.country,
        formValues?.origin?.shippingAddress?.address?.state,
        formValues?.origin?.shippingAddress?.address?.postalCode,
        formValues?.origin?.shippingAddress?.address?.city,
        offShoreZipcodeSpecialHandling,
    ])

    useEffect(() => {
        const destinationPostalCode =
            formValues?.destination?.shippingAddress?.address?.postalCode
        const city = formValues?.destination?.shippingAddress?.address?.city

        if (requiredAccessorialList.length > 0) {
            setRequiredAccessorialList(
                requiredAccessorialList.filter(acc => acc !== "DOCKPU")
            )
        }
        if (destinationPostalCode && city) {
            if (
                isAK(
                    formValues?.destination?.shippingAddress?.address?.country,
                    formValues?.destination?.shippingAddress?.address?.state
                )
            ) {
                zipCodeAKCheck(
                    destinationPostalCode,
                    formValues?.destination?.shippingAddress?.address,
                    "pickupAccessorials"
                )
            } else if (
                isHI(
                    formValues?.destination?.shippingAddress?.address?.country,
                    formValues?.destination?.shippingAddress?.address?.state
                )
            ) {
                zipCodeHICheck(
                    destinationPostalCode,
                    formValues?.destination?.shippingAddress?.address,
                    "pickupAccessorials"
                )
            }
        }
    }, [
        formValues?.destination?.shippingAddress?.address?.country,
        formValues?.destination?.shippingAddress?.address?.state,
        formValues?.destination?.shippingAddress?.address?.postalCode,
        formValues?.destination?.shippingAddress?.address?.city,
        offShoreZipcodeSpecialHandling,
    ])

    const { openSnackbar } = useSnackbarContext()

    const addNewHandlingUnit = fields => {
        const protectFromFreezing = formValues?.handlingUnits?.some(
            hu => hu?.protectFromFreezing
        )
        const isInBondShipment = formValues?.handlingUnits?.some(
            hu => hu?.isInBondShipment
        )
        trackGA(
            "Book Shipment",
            "Add New Handling Unit",
            `Current Length: ${fields.length}`
        )
        fields.push(
            newFreightDirectHandlingUnit({
                count: 1,
                height: "",
                items: [newFreightDirectItem()],
                length: "",
                name: "",
                packageType: "",
                stackable: true,
                doNotStack: false,
                totalWeight: "",
                width: "",
                isMultiClass: false,
                isIndividualHUWeight: false,
                protectFromFreezing: protectFromFreezing,
                isInBondShipment: isInBondShipment,
            })
        )
        const id = fields.length
        setIsHUOpen(id > -1 ? id : undefined)
    }

    const handleHUPanel = index => {
        setIsHUOpen(isHUOpen === index ? null : index)
    }

    const onStepClick = label => {
        trackGA("Book Shipment", "Stepper - Step Click", label)
        scroller.scrollTo(label, {
            duration: 500,
            smooth: true,
            offset: -175,
        })
    }

    const recordStepTime = (update = false) => {
        if (stepStartTime) {
            setSessionSteps([...sessionSteps, currentStep])
            const elapsedTime = Date.now() - stepStartTime
            const newStepTimes = update ? stepEditTimes : stepTimes
            newStepTimes[currentStep] += elapsedTime
            if (update) {
                setStepEditTimes(newStepTimes)
            } else {
                setStepTimes(newStepTimes)
            }
        }
        setStepStartTime(Date.now())
    }

    const handleComplete = (isInvalidNextButton, featureToBookShipment) => {
        recordStepTime()
        if (isInvalidNextButton) return
        if (featureToBookShipment === "quickRate") {
            trackGA(
                "Transition",
                "Quick Rate to Book Shipment",
                "Successful Quick Rate to Book Shipment Rate Request"
            )
            setCurrentStep(4)
        } else if (featureToBookShipment === "orders") {
            trackGA(
                "Transition",
                "Orders to Book Shipment",
                "Successful Order to Book Shipment Rate Request"
            )
            setCurrentStep(4)
        } else {
            trackGA(
                "Book Shipment",
                "Complete Step",
                bookShipmentSteps[currentStep].gaLabel
            )
            setCurrentStep(currentStep + 1)
        }
    }

    const handleUpdate = isInvalid => {
        if (!isInvalid) {
            recordStepTime(true)
            setEditMode(false)
            trackGA(
                "Book Shipment",
                "Update Button Click",
                bookShipmentSteps[currentStep]?.label
            )
            setCurrentStep(currentStep + 1)
        }
    }

    const handleEdit = index => {
        recordStepTime()
        trackGA(
            "Book Shipment",
            "Edit Button Click",
            `${bookShipmentSteps[currentStep]?.label} to ${bookShipmentSteps[previousStep]?.label}`
        )

        setIsControlledFlow(false)
        setPreviousStep(currentStep)
        setCurrentStep(index)
        setEditMode(true)
        setOldFormValues(formValues)
    }

    const handleCancelEdit = () => {
        trackGA(
            "Book Shipment",
            "Back Button Click",
            `${bookShipmentSteps[currentStep]?.label} to ${bookShipmentSteps[previousStep]?.label}`
        )

        setIsControlledFlow(true)

        Object.keys(oldFormValues).forEach(entry => {
            changeField(entry, oldFormValues[entry])
        })
        setEditMode(false)
        setCurrentStep(previousStep)
    }

    const checkVagueCommodities = async () => {
        const originCountry =
            formValues?.origin?.shippingAddress?.address?.country
        const destinationCountry =
            formValues?.destination?.shippingAddress?.address?.country
        const vagueItemDescriptions = []

        if (originCountry !== destinationCountry) {
            formValues.handlingUnits.forEach((hu, huIndex) => {
                hu.items.forEach((item, itemIndex) => {
                    vagueCommodities.forEach(vagueCommodity => {
                        if (
                            vagueCommodity.name.toLowerCase() ===
                            item.description.trim().toLowerCase()
                        ) {
                            const validDescription = {
                                huIndex,
                                itemIndex,
                                oldDescription: item.description,
                                newDescription: item.description,
                                vagueDescription: vagueCommodity.description,
                                class: item.freightClass,
                            }
                            vagueItemDescriptions.push(validDescription)
                        }
                    })
                })
            })
        }
        if (vagueItemDescriptions.length > 0) {
            trackGA("Book Shipment", "Vague Commodities Modal", "Appears")
            setVagueItemDescriptions(vagueItemDescriptions)
            setIsVagueCommodityDialogOpen(true)
            return false
        } else {
            return true
        }
    }

    const checkLoadedAKSpecialCases = shippingAddress => {
        return offShoreZipcodeSpecialHandling["AK"]
            ?.find(
                specialInfo => specialInfo?.zip === shippingAddress?.postalCode
            )
            ?.specialInstructions.find(
                specialHandling =>
                    specialHandling.city?.toUpperCase() === "ALL CITIES" ||
                    specialHandling.city?.toUpperCase() ===
                        shippingAddress.city.toUpperCase()
            )
    }

    const checkLoadedHISpecialCases = shippingAddress => {
        return offShoreZipcodeSpecialHandling["HI"]?.find(
            info =>
                info?.zipcode === shippingAddress?.postalCode &&
                (info.city?.toUpperCase() ===
                    shippingAddress?.city.toUpperCase() ||
                    info?.aliasCities?.some(
                        city =>
                            city?.toUpperCase() ===
                            shippingAddress.city.toUpperCase()
                    ))
        )
    }

    const checkCapacityLoadWarnings = () => {
        if (showCapLoadWarning) {
            trackGA("Book Shipment", "Capacity Load Warning Modal", "Appears")
            setIsCapLoadDialogOpen(true)
            return false
        } else {
            return true
        }
    }

    const getRates = async (featureToBookShipment, dismissCapLoadWarning) => {
        let proceed = true
        if (!dismissCapLoadWarning) {
            proceed = checkCapacityLoadWarnings()
        }
        if (proceed) proceed = await checkVagueCommodities()
        if (proceed) await makeRateRequest(featureToBookShipment)
    }

    const onBookShipment = async () => {
        trackGA("Book Shipment - Review", "Click Book Shipment")
        setIsLoading(true)
        try {
            const shipmentResult = await getBookRequest(formValues)

            changePath(`/track/${shipmentId}`)

            const carrier = shipmentResult?.shipment?.carrier
            trackGA("Book Shipment - Review", "Book Success", carrier)
            if (carrier === "FXNL" || carrier === "FXFE") {
                setShouldOpen(true)
            }
            recordStepTime()
        } catch (err) {
            console.log(err)
            if (err) {
                trackGA("Book Shipment - Review", "Book Error", err)
                openSnackbar("error", err)
            }
        } finally {
            setIsLoading(false)
            setIsBookingQR(false)
            setIsBookingOrder(false)
            goFetch(
                "/shipments/session",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                        "Content-Type": "application/json",
                    },
                    data: {
                        stepTimes,
                        stepEditTimes,
                        sessionSteps: [...sessionSteps, currentStep],
                        shipmentId,
                    },
                },
                true
            )
        }
    }

    const makeRateRequest = async featureToBookShipment => {
        trackGA("Book Shipment", "Rate Request", `isUpdate: ${editMode}`)
        setIsLoading(true)
        let newFormValues = freightClassCheck(
            formValues,
            multiCarrierSpacePaceWorkflow
        )
        let orderFormValues = freightClassCheck(
            initialValues,
            multiCarrierSpacePaceWorkflow
        )
        try {
            if (featureToBookShipment === "orders") {
                await getRatesRequest(orderFormValues, featureToBookShipment)
            } else {
                await getRatesRequest(newFormValues, featureToBookShipment)
            }
            trackGA("Book Shipment", "Rating Success", `isUpdate: ${editMode}`)
            if (editMode) {
                handleUpdate()
            } else {
                handleComplete(false, featureToBookShipment)
            }
        } catch (err) {
            if (err?.props?.id && err?.props?.defaultMessage) {
                trackGA(
                    "Book Shipment",
                    "Rating Error",
                    err?.props?.defaultMessage
                )
                openSnackbar("error", <FormattedMessage {...err?.props} />)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const toggleOnPieceWeight = () => {
        for (let huIndex in handlingUnits) {
            for (let itemIndex in handlingUnits[huIndex].items) {
                changeField(
                    `handlingUnits[${huIndex}].items[${itemIndex}].freightDirectPiecesToggle`,
                    true
                )
            }
        }
    }

    const inputProps = {
        classes: {
            root: classes.input__field,
            input: classes.input__field,
        },
    }

    const textInputProps = {
        classes: {
            root: classes.textInput__field,
            input: classes.textInput__field,
        },
    }

    const resetSelection = field => {
        changeField(`${field}Name`, null)
        changeField(`${field}City`, null)
        changeField(`${field}State`, null)
        changeField(`${field}Street`, null)
        changeField(`${field}Contact`, null)
        changeField(`${field}Address`, null)
        changeField(`${field}ContactInfo`, null)
        changeField(`${field}AddressInfo`, null)
    }

    const props = {
        currentStep,
        editMode,
        handleComplete,
        handleEdit,
        handleCancelEdit,
        handleUpdate,
        inputProps,
        textInputProps,
        initialValues,
        formValues,
        setIsLoading,
        permissions: formValues?.selectedLocation?.users[0].permissions,
        shipmentId,
        activeQuote,
        changeField,
        trackGA,
        invalid,
        touchField,
        isBookingQuickRate,
        isControlledFlow,
        setIsControlledFlow,
        handleSubmit,
        IntercomAPI,
        isBookingOrder,
        originCountry,
        destinationCountry,
        isCanadaMexico,
        isCAtoMX,
        isCanadaAlaskaShipment,
        isMXtoCA,
    }

    if (isLoadingRate) {
        return <GlobalSpinner />
    }

    return (
        <Grid container>
            <FreightBoxRestrictionDialog
                open={isFreightBoxDialogOpen}
                handleClose={() => setIsFreightBoxDialogOpen(false)}
            />
            <AlertDialog
                open={isPieceWeightDialogOpen}
                onCloseDialog={() => {
                    setIsPieceWeightDialogOpen(false)
                    trackGA(
                        "Book Shipment - Rates",
                        "Close Piece Weight Dialog"
                    )
                }}
                onContinue={() => {
                    setIsPieceWeightDialogOpen(false)
                    trackGA(
                        "Book Shipment - Rates",
                        "Continue to Change Piece Weight"
                    )
                    setCurrentStep(3)
                    toggleOnPieceWeight()
                }}
                dialogTitle={
                    <FormattedMessage
                        id="book.freightDirect_Pieces_Dialog_title"
                        defaultMessage="Standard and Premium FXFD service levels"
                    />
                }
                dialogContentText={
                    <FormattedMessage
                        id="book.FXFDpiece_weight_dialog_contentText"
                        defaultMessage="Standard and Premium FXFD service levels require 
                    piece weight information. Go to line items page to add information?"
                    />
                }
                closeDialogButtonText={
                    <FormattedMessage
                        id="generalTerms__cancel"
                        defaultMessage="Cancel"
                    />
                }
                confirmButtonText={
                    <FormattedMessage
                        id="book.FXFDpiece_weight_dialog_Lineitems"
                        defaultMessage="OK"
                    />
                }
            />

            <form
                onSubmit={handleSubmit(() => {})}
                className={classes.containerForm}
            >
                {isLoading && <GlobalSpinner />}
                {currentStep < 6 && (
                    <Grid
                        container
                        direction="column"
                        className={classes.baseContainer}
                        alignItems="center"
                    >
                        <Grid
                            item
                            container
                            className={classes.stepperContainer}
                        >
                            <BookStepper
                                currentStep={currentStep}
                                steps={bookShipmentSteps}
                                onStepClick={onStepClick}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.workflowContainer}
                        >
                            {currentStep >= 0 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[0].label}
                                    />
                                    <BasicInfo
                                        {...{
                                            ...props,
                                            formattedLocations,
                                            locations,
                                            resetSelection,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 1 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[1].label}
                                    />
                                    <Origin
                                        {...{
                                            ...props,
                                            performContactSearch,
                                            contactSearchInProgress,
                                            cities: originCities,
                                            pickupAccessorialsList,
                                            setIsFreightBoxDialogOpen,
                                            requireNextDayPickUpModal,
                                            setRequireNextDayPickUpModal,
                                            checkLoadedAKSpecialCases,
                                            alaskaSpecialHandlingMessageMap,
                                            addSelectedRequiredAccessorial,
                                            requiredAccessorialList,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 2 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[2].label}
                                    />
                                    <Destination
                                        {...{
                                            ...props,
                                            performContactSearch,
                                            contactSearchInProgress,
                                            cities: destinationCities,
                                            deliveryAccessorialsList,
                                            setIsFreightBoxDialogOpen,
                                            locations,
                                            formattedLocations,
                                            checkLoadedAKSpecialCases,
                                            alaskaSpecialHandlingMessageMap,
                                            requiredAccessorialList,
                                            addSelectedRequiredAccessorial,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 3 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[3].label}
                                    />
                                    <Items
                                        {...{
                                            ...props,
                                            handleHUPanel,
                                            isHUOpen,
                                            addNewHandlingUnit,
                                            getRates,
                                            loadItems,
                                            isLoading,
                                            handlingUnitSyncErrors,
                                            editMode,
                                            setShowCapLoadWarning,
                                            checkLoadedHISpecialCases,
                                            openModal,
                                            closeModal,
                                            isCanadaMexico,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 4 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[4].label}
                                    />
                                    <Rates
                                        {...{
                                            ...props,
                                            guaranteedList,
                                            onClickReRunRates,
                                            isLoading,
                                            setEditMode,
                                            setIsPieceWeightDialogOpen,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 5 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element
                                        name={bookShipmentSteps[5].label}
                                    />
                                    <Finalize
                                        {...{
                                            ...props,
                                            originCities,
                                            destinationCities,
                                            performContactSearch,
                                            contactSearchInProgress,
                                            checkOriginZipCommercialInvoice,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <VagueCommodityDialog
                            isVagueCommodity={isVagueCommodityDialogOpen}
                            originalFormValues={formValues}
                            // checkAddressValidation={this.checkAddressValidation}
                            vagueItemDescriptions={vagueItemDescriptions}
                            vagueCommodities={vagueCommodities}
                            currentForm={"bookShipment"}
                            toggleVagueCommodityStatus={
                                setIsVagueCommodityDialogOpen
                            }
                        />
                        <CapLoadWarningDialog
                            onContinue={() => {
                                setIsCapLoadDialogOpen(false)
                                getRates(null, true)
                            }}
                            open={isCapLoadDialogOpen}
                            onCloseDialog={() => {
                                setIsCapLoadDialogOpen(false)
                            }}
                        />
                    </Grid>
                )}
                {currentStep === 6 && (
                    <Grid item container className={classes.reviewContainer}>
                        <Review
                            {...{
                                ...props,
                                pickupAccessorialsList,
                                deliveryAccessorialsList,
                                onBookShipment,
                                isLoading,
                                locations,
                                originCountry,
                                destinationCountry,
                                checkLoadedHISpecialCases,
                                openModal,
                                closeModal,
                            }}
                        />
                    </Grid>
                )}
            </form>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => ({
    loadRate: shipmentId => dispatch(populateActiveRate(shipmentId)),
    changeField: (field, value) =>
        dispatch(change("bookShipment", field, value)),
    performContactSearch: (value, postal_code, country) =>
        dispatch(searchContacts(value, "quote", postal_code, country)),
    getRatesRequest: async (payload, featureToBookShipment) =>
        dispatch(requestQuote(payload, featureToBookShipment)),
    getBookRequest: async payload => {
        const shipmentResult = await dispatch(createShipment(payload))
        return shipmentResult
    },
    loadItems: () => dispatch(requestItems()),
    changePath: path => dispatch(changePath(path)),
    loadAccessorials: () => dispatch(requestAccessorials()),
    resetForm: form => dispatch(reset(form)),
    trackGA: (category, action, label, value) =>
        dispatch(trackGAEvent(category, action, label, value)),
    touchField: field => dispatch(touch("bookShipment", field)),
    setIsBookingQR: value => dispatch(setIsBookingQuickRate(value)),
    reRunRates: async shipmentId =>
        dispatch(
            copyShipment({
                shipmentId,
                queryVersion: "V2",
                shipAgain: false,
                openSnackbar: false,
            })
        ),
    checkOriginZipCommercialInvoice: originZipcode =>
        dispatch(checkOriginZipTable(originZipcode)),
    checkRestrictionsByPostalCode: (country, postalCode) =>
        dispatch(checkRestrictionsByPostalCode(country, postalCode)),
    checkZipcodeSpecialHandling: (zipcode, state) =>
        dispatch(getZipcodeSpecialHandling(zipcode, state)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: async () => dispatch(closeModal()),
})

const mapStateToProps = (state, props) => {
    const locations = state?.user?.profile?.locations ?? []

    const formValues = getFormValues("bookShipment")(state)
    const bookShipmentSyncErrors = state?.form?.bookShipment?.syncErrors

    const formattedLocations = locations
        .filter(
            item =>
                item?.users[0]?.permissions?.createRates?.value &&
                !item?.users[0]?.permissions?.suspended?.value &&
                !item.isPending
        )
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    props.intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                thirdparty: item.is3rdPartyEnabled,
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item.locationType === "FEDEX_FREIGHT_BOX",
                fedexFreightAccountType: item.fedexFreightAccountType,
                fedexBillToAccountType: item.fedexBillToAccountType,
                shippingAddress: item.shippingAddress,
            }
        })

    const contactSearchInProgress = state?.contact?.searchInProgress
    const language = state?.user?.profile?.preferences?.language

    const shipmentId = props?.computedMatch?.params?.shipmentId

    const originCountry = formValues?.origin?.shippingAddress?.address?.country
    const destinationCountry =
        formValues?.destination?.shippingAddress?.address?.country

    const paymentAccountCountry = formValues?.paymentAccount?.address?.country

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            originCountry !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            destinationCountry !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        shipmentId,
        locations,
        formattedLocations,
        bookShipmentSyncErrors,
        initialValues: props.order
            ? orderToFormSelector(state, props)
            : formSelector(state, props),
        formValues,
        contactSearchInProgress,
        originCities:
            state?.postalCode[
                `${originCountry}-${formValues?.origin?.shippingAddress?.address?.postalCode}`
            ]?.cities,
        destinationCities:
            state?.postalCode[
                `${destinationCountry}-${formValues?.destination?.shippingAddress?.address?.postalCode}`
            ]?.cities,
        paymentAccountCities:
            state?.postalCode[
                `${paymentAccountCountry}-${formValues?.paymentAccount?.address?.postalCode}`
            ]?.cities,

        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        vagueCommodities: state?.vagueCommodities?.list,
        guaranteedList: state.accessorials.list.guaranteed,
        activeQuote: state?.redesign?.activeRates[shipmentId],
        isBookingQuickRate: state?.redesign?.isBookingQuickRate,
        quickRate: state?.form?.quickRate?.values,
        user: state?.user?.profile,
        offShoreZipcodeSpecialHandling:
            state?.offshoreRating?.zipcodeSpecialHandling,
    }
}

BookShipmentForm = injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            // a unique name for the form
            form: "bookShipment",
            validate: bookShipmentFormValidation,
            destroyOnUnmount: true,
            forceUnregisterOnUnmount: true,
            enableReinitialize: true,
            onSubmitFail: errors => scrollToFirstError(errors),
        })(BookShipmentForm)
    )
)

const BookShipment = props => {
    const [order, setOrder] = useState()
    const [isLoading, setLoading] = useState(false)
    const { getOrder } = useContext(OrdersContext)
    const orderId = useQueryParam("order")

    useEffect(() => {
        const loadOrder = async () => {
            if (!orderId) {
                setOrder(null)
                setLoading(false)
                return
            }
            setLoading(true)
            const result = await getOrder(orderId)
            setOrder(result)
            setLoading(false)
        }
        loadOrder()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    if (isLoading) {
        return <GlobalSpinner />
    }

    return <BookShipmentForm {...props} order={order} />
}

export default BookShipment
