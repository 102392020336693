import { goFetch } from "../../../../http"

export async function fetchHSCCode(payload) {
    // returns hsc descriptions
    try {
        const { data } = await goFetch(
            `/item/fedex/hscode/details?destinationCountry=${payload.destinationCountry}&importDate=${payload.importDate}&harmonizedCode=${payload.harmonizedCode}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}

export async function fetchHSCDetails(payload) {
    // returns hscCode
    try {
        const { data } = await goFetch(
            `/item/fedex/hscode/search`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: payload,
            },
            true
        )
        return data
    } catch (error) {
        throw error
    }
}
