import { combineReducers } from "redux"
import { upperFirst, get } from "lodash"
import {
    USER_RECEIVED,
    USER_DISAUTHENTICATED,
    INIT_SIGNUP,
    USER_UPDATE_START,
    USER_UPDATE_ERROR,
    USER_PREFERENCE_ACCEPT_ADDRESS,
    CONTACT_SELECT,
    SET_ACTIVE_LOCATION,
    USER_CARRIER_RESULT,
    USER_TOGGLE_GROUP_BY_CARRIER,
    USER_NEW_LOCATION_ACCEPT_ADDRESS,
    LOCATION_UPDATE,
} from "../actions/user"
import { loadContact, checkPostalCode } from "./address"
import {
    AUTHORIZATION_ERROR,
    AUTHORIZATION_FINISHED,
} from "../actions/authorization"

const addressFields = [
    "street1",
    "street2",
    "city",
    "state",
    "postalCode",
    "country",
    "companyName",
]

function newAddress(prefix) {
    return addressFields.reduce((prev, curr) => {
        prev[`${prefix}${upperFirst(curr)}`] = ""
        return prev
    }, {})
}

function loadAddress(item, prefix) {
    if (!item || !item.address) return newAddress(prefix)
    return {
        street1: item.address.street1,
        street2: item.address.street2 ?? "",
        city: item.address.city,
        state: item.address.state,
        postalCode: checkPostalCode(item.address.postalCode),
        companyName: item.name || "",
        id: item._id || "",
    }
}

function loadBillingContact(item) {
    if (!item) return {}
    return {
        companyName: item.companyName ?? "",
        name: item.contactName ?? "",
        email: item.email ?? "",
        phone: {
            phone_number: item.phoneNumber ?? "",
            extension: item.extension ?? "",
        },
    }
}

function quoteDefaultsOf(action) {
    return (action.user && action.user.preferences && action.user.cpg) || {}
}

const loadLocation = item => ({
    cpgCode: item.cpgCode,
    cpgName: item.cpgName,
    readyTime: item.readyTime || "3:00 PM",
    closeTime: item.closeTime || "5:00 PM",
    pickupInformation: {
        ...loadContact(item.pickupContact),
    },
    shippingAddress: {
        ...loadAddress(item.shippingAddress),
    },
    billingInformation: {
        ...loadAddress(item.address.address ? item.address : item),
        ...loadBillingContact(item.address.billingContact),
    },
    isDefault: item.isDefault,
    editMode: false,
    isModified: false,
    is3rdPartyEnabled: item.is3rdPartyEnabled,
    carriers: item.carriers,
    isShippingInstructionEnabled: item.isShippingInstructionEnabled,
})

export function location(
    state = {
        shippingAddress: {
            id: "",
            companyName: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            postalCode: "",
        },
        billingInformation: {
            id: "",
            companyName: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            postalCode: "",
            name: "",
            email: "",
            phone: "",
        },
        pickupInformation: {
            id: "",
            name: "",
            phone: "",
            email: "",
            extension: "",
            readyTime: "",
            closeTime: "",
        },

        cpgCode: "",
        cpgName: "",

        fedexFreightAccount: "",
        fedexBillToAccount: "",
        validation: {},
        editMode: false,
        isModified: false,
        carriers: [],
        isShippingInstructionEnabled: false,
    },
    action = {}
) {
    switch (action.type) {
        case USER_RECEIVED:
            return loadLocation(state)
        case USER_PREFERENCE_ACCEPT_ADDRESS:
            return { ...state, ...action.origin }
        case CONTACT_SELECT:
            return {
                ...state,
                ...loadAddress(action.contact, "shipping"),
            }
        default:
            return state
    }
}

function defaultContactOf(action) {
    return quoteDefaultsOf(action).contact || {}
}

export function contact(state = { _id: "" }, action = {}) {
    if (action.type === USER_RECEIVED) {
        return {
            _id: defaultContactOf(action)._id || "",
        }
    }
    return state
}

function profile(state = null, action = {}) {
    switch (action.type) {
        case USER_RECEIVED:
            return action.user
        case INIT_SIGNUP:
            return {
                firstname: action.fields.given_name || "",
                lastname: action.fields.family_name || "",
                email: {
                    email_address: action.fields.email || "",
                },
            }
        case USER_DISAUTHENTICATED:
            return profile()
        case LOCATION_UPDATE:
            const oldLocations = state?.locations ?? []
            const locationIndex = oldLocations.findIndex(
                location => location?._id === action?.data?._id
            )
            const updatedLocations =
                locationIndex >= 0
                    ? [
                          ...oldLocations.slice(0, locationIndex),
                          action?.data,
                          ...oldLocations.slice(locationIndex + 1),
                      ]
                    : [...oldLocations]
            return {
                ...state,
                locations: updatedLocations,
            }
        default:
            return state
    }
}

const isAccountActive = (locations = []) => {
    return locations.some(
        item =>
            get(item, "users[0].access.granted") &&
            !get(item, "signUpInProgress", false)
    )
}
export function preferences(
    state = {
        accountActive: false,
        isLoaded: false,
        isFetching: false,
        isError: false,
        onboardingDNA: false,
        groupRatesByCarrier: false,
        newLocation: location(),
    },
    action = {}
) {
    switch (action.type) {
        case USER_RECEIVED:
            return {
                ...state,
                isLoaded: true,
                onboardingDNA: get(action, "user.preferences.onboardingDNA"),
                accountActive: isAccountActive(get(action, "user.locations")),
                hasFXFDReturnsLocation: action?.user?.locations.some(
                    location => location.locationType === "FEDEX_DIRECT_RETURNS"
                ),
            }
        case USER_TOGGLE_GROUP_BY_CARRIER:
            return {
                ...state,
                groupRatesByCarrier: !state.groupRatesByCarrier,
            }
        case USER_PREFERENCE_ACCEPT_ADDRESS:
            state.locations[action.index] = location(
                state.locations[action.index],
                action
            )
            return { ...state, locations: [...state.locations] }
        case USER_NEW_LOCATION_ACCEPT_ADDRESS:
            return {
                ...state,
                newLocation: {
                    ...state.newLocation,
                    ...action.origin,
                },
            }
        case USER_UPDATE_START:
            return { ...state, isFetching: true, isError: false }
        case USER_UPDATE_ERROR:
            return { ...state, isFetching: false, isError: true }
        case USER_DISAUTHENTICATED:
            return preferences()
        default:
            return state
    }
}

export function authFinished(state = false, action) {
    switch (action.type) {
        case AUTHORIZATION_ERROR:
        case AUTHORIZATION_FINISHED:
            return true
        default:
            return state
    }
}

function carriers(state = [], action) {
    switch (action.type) {
        case USER_CARRIER_RESULT:
            return action.result
        default:
            return state
    }
}

function activeLocation(state = [], action) {
    switch (action.type) {
        case SET_ACTIVE_LOCATION:
            return action.index
        default:
            return state
    }
}

export const user = combineReducers({
    profile,
    preferences,
    authFinished,
    contact,
    carriers,
    activeLocation,
})
