import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import NextButton from "../atoms/NextButton"
import SummaryButtons from "../atoms/SummaryButtons"
import { Element, scroller } from "react-scroll"
import HazMatForm from "../atoms/HazMatForm"
import HazMatDetails from "../atoms/HazMatDetails"
import AlertsPreferences from "../../../alerts/alertsPreferences"
import InBondCard from "../../../book/InBondCard"
import OptionalIdentifiers from "../atoms/OptionalIdentifiers"
import {
    isInternationalShipment,
    isMexicoToUsaInternationalShipment,
    isMXDomesticShipment,
    isUsaMainlandToMexicoInternationalShipment,
    isUSDomesticOffshoreShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isValidEmail,
    isValidPhoneNumber,
    isValidCountryForFedexShipment,
    isToOrFromMexicoShipment,
} from "../../../../actions/validation"
import { formValues, reduxForm } from "redux-form"
import { isFedExCarrier } from "../../../../misc"
import SupportDocumentationForm from "../atoms/SupportDocumentationForm"
import { createHandlingUnitInitialValues } from "../../../documents/certificateOfOrigin/selectors"
import PickUpForm from "../atoms/PickUpForm"
import {
    sensibleReadyTime,
    isPastPickupCutoffTime,
    getDayOfTheWeek,
} from "../../../book/selectors"
import TrueThirdPartyForm from "../atoms/TrueThirdPartyForm"
import {
    weightUnitSingular,
    weightUnitSingularCapital,
} from "../../../util/units"
import DeclaredValue from "../atoms/DeclaredValue"
import CustomsBrokerage from "../atoms/CustomsBrokerage"
import { useFlags } from "launchdarkly-react-client-sdk"
import { SJU } from "../../../../actions/util/domesticOffshoreTerminal"
import WarningCommercialnvoice from "../atoms/WarningCommercialnvoice"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../../util/carrierLogoUtils"
import TaxIdInfo from "../atoms/TaxIdInfo"
import { isOffshorePRShipmentFedexValid } from "../../../util/offshoreUtils."
import { bookShipmentFormValidation } from "../validators"
import EstimatedDutiesAndTaxes from "../atoms/EstimatedDutiesAndTaxes"

const useStyles = makeStyles({
    section: {
        paddingBottom: "20px",
    },
    sectionWide: {
        paddingBottom: "45px",
    },
    sectionSmall: {
        padding: "0px 15% 45px 15%",
    },
})

const GA_CATEGORY = "Book Shipment - Finalize"

const Finalize = ({
    handleComplete,
    handleUpdate,
    editMode,
    handleCancelEdit,
    currentStep,
    handlingUnits = [],
    selectedRate = {},
    identifiers = {},
    destination = {},
    origin = {},
    unknownOriginTaxId,
    unknownDestinationTaxId,
    hazmatEmergency = {},
    requiresCommercialInvoice = false,
    preferredCurrencyCode = "USD",
    preferredSystemOfMeasurement = "IMPERIAL",
    isInBondShipment,
    pickupLater,
    selectedLocation,
    isFreightDirectReturns,
    paymentType,
    declaredValueOptions = {},
    declaredValueExpanded,
    estimatedDutiesTaxesDescriptions = {},
    estimatedDutiesValueExpanded,
    changeField,
    invalid,
    activeQuote = {},
    touchField,
    performContactSearch,
    textInputProps,
    contactSearchInProgress,
    IntercomAPI,
    originCountry,
    destinationCountry,
    isCanadaMexico,
    isCAtoMX,
    isMXtoCA,
    secondSelectedLocation,
    attachedDocuments = [],
    checkOriginZipCommercialInvoice,
}) => {
    const {
        supportingDocumentationIntraMexico,
        commercialInvoiceRequiredByZipcodeOnly,
        domesticOffshoreRating,
        mexicoShipmentTaxIdCollection,
        enableEstimatedDutiesAndTaxes,
    } = useFlags()

    const classes = useStyles()
    const [isHazMat, setIsHazMat] = useState(false)
    const [
        intraMXCommercialInvoiceRequired,
        setIntraMXCommercialInvoiceRequired,
    ] = useState(false)
    const [hazMatContact, setHazMatContact] = useState(null)
    const [errorAttachingDocs, setErrorAttachingDocs] = useState(false)
    const { proEnabled } = identifiers
    const { _id, terminal, serviceLevel } = selectedRate
    const originState = origin?.shippingAddress?.address?.state
    const destinationState = destination?.shippingAddress?.address?.state
    const { search } = activeQuote
    const isMXToUSIntl = isMexicoToUsaInternationalShipment(
        originCountry,
        destinationCountry
    )
    const isIntraMexico = isMXDomesticShipment(
        originCountry,
        destinationCountry
    )

    const isIntl = isInternationalShipment(originCountry, destinationCountry)
    const isUSDomesticOffshore = isUSDomesticOffshoreShipment(
        originCountry,
        destinationCountry,
        originState,
        destinationState
    )
    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        originCountry,
        destinationCountry,
        originState,
        destinationState
    )

    const isUSMainlandToMXIntl = isUsaMainlandToMexicoInternationalShipment(
        originCountry,
        destinationCountry,
        originState
    )
    const isToOrFromMexicoIntl = isToOrFromMexicoShipment(
        originCountry,
        destinationCountry
    )

    const certificateOfOriginLineItems = createHandlingUnitInitialValues(
        handlingUnits,
        weightUnitSingularCapital(preferredSystemOfMeasurement)
    )

    const originTimezone = search?.origin?.originTimezone ?? "Pacific/Honolulu"
    const pickupDateStr = search?.pickupDate
    const isPastPickup = isPastPickupCutoffTime(
        pickupDateStr,
        originTimezone,
        originCountry
    )

    const enableIntraMexicoDocs =
        isIntraMexico && supportingDocumentationIntraMexico

    const isValidAttachmentShipmentRestrictions = (field, documentCategory) => {
        const required =
            selectedLocation?.shipmentRestrictions?.[field] ?? false

        const hasValue = attachedDocuments?.some(
            doc => doc?.documentCategory === documentCategory
        )

        if (!required || (required && hasValue)) {
            return true
        }
        return false
    }

    const containsCommercialInvoiceAttachments = () =>
        attachedDocuments?.some(
            doc => doc?.documentCategory === "commercialInvoice"
        )

    const commercialInvoiceIsAttached = containsCommercialInvoiceAttachments()
    const supportInvoiceIsAttached = isValidAttachmentShipmentRestrictions(
        "requireSupportInvoice",
        "supportInvoice"
    )

    const isValidAttachments =
        isIntl ||
        isUSDomesticOffshoreWithItemCustoms ||
        (intraMXCommercialInvoiceRequired && enableIntraMexicoDocs
            ? commercialInvoiceIsAttached && supportInvoiceIsAttached
            : enableIntraMexicoDocs
            ? supportInvoiceIsAttached
            : true)

    const attachmentErrors = {
        commercialInvoice:
            !commercialInvoiceIsAttached && intraMXCommercialInvoiceRequired,
        supportInvoice: !supportInvoiceIsAttached && enableIntraMexicoDocs,
    }

    const [
        displayWarningCommercialInvoice,
        setDisplayWarningCommercialInvoice,
    ] = useState(false)

    const isUSPRDomesticOffshore =
        isUSDomesticOffshore === true &&
        (originState === "PR" || destinationState === "PR")

    const isPRShipmentFedexValid = isOffshorePRShipmentFedexValid(
        originCountry,
        originState,
        destinationCountry,
        destinationState
    )

    const shouldDispayCommercialInvoiceWarning =
        !commercialInvoiceIsAttached && //attachment
        !requiresCommercialInvoice && // autogenerate
        domesticOffshoreRating &&
        (containsCombinedOtherRatesAndFedexOceanRates(selectedRate) ||
            isFedExCarrier(selectedRate?.carrierCode)) &&
        isPRShipmentFedexValid

    useEffect(() => {
        if (enableIntraMexicoDocs) {
            if (!commercialInvoiceRequiredByZipcodeOnly) {
                setIntraMXCommercialInvoiceRequired(true)
            } else {
                const makeZipcodeApiCall = async () => {
                    try {
                        const result = await checkOriginZipCommercialInvoice(
                            origin?.shippingAddress?.address?.postalCode
                        )

                        if (result?.data?.zipcode) {
                            setIntraMXCommercialInvoiceRequired(true)
                            return result
                        }
                    } catch (error) {
                        return
                    }
                }
                makeZipcodeApiCall()
            }
        }
    }, [origin?.shippingAddress?.address?.postalCode, isIntraMexico])

    useEffect(() => {
        if (currentStep === 5) {
            if (isIntl) {
                IntercomAPI(
                    "trackEvent",
                    "book-shipment-finalize-international"
                )
            } else {
                IntercomAPI("trackEvent", "book-shipment-finalize")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentStep === 5) {
            scroller.scrollTo("finalizeTitle", {
                duration: 1000,
                smooth: true,
                offset: -160,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        setIsHazMat(
            handlingUnits.some(hu => {
                return hu.items.some(item => item.isHazMat)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])

    useEffect(() => {
        setIsHazMat(
            handlingUnits.some(hu => {
                return hu.items.some(item => item.isHazMat)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])

    useEffect(() => {
        if (
            hazmatEmergency?.name &&
            hazmatEmergency?.phone?.phone_number &&
            isValidPhoneNumber(
                hazmatEmergency?.phone?.phone_number,
                originCountry
            ) &&
            hazmatEmergency?.email &&
            isValidEmail(hazmatEmergency?.email)
        ) {
            if (
                !hazMatContact?.name &&
                !hazMatContact?.phone?.phone_number &&
                !hazMatContact?.email
            ) {
                setHazMatContact(hazmatEmergency)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hazmatEmergency])

    useEffect(() => {
        changeField("certificateOfOrigin", {
            certificateOfOriginLineItems,
        })
    }, [])

    useEffect(() => {
        const pickupTime = sensibleReadyTime(
            origin?.readyTime,
            pickupDateStr,
            originTimezone
        )
        changeField("origin", {
            ...origin,
            readyTime: pickupTime || "6:00 AM",
            closeTime: origin?.closeTime || "1:00 PM",
        })
        touchField("origin.closeTime")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            isFreightDirectReturns &&
            (serviceLevel?.code === "BASIC_PICKUP" ||
                serviceLevel?.code === "PREMIUM_PICKUP")
        ) {
            changeField("origin", {
                ...origin,
                readyTime: "8:00 AM",
                closeTime: "7:00 PM",
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceLevel])

    useEffect(() => {
        if (isIntraMexico && getDayOfTheWeek(pickupDateStr) === 6) {
            changeField("pickupLater", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const calculateTotalPrice = () => {
        return handlingUnits.reduce(
            (huSum, hu) =>
                huSum +
                hu.items.reduce(
                    (itemSum, item) =>
                        itemSum +
                        (item.unitPrice == null ? 0 : item.unitPrice) *
                            (item.unit == null
                                ? item.pieces == null
                                    ? 1
                                    : item.pieces
                                : item.unit) *
                            hu.count,
                    0
                ),
            0
        )
    }

    const shouldShowAES = () => {
        if (isUSPRDomesticOffshore || isUSMainlandToMXIntl === true) {
            const value = calculateTotalPrice()
            if (value > 2500) {
                return true
            }
            return false
        }
    }

    return (
        <Grid item container>
            <WarningCommercialnvoice
                isCAtoMX={isCAtoMX}
                isDialogOpen={displayWarningCommercialInvoice}
                cancelModal={() => setDisplayWarningCommercialInvoice(false)}
                proccedWithwarning={() => handleComplete()}
            />
            <Grid item container justify="center" className={classes.section}>
                <Typography variant="h6">
                    <FormattedMessage
                        id="bookShipment.finalize__title"
                        defaultMessage="Finalize"
                    />
                </Typography>
                <Element name="finalizeTitle" />
            </Grid>
            <Grid item container className={classes.sectionSmall}>
                <OptionalIdentifiers
                    category={GA_CATEGORY}
                    selectedRate={selectedRate}
                    proEnabled={proEnabled}
                    changeField={changeField}
                    shouldShowAES={shouldShowAES()}
                    totalPrice={{
                        display: requiresCommercialInvoice,
                        amount: calculateTotalPrice(),
                        preferredCurrencyCode: preferredCurrencyCode,
                    }}
                    selectedLocation={selectedLocation}
                />
            </Grid>
            <Grid item container className={classes.sectionSmall}>
                <PickUpForm
                    category={GA_CATEGORY}
                    country={originCountry}
                    pickupLater={pickupLater}
                    terminal={
                        isPRShipmentFedexValid &&
                        originState == "PR" &&
                        domesticOffshoreRating &&
                        (containsCombinedOtherRatesAndFedexOceanRates(
                            selectedRate
                        ) ||
                            isFedExCarrier(selectedRate?.carrierCode))
                            ? SJU
                            : terminal
                    }
                    isPastPickup={isPastPickup}
                    rateMode={selectedRate?.mode}
                    carrierCode={selectedRate?.carrierCode}
                    isFreightDirectReturns={isFreightDirectReturns}
                    serviceLevel={selectedRate?.serviceLevel}
                    isIntraMexico={isIntraMexico}
                    dayOfTheWeek={getDayOfTheWeek(pickupDateStr)}
                />
            </Grid>
            <Grid
                item
                container
                className={classes.section}
                justify="center"
                alignItems="center"
            >
                {isHazMat && (
                    <Grid item container className={classes.sectionSmall}>
                        <HazMatForm category={GA_CATEGORY} />
                    </Grid>
                )}
                {isHazMat &&
                    hazMatContact?.name &&
                    hazMatContact?.email &&
                    hazMatContact?.phone?.phone_number && (
                        <Grid item container className={classes.sectionSmall}>
                            <HazMatDetails
                                changeField={changeField}
                                hazMatContact={hazMatContact}
                            />
                        </Grid>
                    )}
                {isFedExCarrier(selectedRate?.carrierCode) && !isIntraMexico ? (
                    <Grid item container className={classes.sectionSmall}>
                        <DeclaredValue
                            category={GA_CATEGORY}
                            preferredCurrencyCode={preferredCurrencyCode}
                            weightMeasurementUnit={weightUnitSingular(
                                preferredSystemOfMeasurement
                            )}
                            declaredValueOptions={declaredValueOptions}
                            changeField={changeField}
                            declaredValueExpanded={declaredValueExpanded}
                        />
                    </Grid>
                ) : null}
                {enableEstimatedDutiesAndTaxes &&
                isFedExCarrier(selectedRate?.carrierCode) &&
                isIntl ? (
                    <Grid item container className={classes.sectionSmall}>
                        <EstimatedDutiesAndTaxes
                            category={GA_CATEGORY}
                            estimatedDutiesTaxesDescriptions={
                                estimatedDutiesTaxesDescriptions
                            }
                            handlingUnits={handlingUnits}
                            changeField={changeField}
                            estimatedDutiesValueExpanded={
                                estimatedDutiesValueExpanded
                            }
                            originCountry={originCountry}
                            destinationCountry={destinationCountry}
                            pickupDateStr={pickupDateStr}
                        />
                    </Grid>
                ) : null}
                <CustomsBrokerage
                    category={GA_CATEGORY}
                    isMXToUSIntl={isMXToUSIntl}
                    classes={classes}
                    originCountry={originCountry}
                    destinationCountry={destinationCountry}
                    selectedLocation={selectedLocation}
                    secondSelectedLocation={secondSelectedLocation}
                    changeField={changeField}
                    isCanadaMexico={isCanadaMexico}
                    isIntl={isIntl}
                    isUSDomesticOffShore={isUSDomesticOffshore}
                />
                {isToOrFromMexicoIntl && mexicoShipmentTaxIdCollection ? (
                    <Grid item container className={classes.sectionSmall}>
                        <TaxIdInfo
                            unknownOriginTaxId={unknownOriginTaxId}
                            unknownDestinationTaxId={unknownDestinationTaxId}
                            changeField={changeField}
                            originCountry={originCountry}
                            destinationCountry={destinationCountry}
                            gaCategory={`${GA_CATEGORY} : Tax Identification`}
                        />
                    </Grid>
                ) : null}
                {(isIntl ||
                    isUSDomesticOffshoreWithItemCustoms ||
                    enableIntraMexicoDocs) &&
                    isFedExCarrier(selectedRate?.carrierCode) && (
                        <Grid item container className={classes.section}>
                            <SupportDocumentationForm
                                locationId={selectedLocation?._id}
                                shipmentId={identifiers?.internalTrackingNumber}
                                isIntraMexico={isIntraMexico}
                                intraMXCommercialInvoiceRequired={
                                    intraMXCommercialInvoiceRequired
                                }
                                attachmentErrors={attachmentErrors}
                                errorAttachingDocs={errorAttachingDocs}
                                setErrorAttachingDocs={setErrorAttachingDocs}
                                isUSPRDomesticOffshore={isUSPRDomesticOffshore}
                                gaCategory="Book Shipment - Finalize : Support Document"
                            />
                        </Grid>
                    )}
                {isInBondShipment && (
                    <Grid item container className={classes.sectionSmall}>
                        <InBondCard
                            bookShipmentWorkflow
                            formName="bookShipment"
                            gaCategory={`${GA_CATEGORY} : InBondShipment`}
                        />
                    </Grid>
                )}
                {isFedExCarrier(selectedRate?.carrierCode) &&
                    paymentType === "THIRD_PARTY" && (
                        <Grid item container className={classes.sectionSmall}>
                            <TrueThirdPartyForm
                                performContactSearch={performContactSearch}
                                textInputProps={textInputProps}
                                contactSearchInProgress={
                                    contactSearchInProgress
                                }
                                changeField={changeField}
                            />
                        </Grid>
                    )}
                <Grid item container className={classes.sectionSmall}>
                    <AlertsPreferences
                        bookShipmentWorkflow
                        share
                        formName="bookShipment"
                        isFreightDirect={
                            selectedRate?.mode === "LTL_DIRECT" ||
                            selectedRate?.mode === "LTL_DIRECT_RETURNS"
                        }
                        isFedExShipment={
                            !!isFedExCarrier(selectedRate?.carrierCode)
                        }
                        location={selectedLocation}
                        gaCategory={`${GA_CATEGORY} : Alert Preferences`}
                    />
                </Grid>
                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={() => {
                            if (!isValidAttachments) {
                                setErrorAttachingDocs(true)
                            } else {
                                handleCancelEdit()
                            }
                        }}
                        handleUpdate={() => {
                            if (
                                (shouldDispayCommercialInvoiceWarning ||
                                    isCanadaMexico) &&
                                !isMXtoCA
                            ) {
                                setDisplayWarningCommercialInvoice(true)
                            } else {
                                handleUpdate()
                            }
                        }}
                        invalid={invalid || !isValidAttachments}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                if (!isValidAttachments) {
                                    setErrorAttachingDocs(true)
                                }
                                handleComplete(isInvalidNextButton)
                            } else {
                                if (
                                    (shouldDispayCommercialInvoiceWarning ||
                                        isCanadaMexico) &&
                                    !isMXtoCA
                                ) {
                                    setDisplayWarningCommercialInvoice(true)
                                } else {
                                    handleComplete()
                                }
                            }
                        }}
                        invalid={invalid || !isValidAttachments}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(
    formValues({
        handlingUnits: "handlingUnits",
        selectedRate: "selectedRate",
        identifiers: "identifiers",
        destination: "destination",
        origin: "origin",
        hazmatEmergency: "hazmatEmergency",
        requiresCommercialInvoice: "requiresCommercialInvoice",
        preferredCurrencyCode: "preferredCurrencyCode",
        preferredSystemOfMeasurement: "preferredSystemOfMeasurement",
        isInBondShipment: "isInBondShipment",
        pickupLater: "pickupLater",
        selectedLocation: "selectedLocation",
        isFreightDirectReturns: "isFreightDirectReturns",
        paymentType: "paymentType",
        declaredValueOptions: "declaredValueOptions",
        declaredValueExpanded: "declaredValueExpanded",
        estimatedDutiesTaxesDescriptions: "estimatedDutiesTaxesDescriptions",
        estimatedDutiesValueExpanded: "estimatedDutiesValueExpanded",
        secondSelectedLocation: "secondSelectedLocation",
        attachedDocuments: "attachedDocuments",
        mode: "mode",
        unknownOriginTaxId: "unknownOriginTaxId",
        unknownDestinationTaxId: "unknownDestinationTaxId",
    })(Finalize)
)
