const UNSUPPORTED_STATES = ["AK", "HI", "PR"]
const usMxArray = ["US", "MX"]

export const isMultiCountryShipment = (originCountry, destCountry) => {
    if (originCountry !== destCountry) {
        const checkNeededCountries = ["CA", "MX"]
        const givenCountries = [originCountry, destCountry]

        return givenCountries.every(country =>
            checkNeededCountries.includes(country)
        )
    }
    return
}

export const confirmAccountTypesForMultiCountry = (
    selectedLocation,
    locations
) => {
    const {
        fedexFreightAccountType = "",
        fedexBillToAccountType = "",
    } = selectedLocation
    let valid = false
    if (
        fedexFreightAccountType === "FXFC" ||
        fedexBillToAccountType === "FXFC" ||
        fedexBillToAccountType === "FXFE"
    ) {
        valid = locations.some(
            location =>
                location.fedexFreightAccountType === "FXFM" ||
                location.fedexBillToAccountType === "FXFM"
        )
    }

    if (
        fedexFreightAccountType === "FXFM" ||
        fedexBillToAccountType === "FXFM"
    ) {
        valid = locations.some(
            location =>
                location.fedexFreightAccountType === "FXFC" ||
                location.fedexBillToAccountType === "FXFC" ||
                location.fedexBillToAccountType === "FXFE"
        )
    }

    if (
        selectedLocation?.shippingAddress?.address?.postalCode === String(78045)
    ) {
        valid = true
    }

    return valid
}

export const isUnsupportedCountryState = (
    originCountry,
    originState,
    destinationCountry,
    destinationState
) => {
    return (
        destinationCountry === "US" &&
        originCountry === "US" &&
        (((originState === "PR" || originState === "HI") &&
            UNSUPPORTED_STATES.includes(destinationState)) ||
            (originState === "AK" &&
                (destinationState === "PR" || destinationState === "HI")))
    )
}

export const isMexicoOffshore = (
    originCountry,
    originState,
    destinationCountry,
    destinationState
) => {
    const usMxArray = ["US", "MX"]
    return (
        originCountry !== destinationCountry &&
        usMxArray.includes(originCountry) &&
        usMxArray.includes(destinationCountry) &&
        [originState, destinationState].some(state =>
            UNSUPPORTED_STATES.includes(state)
        )
    )
}
