import React, { useEffect, useState } from "react"
import AddCarrier from "./AddCarrier"
import CarrierForm from "./CarrierForm"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useHistory } from "react-router-dom"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { Box, Button, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { refetchUser } from "../../actions/user"
import { requestCarrierList } from "./requests"

const CarrierOnboard = ({
    match,
    allCarriers,
    onboardingLocation,
    updateLocation,
    ...props
}) => {
    const history = useHistory()
    const { carrierId, mode } = useParams()
    const { openSnackbar } = useSnackbarContext()
    const [addableCarriers, setAddableCarriers] = useState([])
    const [carrierListLoading, setCarrierListLoading] = useState(false)
    const [carrierListError, setCarrierListError] = useState(null)

    useEffect(() => {
        if (onboardingLocation === null) {
            history.push("/locations")
        }
    }, [])

    useEffect(() => {
        const fetchCarrierList = async () => {
            setCarrierListLoading(true)
            try {
                const rawAvailableCarriersToAdd = await requestCarrierList(
                    onboardingLocation?.cpgCode
                )
                const availableCarriersToAdd = rawAvailableCarriersToAdd.map(
                    carrierObj => {
                        return {
                            ...carrierObj,
                            carrierDetails: allCarriers
                                ? allCarriers[
                                      carrierObj?.capacityProviderIdentifiers[0]
                                          ?.value
                                  ]
                                : null,
                        }
                    }
                )
                setAddableCarriers(availableCarriersToAdd)
            } catch (error) {
                setCarrierListError(error?.response?.data)
            } finally {
                setCarrierListLoading(false)
            }
        }

        if (allCarriers) {
            fetchCarrierList()
        }
    }, [allCarriers])
    return (
        <Box sx={{ padding: "2%" }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h4">
                    <FormattedMessage
                        id="carrier.onboarding.title"
                        defaultMessage="Onboard Carrier"
                    />
                </Typography>
                <Button
                    onClick={() => {
                        history.push("/locations")
                    }}
                    variant="outlined"
                    color="primary"
                >
                    <FormattedMessage
                        id="carrier.onboarding.navigate.back"
                        defaultMessage="Back to Locations"
                    />
                </Button>
            </Box>
            <Typography variant="h6" color="primary">
                {onboardingLocation?.displayName ??
                    onboardingLocation?.billingContact?.companyName}
            </Typography>
            {mode === "add" && !carrierId ? (
                <AddCarrier
                    openSnackbar={openSnackbar}
                    carrierListLoading={carrierListLoading}
                    addableCarriers={addableCarriers}
                    allCarriers={allCarriers}
                    onboardingLocation={onboardingLocation}
                    {...props}
                />
            ) : (
                <CarrierForm
                    refetchUser={refetchUser}
                    onboardingLocation={onboardingLocation}
                    addableCarriers={addableCarriers}
                    openSnackbar={openSnackbar}
                    match={match}
                    {...props}
                />
            )}
        </Box>
    )
}

const mapStateToProps = state => {
    return {
        allCarriers: state?.carriers?.carriers,
        onboardingLocation: state?.carriers?.onboardingLocation,
    }
}

const mapDispatchToProps = dispatch => ({
    refetchUser: async () => {
        dispatch(await refetchUser())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CarrierOnboard)
