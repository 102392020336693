import React, { useState } from "react"
import PropTypes from "prop-types"
import {
    withStyles,
    Typography,
    Button,
    Box,
    TextField,
    InputAdornment,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { FormattedMessage } from "react-intl"
import { styles } from "./styles"
import AddEditItemModal from "./AddEditItemModal"
import PaginatedItemTable from "./PaginatedItemsTable"
import ImportItemCSVModal from "./ImportItemModal"

import { useItemsContext } from "../../context/providers/ItemsProvider"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const GACategory = "Items"

const ItemPagePresentation = ({ classes }) => {
    const {
        addingItem,
        setAddingItem,
        itemToEdit,
        setItemToEdit,
        debouncedHandleItemSearch,
        searchValue,
        setSearchValue,
    } = useItemsContext()

    const [importModalOpen, setImportModalOpen] = useState(false)

    const handleItemSearch = evt => {
        setSearchValue(evt.target.value)
        debouncedHandleItemSearch(evt.target.value)
    }

    const { logGAEvent } = useGAContext()

    const importModalAction = actionIsOpen => {
        if (actionIsOpen) {
            logGAEvent(GACategory, "Import Item button Click")
            setImportModalOpen(true)
        } else {
            logGAEvent(GACategory, "Import Item Modal Closed")
            setImportModalOpen(false)
        }
    }

    return (
        <div className={classes?.itemPage__container}>
            <AddEditItemModal
                open={addingItem}
                onClose={() => {
                    setAddingItem(false)
                }}
            />
            <AddEditItemModal
                open={itemToEdit}
                onClose={() => {
                    setItemToEdit(null)
                }}
                item={itemToEdit}
            />
            <ImportItemCSVModal
                open={importModalOpen}
                setOpen={importModalAction}
                gaCategory={GACategory}
                logGAEvent={logGAEvent}
            />

            <Box className={classes?.itemPage__title}>
                <Typography variant="h4">
                    <FormattedMessage
                        id="items__title"
                        defaultMessage="Items"
                    />
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            importModalAction(true)
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms__import"
                            defaultMessage="Import"
                        />
                    </Button>
                    <Button
                        onClick={() => {
                            logGAEvent("Items", "Add Item Click")
                            setAddingItem(true)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms__add"
                            defaultMessage="Add"
                        />
                    </Button>
                </Box>
            </Box>
            <Box className={classes.table_container}>
                <Box mb={2}>
                    <TextField
                        name="items.searchItem"
                        value={searchValue}
                        onChange={handleItemSearch}
                        label={
                            <FormattedMessage
                                id="items.search"
                                defaultMessage="Search for an item"
                            />
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <PaginatedItemTable />
            </Box>
        </div>
    )
}

ItemPagePresentation.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ItemPagePresentation)
