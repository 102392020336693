import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import {
    Business,
    Contacts,
    LocalShipping,
    MeetingRoom,
} from "@material-ui/icons"
import React, { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useForm } from "react-hook-form"
import { generateTimeList } from "../book/pickup/form"
import { yupResolver } from "@hookform/resolvers/yup"
import { goFetch } from "../../http"
import { schema } from "./validation"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import AccessorialSelector from "./AccessorialSelector"

const ContactAddModal = ({
    open,
    setOpen,
    addContactToList,
    gaCategory,
    logGAEvent,
}) => {
    const { openSnackbar } = useSnackbarContext()
    const [pickupAccessorials, setPickupAccessorials] = useState([])
    const [deliveryAccessorials, setDeliveryAccessorials] = useState([])
    const submitRef = useRef(null)
    const {
        handleSubmit,
        register,
        reset,
        formState: { errors, dirtyFields },
    } = useForm({ resolver: yupResolver(schema) })

    useEffect(() => {
        reset()
        setPickupAccessorials([])
        setDeliveryAccessorials([])
    }, [open])

    const handleClose = () => {
        setOpen(false)
    }

    const logGAField = (fieldName, label) => {
        const isDirty = fieldName.split(".").reduce((r, val) => {
            return r ? r[val] : undefined
        }, dirtyFields)
        if (isDirty) {
            logGAEvent(gaCategory, `Add Contact: ${fieldName}`, label)
        }
    }

    const addContact = async data => {
        logGAEvent(gaCategory, `Contact Added`)
        try {
            const response = await goFetch(
                `/contact/`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: { ...data, pickupAccessorials, deliveryAccessorials },
                },
                true
            )
            addContactToList(response.data)
            setOpen(false)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="contacts.add.success"
                    defaultMessage="Contact added"
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="contacts.add.error"
                    defaultMessage="Error adding contact"
                />,
                4000
            )
        }
    }

    const handleExternalSubmit = () => {
        if (submitRef.current) {
            submitRef.current.click()
        }
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="contacts-add-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="sm"
                scroll="paper"
            >
                <DialogTitle id="contacts-add-dialog-title">
                    <FormattedMessage
                        id="contacts.add.title"
                        defaultMessage="Add a new contact"
                    />
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(addContact)}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Box
                                style={{
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Business
                                        style={{ paddingRight: "5px" }}
                                        fontSize="medium"
                                    />
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="generalTerms.address"
                                            defaultMessage="Address"
                                        />
                                    </Typography>
                                </Box>
                                <Box>
                                    <TextField
                                        style={{
                                            width: "100%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.companyName"
                                                defaultMessage="Company Name"
                                            />
                                        }
                                        required
                                        {...register("name")}
                                        onBlur={() => logGAField("name")}
                                        error={!!errors.name}
                                        helperText={
                                            errors.name
                                                ? errors.name?.message
                                                : ""
                                        }
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        style={{
                                            width: "70%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.address1"
                                                defaultMessage="Address 1"
                                            />
                                        }
                                        {...register("address.street1")}
                                        onBlur={() =>
                                            logGAField("address.street1")
                                        }
                                        error={!!errors?.address?.street1}
                                        helperText={
                                            errors?.address?.street1
                                                ? errors?.address?.street1
                                                      ?.message
                                                : ""
                                        }
                                    />
                                    <TextField
                                        style={{
                                            width: "30%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.address2"
                                                defaultMessage="Address 2"
                                            />
                                        }
                                        {...register("address.street2")}
                                        onBlur={() =>
                                            logGAField("address.street2")
                                        }
                                        error={!!errors?.address?.street2}
                                        helperText={
                                            errors.address?.street2
                                                ? errors.address?.street2
                                                      .message
                                                : ""
                                        }
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        style={{
                                            width: "40%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.city"
                                                defaultMessage="City"
                                            />
                                        }
                                        {...register("address.city")}
                                        onBlur={() =>
                                            logGAField("address.city")
                                        }
                                        error={!!errors?.address?.city}
                                        helperText={
                                            errors.address?.city?.message ?? ""
                                        }
                                    />
                                    <TextField
                                        style={{
                                            width: "15%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.state"
                                                defaultMessage="State"
                                            />
                                        }
                                        {...register("address.state")}
                                        onBlur={() =>
                                            logGAField("address.state")
                                        }
                                        error={!!errors?.address?.state}
                                        helperText={
                                            errors.address?.state?.message ?? ""
                                        }
                                    />
                                    <TextField
                                        style={{
                                            width: "30%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.zipPostalCode"
                                                defaultMessage="Zip/Postal Code"
                                            />
                                        }
                                        {...register("address.postalCode")}
                                        onBlur={() =>
                                            logGAField("address.postalCode")
                                        }
                                        error={!!errors?.address?.postalCode}
                                        helperText={
                                            errors.address?.postalCode
                                                ?.message ?? ""
                                        }
                                    />
                                    <FormControl
                                        style={{
                                            width: "15%",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            required
                                            variant="standard"
                                            defaultValue={"US"}
                                            labelId="contact-address-country-select-label"
                                            id="contact-address-country-select"
                                            {...register("address.country")}
                                            onBlur={evt =>
                                                logGAField(
                                                    "address.country",
                                                    evt.target.value
                                                )
                                            }
                                            error={!!errors?.address?.country}
                                            helperText={
                                                errors.address?.country
                                                    ?.message ?? ""
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms.country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                        >
                                            <MenuItem value={"US"}>US</MenuItem>
                                            <MenuItem value={"CA"}>CA</MenuItem>
                                            <MenuItem value={"MX"}>MX</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            >
                                <Box style={{ width: "100%" }}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Contacts
                                            style={{ paddingRight: "5px" }}
                                            fontSize="medium"
                                        />
                                        <Typography variant="body2">
                                            <FormattedMessage
                                                id="contacts.card.header.primaryContact"
                                                defaultMessage="Primary Contact"
                                            />
                                        </Typography>
                                    </Box>
                                    <TextField
                                        style={{
                                            width: "100%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="contacts.name"
                                                defaultMessage="Name"
                                            />
                                        }
                                        {...register("contact.name")}
                                        onBlur={() =>
                                            logGAField("contact.name")
                                        }
                                        error={!!errors?.contact?.name}
                                        helperText={
                                            errors.contact?.name?.message ?? ""
                                        }
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        style={{
                                            width: "35%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        {...register(
                                            "contact.phone.phoneNumber"
                                        )}
                                        onBlur={() =>
                                            logGAField(
                                                "contact.phone.phoneNumber"
                                            )
                                        }
                                        error={
                                            !!errors?.contact?.phone
                                                ?.phoneNumber
                                        }
                                        helperText={
                                            errors.contact?.phone?.phoneNumber
                                                ?.message ?? ""
                                        }
                                        label={
                                            <FormattedMessage
                                                id="generalTerms.phone"
                                                defaultMessage="Phone"
                                            />
                                        }
                                    />
                                    <TextField
                                        style={{
                                            width: "15%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="contacts.phoneExt"
                                                defaultMessage="Ext."
                                            />
                                        }
                                        {...register("contact.phone.extension")}
                                        onBlur={() =>
                                            logGAField(
                                                "contact.phone.extension"
                                            )
                                        }
                                        error={
                                            !!errors?.contact?.phone?.extension
                                        }
                                        helperText={
                                            errors.contact?.phone?.extension
                                                ?.message ?? ""
                                        }
                                    />
                                    <TextField
                                        style={{
                                            width: "50%",
                                            paddingRight: "10px",
                                        }}
                                        required
                                        label={
                                            <FormattedMessage
                                                id="contacts.form__email"
                                                defaultMessage="Email"
                                            />
                                        }
                                        {...register("contact.email")}
                                        onBlur={() =>
                                            logGAField("contact.email")
                                        }
                                        error={!!errors?.contact?.email}
                                        helperText={
                                            errors.contact?.email?.message ?? ""
                                        }
                                    />
                                </Box>
                            </Box>

                            <Box
                                style={{
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <MeetingRoom
                                        style={{ paddingRight: "5px" }}
                                        fontSize="medium"
                                    />
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="generalTerms__accessorials"
                                            defaultMessage="Accessorials"
                                        />
                                    </Typography>
                                </Box>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Box
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <AccessorialSelector
                                            selected={pickupAccessorials}
                                            setSelected={setPickupAccessorials}
                                            isPickup={true}
                                            gaCategory={gaCategory}
                                            logGAEvent={logGAEvent}
                                        />
                                    </Box>
                                    <Box
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <AccessorialSelector
                                            selected={deliveryAccessorials}
                                            setSelected={
                                                setDeliveryAccessorials
                                            }
                                            isPickup={false}
                                            gaCategory={gaCategory}
                                            logGAEvent={logGAEvent}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    width: "100%",
                                    marginBottom: "24px",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <LocalShipping
                                        style={{ paddingRight: "5px" }}
                                        fontSize="medium"
                                    />
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="contacts.pickupInformation"
                                            defaultMessage="Pickup Information"
                                        />
                                    </Typography>
                                </Box>
                                <Box style={{ width: "100%" }}>
                                    <FormControl
                                        style={{
                                            width: "50%",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <InputLabel>
                                            <FormattedMessage
                                                id="generalTerms.pickUpInformation__readyTime"
                                                defaultMessage="Ready Time"
                                            />
                                        </InputLabel>
                                        <Select
                                            defaultValue={"6:00 AM"}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set your desired maxHeight here
                                                    },
                                                },
                                            }}
                                            variant="standard"
                                            labelId="contact-address-country-select-label"
                                            id="contact-address-country-select"
                                            {...register(
                                                "pickupInformation.readyTime"
                                            )}
                                            onBlur={evt =>
                                                logGAField(
                                                    "pickupInformation.readyTime",
                                                    evt.target.value
                                                )
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms.pickUpInformation__readyTime"
                                                    defaultMessage="Ready Time"
                                                />
                                            }
                                        >
                                            {generateTimeList().map(time => (
                                                <MenuItem
                                                    key={time.value}
                                                    value={time.value}
                                                >
                                                    {time.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        style={{
                                            width: "50%",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <InputLabel>
                                            <FormattedMessage
                                                id="generalTerms.pickUpInformation__closeTime"
                                                defaultMessage="Close Time"
                                            />
                                        </InputLabel>
                                        <Select
                                            defaultValue={"1:00 PM"}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200, // Set your desired maxHeight here
                                                    },
                                                },
                                            }}
                                            variant="standard"
                                            labelId="contact-address-country-select-label"
                                            id="contact-address-country-select"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms.pickUpInformation__closeTime"
                                                    defaultMessage="Close Time"
                                                />
                                            }
                                            {...register(
                                                "pickupInformation.closeTime"
                                            )}
                                            onBlur={evt =>
                                                logGAField(
                                                    "pickupInformation.closeTime",
                                                    evt.target.value
                                                )
                                            }
                                        >
                                            {generateTimeList().map(time => (
                                                <MenuItem
                                                    key={time.value}
                                                    value={time.value}
                                                >
                                                    {time.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Box style={{ width: "100%" }}>
                                        <TextField
                                            style={{
                                                width: "100%",
                                                paddingRight: "10px",
                                            }}
                                            label={
                                                <FormattedMessage
                                                    id="contacts.pickupName"
                                                    defaultMessage="Pickup Name"
                                                />
                                            }
                                            {...register(
                                                "pickupInformation.name"
                                            )}
                                            onBlur={() =>
                                                logGAField(
                                                    "pickupInformation.name"
                                                )
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            style={{
                                                width: "35%",
                                                paddingRight: "10px",
                                            }}
                                            label={
                                                <FormattedMessage
                                                    id="contacts.pickupPhone"
                                                    defaultMessage="Pickup Phone"
                                                />
                                            }
                                            {...register(
                                                "pickupInformation.phone.phoneNumber"
                                            )}
                                            onBlur={() =>
                                                logGAField(
                                                    "pickupInformation.phone.phoneNumber"
                                                )
                                            }
                                            error={
                                                !!errors?.pickupInformation
                                                    ?.phone?.phoneNumber
                                            }
                                            helperText={
                                                errors.pickupInformation?.phone
                                                    ?.phoneNumber?.message ?? ""
                                            }
                                        />
                                        <TextField
                                            style={{
                                                width: "15%",
                                                paddingRight: "10px",
                                            }}
                                            label={
                                                <FormattedMessage
                                                    id="contacts.phoneExt"
                                                    defaultMessage="Ext."
                                                />
                                            }
                                            {...register(
                                                "pickupInformation.phone.extension"
                                            )}
                                            onBlur={() =>
                                                logGAField(
                                                    "pickupInformation.phone.extension"
                                                )
                                            }
                                            error={
                                                !!errors?.pickupInformation
                                                    ?.phone?.extension
                                            }
                                            helperText={
                                                errors.pickupInformation?.phone
                                                    ?.extension?.message ?? ""
                                            }
                                        />
                                        <TextField
                                            style={{
                                                width: "50%",
                                                paddingRight: "10px",
                                            }}
                                            label={
                                                <FormattedMessage
                                                    id="contacts.pickupEmail"
                                                    defaultMessage="Pickup email"
                                                />
                                            }
                                            {...register(
                                                "pickupInformation.email"
                                            )}
                                            onBlur={() =>
                                                logGAField(
                                                    "pickupInformation.email"
                                                )
                                            }
                                            error={
                                                !!errors?.pickupInformation
                                                    ?.email
                                            }
                                            helperText={
                                                errors.pickupInformation?.email
                                                    ?.message ?? ""
                                            }
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <TextField
                                        style={{
                                            width: "100%",
                                            paddingRight: "10px",
                                        }}
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__specialInstruction"
                                                defaultMessage="Special Instructions"
                                            />
                                        }
                                        {...register("note")}
                                        onBlur={() => logGAField("note")}
                                        multiline
                                        maxLength="255"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* This is a hidden submit button because the form needs to live inside of the DialogContent to maintain styles. 
                        It uses a ref that submits the form when the Update button is pushed*/}
                        <Button
                            ref={submitRef}
                            style={{ display: "none" }}
                            type="submit"
                        />
                    </form>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleClose}
                        style={{ margin: "6px 12px" }}
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        style={{ margin: "6px 12px" }}
                        autoFocus
                        onClick={handleExternalSubmit}
                    >
                        <FormattedMessage
                            id="generalTerms__save"
                            defaultMessage="Save"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ContactAddModal
