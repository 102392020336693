import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    buttonContainer: {
        paddingTop: "30px",
    },
})

export default function NextButton({
    handleComplete,
    isItemsStep,
    invalid,
    isLoading,
    isCanadaMexico,
}) {
    const classes = useStyles()

    return (
        <Grid
            item
            container
            justify="center"
            className={classes.buttonContainer}
        >
            <Button
                id="nextButton"
                color="secondary"
                variant="contained"
                onClick={() => handleComplete(!isCanadaMexico ? invalid : null)}
                type="submit"
                disabled={isLoading}
            >
                {isItemsStep ? (
                    <FormattedMessage
                        id="bookShipment__getRates"
                        defaultMessage="Get Rates"
                    />
                ) : (
                    <FormattedMessage
                        id="generalTerms__next"
                        defaultMessage="Next"
                    />
                )}
            </Button>
        </Grid>
    )
}
