import moment from "moment"
import createSelector from "re-reselect"
import { newFreightDirectHandlingUnit } from "../../../reducers/search"

const shipmentIdSelector = (state, props) =>
    props?.match?.params?.shipmentId ?? ""

const returnModeSelector = (state, props) => props?.isReturnMode ?? false

const quoteSelector = createSelector(
    state => state.redesign.activeRates,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const shipmentSelector = createSelector(
    state => state.shipment.list,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const contactIdSelector = (state, props) => props?.toContact ?? ""

const contactSelector = createSelector(
    contactIdSelector,
    state => state?.addressBook?.addresses,
    (contactId, contacts) => {
        if (!contactId || !contacts || !contacts.length) return undefined
        return contacts.find(item => item?.contact?.id === contactId)
    }
)(contactIdSelector)

export const billToLocationSelector = createSelector(
    quoteSelector,
    state => state?.user?.profile?.locations,
    state => state?.user?.profile?.preferences?.defaultLocationId,
    returnModeSelector,
    (quote, locations = [], defaultLocationId, isReturnMode) => {
        if (isReturnMode) {
            return locations.find(
                loc => loc.locationType === "FEDEX_DIRECT_RETURNS"
            )
        } else {
            return (
                locations.find(
                    loc => (loc?.cpgCode ?? "") === quote?.search?.cpg
                ) ||
                locations.find(
                    loc =>
                        loc?._id === defaultLocationId &&
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                ) ||
                locations.find(
                    loc =>
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                )
            )
        }
    }
)(shipmentIdSelector)

export const nextBusinessDay = () => {
    if (moment().day() === 5) {
        return moment.utc(
            moment()
                .add(3, "days")
                .format("YYYY-MM-DD")
        )
    } else if (moment().day() === 6) {
        return moment.utc(
            moment()
                .add(2, "days")
                .format("YYYY-MM-DD")
        )
    } else {
        return moment.utc(
            moment()
                .add(1, "days")
                .format("YYYY-MM-DD")
        )
    }
}

export function lookupContact(address, id) {
    const contact = (address?.contacts ?? []).find(item => item._id === id)
    return transformContact(contact)
}

const transformContact = contact => ({
    _id: contact?._id,
    name: contact?.name,
    phone: contact?.phone,
    email: contact?.email?.email_address,
})

export const formSelector = createSelector(
    quoteSelector,
    shipmentIdSelector,
    billToLocationSelector,
    contactSelector,
    returnModeSelector,
    shipmentSelector,
    state => state?.user?.profile,
    (
        quote,
        shipmentId,
        billToLocation,
        contact,
        isReturnMode,
        shipment,
        user,
        accessorials
    ) => {
        const isFreightBox =
            billToLocation?.locationType === "FEDEX_FREIGHT_BOX"

        const shippingContact = {
            name: [user?.firstname, user?.lastname].filter(x => x).join(" "),
            phone: user?.phone_number,
            email: {
                email_address: user?.email?.email_address,
            },
        }

        if (!shipmentId) {
            const isFreightDirectReturns =
                billToLocation?.locationType === "FEDEX_DIRECT_RETURNS"

            const isFreightDirect =
                billToLocation?.locationType === "FEDEX_DIRECT"

            const isShipperEnabled = billToLocation?.isShipperEnabled
            const isThirdPartyEnabled = billToLocation?.is3rdPartyEnabled

            const isThirdPartyOnly = !isShipperEnabled && isThirdPartyEnabled

            const hazmatEmergency =
                user?.preferences?.hazmatEmergencyContact ?? {}

            const exportCustomsBrokerageInfo =
                user?.preferences?.exportCustomsBrokerageInfo ?? {}

            const importCustomsBrokerageInfo =
                user?.preferences?.importCustomsBrokerageInfo ?? {}

            const locationPickupAccessorials = (
                billToLocation?.pickupAccessorials ?? []
            ).map(entry => entry.value) ?? ["DOCKPU"]

            const pickupAccessorials = locationPickupAccessorials
            const deliveryAccessorials = ["DOCKDEL"]

            const calculatedMode = isFreightDirectReturns
                ? "inbound"
                : isThirdPartyOnly
                ? "thirdParty"
                : "outbound"

            let correctPaymentType = null

            if (
                isShipperEnabled &&
                !isFreightDirect &&
                !isFreightDirectReturns &&
                !isFreightBox
            ) {
                if (calculatedMode === "inbound") {
                    correctPaymentType = "COLLECT"
                } else if (calculatedMode === "outbound") {
                    correctPaymentType = "PREPAID"
                }
            }

            const setDefaultCountry = country => {
                if (country === "MX") {
                    return "MX"
                } else {
                    return "US"
                }
            }

            const destinationCountry = isFreightDirectReturns
                ? billToLocation?.shippingAddress?.address?.country
                : contact?.address?.country ??
                  setDefaultCountry(
                      billToLocation?.shippingAddress?.address?.country
                  )

            return {
                includeThirdParty: true,
                shouldReset: false,
                isAdvancedItems: false,
                alerts: {
                    user: user?.preferences?.alerts,
                },
                hazmatEmergency,
                pickupAccessorials,
                deliveryAccessorials,
                shippingContact,
                selectedLocation: billToLocation,
                mode: calculatedMode,
                origin:
                    isShipperEnabled && !isFreightDirectReturns
                        ? {
                              ...billToLocation,
                              contact: shippingContact,
                              showAccessorials:
                                  locationPickupAccessorials.toString() !==
                                  ["DOCKPU"].toString(),
                          }
                        : isThirdPartyOnly
                        ? {
                              shippingAddress: {
                                  address: { country: destinationCountry },
                              },
                          }
                        : null,
                destination: isFreightDirectReturns
                    ? { ...billToLocation, contact: shippingContact }
                    : {
                          shippingAddress: {
                              address: { country: destinationCountry },
                          },
                      },
                pickupDate: isFreightDirectReturns
                    ? nextBusinessDay()
                    : moment.utc(moment().format("YYYY-MM-DD")),
                billToLocation: billToLocation?.cpgCode,
                isFedexFileEEI: quote?.isFedexFileEEI,
                isFreightDirect,
                isFreightBox:
                    billToLocation?.locationType === "FEDEX_FREIGHT_BOX",
                isFreightDirectReturns,
                handlingUnits: [newFreightDirectHandlingUnit()],
                isQuickRate: true,
                totalLinearFeet: 0,
                userProvidedTotalLinearFeet: false,
                capLoadTotalLinearFeet: 0,
                volumeTotalLinearFeet: 0,
                isOverLTLLimit: false,
                showWarning: false,
                preferredCurrencyCode: "USD",
                preferredSystemOfMeasurement: "IMPERIAL",
                exportCustomsBrokerageInfo,
                importCustomsBrokerageInfo,
                paymentType: correctPaymentType,
            }
        }
        const isFreightDirectReturns =
            billToLocation?.locationType === "FEDEX_DIRECT_RETURNS" ||
            quote?.search?.isFreightDirectReturns

        const isFreightDirect =
            billToLocation?.locationType === "FEDEX_DIRECT" ||
            quote?.search?.isFreightDirect

        const isQuickRate = quote?.search?.isQuickRate

        const shipmentDestination = shipment?.shipment?.destination
        const destination = {
            shippingAddress: {
                address: { ...shipmentDestination?.address },
                name: shipmentDestination?.name,
            },
            contact: shipmentDestination?.contact,
            readyTime: isFreightDirectReturns ? "7:00 AM" : null,
            closeTime: isFreightDirectReturns ? "8:00 PM" : null,
        }

        const mode =
            isReturnMode || isFreightDirectReturns
                ? "inbound"
                : quote?.search?.mode

        let handlingUnits = quote?.handlingUnits ?? []

        handlingUnits = handlingUnits.map(hu => {
            hu.protectFromFreezing = quote?.search?.protectFromFreezing
            hu.isInBondShipment = quote?.search?.isInBondShipment
            return hu
        })
        return {
            ...quote?.search,
            isQuickRate,
            mode,
            proNumber: shipment?.identifiers?.proNumber,
            handlingUnits: handlingUnits,
            pickupDate: isReturnMode
                ? nextBusinessDay()
                : moment.utc(quote?.search?.pickupDate),
            alerts: {
                user: user?.preferences?.alerts,
            },
            origin: isReturnMode
                ? destination
                : {
                      ...quote?.search?.origin,
                      showAccessorials:
                          quote?.search?.pickupAccessorials.length > 0,
                  },
            destination: isReturnMode
                ? { ...billToLocation, contact: shippingContact }
                : mode === "inbound"
                ? {
                      ...quote?.search?.destination,
                      shippingAddress: billToLocation?.shippingAddress,
                  }
                : {
                      ...quote?.search?.destination,
                      showAccessorials:
                          quote?.search?.deliveryAccessorials.length > 0,
                  },
            cpg: isReturnMode ? billToLocation?.cpgCode : quote?.search?.cpg,
            billToLocation: isReturnMode
                ? billToLocation?.cpgCode
                : quote?.search?.cpg,
            thirdPartyOnly: !billToLocation?.isShipperEnabled,
            isFedexFileEEI: quote?.isFedexFileEEI,
            isFreightBox: billToLocation?.locationType === "FEDEX_FREIGHT_BOX",
            isFreightDirect: isFreightDirect,
            isFreightDirectReturns: isReturnMode
                ? true
                : isFreightDirectReturns,
            preferredCurrencyCode: quote?.search?.preferredCurrencyCode,
            preferredSystemOfMeasurement:
                quote?.search?.preferredSystemOfMeasurement,
            selectedLocation: billToLocation,
            paymentType: quote?.search?.paymentType,
        }
    }
)(shipmentIdSelector)

export const locationPermissionSelector = createSelector(
    billToLocationSelector,
    billToLocation => ({
        canCreateRates: !!billToLocation?.users?.[0]?.permissions?.createRates
            ?.value,
        canCreateShipments: !!billToLocation?.users?.[0]?.permissions
            ?.createShipments?.value,
        canViewRateAmounts: !!billToLocation?.users?.[0]?.permissions
            ?.viewRateAmounts?.value,
    })
)(shipmentIdSelector)
