import { makeStyles } from "@material-ui/core"

export const styles = theme => ({
    itemPage__container: {
        padding: theme.spacing(4),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        minWidth: "898px",
        maxWidth: "1600px",
        margin: "0 auto",
    },

    itemPage__title: {
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    conactLabel: {
        fontSize: "12px",
        color: "#757575",
        marginBottom: "1px",
    },
    button__container: {
        marginBottom: "20px",
    },
    button__close: {
        marginLeft: "auto",
    },
    content: {
        padding: "24px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        overflowY: "scroll",
    },
    header: {
        paddingBottom: "24px",
    },
    error__container: {
        margin: "35px 0",
    },
    error__item: {
        backgroundColor: "#D4D4D4",
        padding: "8px",
        marginTop: "5px",
    },
    error__line: {
        marginRight: "10px",
    },
    table__container: {
        height: "200px",
        position: "relative",
        // overflowX: "scroll",
    },
    table__row: {
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
    },

    error__field: {
        color: "#D40000",
    },
    summary: {
        margin: "16px",
    },
    headingTableTitle: {
        fontWeight: 600,
    },
})

export const useStyles = makeStyles({
    table_row: {
        height: "60px",
    },
})
