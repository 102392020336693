import React, { Fragment, useEffect } from "react"
import formField from "../../../form/form-field"
import { Field } from "redux-form"
import { FormattedMessage } from "react-intl"
import {
    Button,
    Collapse,
    Grid,
    Tooltip,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import { Info } from "@material-ui/icons"
import InfoToolTip from "../../../common/InfoToolTip"
import { fetchHSCCode, fetchHSCDetails } from "../request"
import moment from "moment"

const StyledToolTip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.light,
        color: "white",
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip)

const StyledInfoTooltip = ({ text }) => {
    return (
        <StyledToolTip title={text} placement="right">
            <IconButton aria-label="info">
                <Info />
            </IconButton>
        </StyledToolTip>
    )
}

export const EstimatedDutiesTaxesDescription = ({
    fields,
    estimatedDutiesTaxesDescriptions,
    category,
    isExpanded,
    handlingUnits,
    data,
    originCountry,
    destinationCountry,
    pickupDateStr,
    ...props
}) => {
    useEffect(() => {
        fields.removeAll()
        if (isExpanded) {
            initValues()
        }
    }, [isExpanded])
    const initValues = () => {
        handlingUnits.forEach(handlingUnit => {
            handlingUnit.items.forEach(unit => {
                fields.push({
                    item: unit,
                    descriptionValue: unit.description,
                    harmonizedCode: unit.harmonizedCode,
                })
            })
        })
    }

    const today = moment().format("YYYY-MM-DD")

    const searchHSC = async () => {
        const {
            harmonizedCode,
            descriptionValue,
        } = estimatedDutiesTaxesDescriptions.value[0]
        const basePayload = { destinationCountry }

        if (harmonizedCode && !descriptionValue) {
            const payload = {
                ...basePayload,
                importDate: today,
                harmonizedCode,
            }
            await fetchHSCCode(payload)
        } else if (!harmonizedCode && descriptionValue) {
            const payload = {
                searchText: descriptionValue,
                searchType: "DESCRIPTION",
                searchLevel: "FULL",
                destinationCountryCode: destinationCountry,
                shipDate: pickupDateStr,
            }
            await fetchHSCDetails(payload)
        } else if (harmonizedCode && descriptionValue) {
            const payload1 = {
                ...basePayload,
                importDate: pickupDateStr,
                harmonizedCode,
            }
            const payload2 = {
                searchText: descriptionValue,
                searchType: "DESCRIPTION",
                searchLevel: "FULL",
                destinationCountryCode: destinationCountry,
                shipDate: pickupDateStr,
            }
            await Promise.all([
                fetchHSCCode(payload1),
                fetchHSCDetails(payload2),
            ])
        }
    }

    return (
        <Fragment>
            {fields.map((item, index) => (
                <Grid container key={"estimatedDutiesTaxesWrapper-" + index}>
                    <Grid container xs={1} alignItems="flex-end">
                        <Typography variant="subtitle1" align="center">
                            <FormattedMessage
                                id="book.estimatedDutiesTaxes__ItemCounter"
                                defaultMessage="Item"
                            />
                            {index + 1}
                        </Typography>
                    </Grid>
                    <Grid container xs={5}>
                        <Field
                            category={category}
                            name={`${item}.descriptionValue`}
                            label={
                                <FormattedMessage
                                    id="book.estimatedDutiesTaxes__DescriptionOfGood"
                                    defaultMessage="Description of Goods"
                                />
                            }
                            component={formField}
                        />
                    </Grid>
                    <Grid item alignItems="center" xs={1}>
                        <StyledInfoTooltip
                            text={
                                <Fragment>
                                    <FormattedMessage
                                        id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_Heading"
                                        defaultMessage="Enter a detailed description including:"
                                    />
                                    <ul style={{ paddingLeft: "15px" }}>
                                        <li>
                                            <FormattedMessage
                                                id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_ItemNameDesc"
                                                defaultMessage={`Item name and type (e.g., "men's cotton shirts")`}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_MaterialUsedDesc"
                                                defaultMessage={`Materials used (e.g., "100% silk")`}
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_intendedUse"
                                                defaultMessage="Intended use"
                                            />
                                        </li>
                                    </ul>
                                    <FormattedMessage
                                        id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_keyTip"
                                        defaultMessage={`Key Tip: Specific details help avoid clearance delays and ensure correct charges. Avoid vague terms like "electronics." Example: "Ladies' 100% silk knitted blouse”`}
                                    />
                                </Fragment>
                            }
                        ></StyledInfoTooltip>
                    </Grid>
                    <Grid container xs={4}>
                        <Field
                            category={category}
                            name={`${item}.harmonizedCode`}
                            label={
                                <FormattedMessage
                                    id="book.estimatedDutiesTaxes__HarmonizedCode"
                                    defaultMessage="Harmonized Code (Optional)"
                                />
                            }
                            component={formField}
                        />
                    </Grid>
                    <Grid
                        item
                        alignItems="center"
                        xs={1}
                        style={{ display: "flex" }}
                    >
                        <StyledInfoTooltip
                            text={
                                <Fragment>
                                    <FormattedMessage
                                        id="book.estimatedDutiesTaxes__DescriptionOfGood.toolTip_hscCode"
                                        defaultMessage="A proper harmonized code will help expedite customs clearance as well as help with a more accurate estimation of duties and taxes."
                                    />
                                </Fragment>
                            }
                        ></StyledInfoTooltip>
                        <SearchIcon onClick={() => searchHSC()} />
                    </Grid>
                </Grid>
            ))}
        </Fragment>
    )
}
