import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Prompt } from "react-router-dom"
import { reduxForm } from "redux-form"
import { get } from "lodash"
import { combineValidators, composeValidators } from "revalidate"
import { Grid, withStyles, Typography, Snackbar } from "@material-ui/core"
import {
    basicUpdateUser,
    setActiveLocation,
    trackGAEvent,
} from "../../actions/user"
import { changePath } from "../../actions"
import LocationContainer from "./components/LocationInfo/LocationInfo"
import UnsavedChanges from "./components/unsavedChanges"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isRequiredIf,
    isNumeric,
} from "../../actions/validation"
import { locationUpdateConfirm } from "../../messages/confirmation-constants"
import { IntercomAPI } from "react-intercom"
import { styles } from "./styles"
import LocationsNav from "./components/LocationsNav"

const GACategory = "Location"

const LocationsPresentation = ({
    classes,
    locations,
    handleSubmit,
    dirty,
    activeLocationIndex,
    defaultLocationId,
    onAddLocationSelect,
    setActiveLocation,
    basicUpdateUser,
    initialValues,
    trackGA,
    history,
}) => {
    const [sbOpen, setSbOpen] = useState(false)
    const [sbVariant, setSbVariant] = useState("info")
    const [sbMessage, setSbMessage] = useState("")
    const [unsavedChangesModalOpen, setUnSavedChangesModalOpen] = useState(
        false
    )
    const [bufferedIndex, setBufferedIndex] = useState(null)

    useEffect(() => {
        basicUpdateUser()
        if (history?.location?.state) {
            setActiveLocation(history.location?.state.activeLocationIndex)
        } else {
            setActiveLocation(0)
        }
        IntercomAPI("trackEvent", "locations")
        trackGA(GACategory, "Page Mount")
    }, [])

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        setSbOpen(false)
    }

    const handleSnackbarOpen = (variant, message) => {
        setSbOpen(true)
        setSbVariant(variant)
        setSbMessage(message)
    }

    const handleActiveLocationChange = index => {
        if (index !== activeLocationIndex) {
            trackGA(GACategory, "Location Change Click")
            if (dirty) {
                setUnSavedChangesModalOpen(true)
                setBufferedIndex(index)
                trackGA(GACategory, "Unsaved Change Modal Open")
            } else {
                setActiveLocation(index)
            }
        }
    }

    const handleUnsavedChangesClose = () => {
        setUnSavedChangesModalOpen(false)
        trackGA(GACategory, "Unsaved Change Modal Cancel Click")
    }

    const handleUnsavedChangesContinue = () => {
        setActiveLocation(bufferedIndex)
        setUnSavedChangesModalOpen(false)
        setBufferedIndex(null)
        trackGA(GACategory, "Unsaved Change Modal Continue Click")
    }

    const isRequested =
        get(locations[activeLocationIndex], "users[0].role") === "requested"

    return (
        <Grid
            className={classes.location__container}
            container
            alignItems="flex-start"
        >
            <Prompt
                when={dirty && !isRequested}
                message={locationUpdateConfirm}
            />
            <UnsavedChanges
                message={locationUpdateConfirm}
                handleClose={handleUnsavedChangesClose}
                handleContinue={handleUnsavedChangesContinue}
                open={unsavedChangesModalOpen}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={sbOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                id="snackbar"
            >
                <ErrorSnackbarContentWrapper
                    variant={sbVariant}
                    onClose={handleSnackbarClose}
                    message={
                        <Typography variant="body2">{sbMessage}</Typography>
                    }
                />
            </Snackbar>
            <LocationsNav
                defaultLocationId={defaultLocationId}
                onAddLocationSelect={onAddLocationSelect}
                classes={classes}
                locations={locations}
                handleActiveLocationChange={handleActiveLocationChange}
                activeLocationIndex={activeLocationIndex}
            />
            {locations[activeLocationIndex] && (
                <Grid item container xs={10} className={classes.right__content}>
                    <LocationContainer
                        allLocations={locations}
                        location={locations[activeLocationIndex]}
                        form={"location"}
                        index={activeLocationIndex}
                        handleSnackbarOpen={handleSnackbarOpen}
                        handleSubmit={handleSubmit}
                        dirty={dirty}
                        startedDefault={get(
                            initialValues,
                            "isDefaultLocation",
                            false
                        )}
                    />
                </Grid>
            )}
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const locations = get(state, "user.profile.locations", [])
    if (!locations.length) return locations
    const activeLocationIndex = get(state, "user.activeLocation", 0)
    const isDefaultLocation =
        get(locations[activeLocationIndex], "_id", "") ===
        get(state, "user.profile.preferences.defaultLocationId", "")

    const activeLocation = locations[activeLocationIndex] ?? {
        pickupAccessorials: [],
        deliveryAccessorials: [],
    }

    let existingPickupAccessorials = activeLocation?.pickupAccessorials ?? [
        { value: "DOCKPU" },
    ]
    let existingDeliveryAccessorials = activeLocation?.deliveryAccessorials ?? [
        { value: "DOCKDEL" },
    ]

    const pickupAccessorials = existingPickupAccessorials
        .map(entry => entry.value)
        .join(",")

    const deliveryAccessorials = existingDeliveryAccessorials
        .map(entry => entry.value)
        .join(",")

    const initalLocationValues = { ...locations[activeLocationIndex] }

    if (initalLocationValues?.shipperLoadCount == undefined) {
        initalLocationValues.shipperLoadCount = {
            address: initalLocationValues?.shippingAddress?.address,
            companyName: initalLocationValues?.shippingAddress?.name,
        }
    }
    return {
        locations,
        activeLocationIndex,
        initialValues: {
            readyTime: "6:00 AM",
            closeTime: "1:00 PM",
            ...initalLocationValues,
            isDefaultLocation,
            pickupAccessorials,
            deliveryAccessorials,
        },
        defaultLocationId: get(
            state,
            "user.profile.preferences.defaultLocationId",
            ""
        ),
    }
}

const mapDispatchToProps = dispatch => ({
    basicUpdateUser: () => dispatch(basicUpdateUser()),
    setActiveLocation: index => dispatch(setActiveLocation(index)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    onAddLocationSelect: () => {
        dispatch(trackGAEvent("Location", "Add Location Button Click"))
        dispatch(changePath("/locations/add"))
    },
})

const isRequiredIfStreet1Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street1
)
const isRequiredIfStreet2Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street2
)

const isRequiredIfPickUpAndDestroy = isRequiredIf(values =>
    get(values, "pickupAndDestroyLocation")
)

const isRequiredIfStreet1MissingPickAndDestoy = isRequiredIf(
    values =>
        !values?.pickupAndDestroyLocation?.billingAddress?.street1 &&
        values?.pickupAndDestroyLocation
)
const isRequiredIfStreet2MissingPickAndDestoy = isRequiredIf(
    values =>
        !values?.pickupAndDestroyLocation?.billingAddress?.street2 &&
        values?.pickupAndDestroyLocation
)

const isRequiredIfIsEnableTrailerManisfest = isRequiredIf(values => {
    return get(values, "shipperLoadCount.isEnableTrailerManifest")
})

const isRequiredIfStreet1MissingTrailerManisfest = isRequiredIf(
    values =>
        !values?.shipperLoadCount?.address?.street1 &&
        values?.shipperLoadCount?.isEnableTrailerManifest
)
const isRequiredIfStreet2MissingTrailerManisfest = isRequiredIf(
    values =>
        !values?.shipperLoadCount?.address?.street2 &&
        values?.shipperLoadCount?.isEnableTrailerManifest
)

const locationItemValidation = combineValidators({
    pickupContact: {
        email: {
            email_address: composeValidators(emailValidator)({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
        phone: {
            phone_number: composeValidators(
                countryPhoneValidator("shippingAddress.address.country")
            )({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
    },
    shippingAddress: {
        name: isRequired({
            field: {
                id: "generalTerms__companyName",
                defaultMessage: "Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequired({
                field: {
                    id: "generalTerms__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequired({
                field: {
                    id: "generalTerms__state",
                    defaultMessage: "State",
                },
            }),
            postalCode: isRequired({
                field: {
                    id: "generalTerms__postalCode",
                    defaultMessage: "Postal Code",
                },
            }),
        },
    },
    fedexBillToAccount: isNumeric({
        field: {
            id: "locations__thirdPartyAccountNumber",
            defaultMessage: "Third Party Account #",
        },
    }),
    fedexFreightAccount: isNumeric({
        field: {
            id: "locations__shipperAccountNumber",
            defaultMessage: "Shipper Account #",
        },
    }),
    pickupAndDestroyLocation: {
        fedexBillToAccount: isRequiredIfPickUpAndDestroy({
            field: {
                id:
                    "locations.pickUpAndDetroyInformation__thirdPartyAccountNumber",
                defaultMessage: "Third Party Account #",
            },
        }),
        billingCompanyName: isRequiredIfPickUpAndDestroy({
            field: {
                id: "locations.pickUpAndDetroyInformation__companyName",
                defaultMessage: "Company Name",
            },
        }),
        billingAddress: {
            street1: isRequiredIfStreet2MissingPickAndDestoy({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1MissingPickAndDestoy({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__stateProvince",
                    defaultMessage: "State",
                },
            }),
            postalCode: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__zipPostalCode",
                    defaultMessage: "Postal Code",
                },
            }),
            country: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "pickupAndDestroyLocation.billingAddress.country",
                    defaultMessage: "Country",
                },
            }),
        },
    },
    shipperLoadCount: {
        companyName: isRequiredIfIsEnableTrailerManisfest({
            field: {
                id: "generalTerms__companyName",
                defaultMessage: "Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2MissingTrailerManisfest({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1MissingTrailerManisfest({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequiredIfIsEnableTrailerManisfest({
                field: {
                    id: "locations.pickUpAndDetroyInformation__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequiredIfIsEnableTrailerManisfest({
                field: {
                    id: "locations.pickUpAndDetroyInformation__stateProvince",
                    defaultMessage: "State",
                },
            }),
            postalCode: isRequiredIfIsEnableTrailerManisfest({
                field: {
                    id: "locations.pickUpAndDetroyInformation__zipPostalCode",
                    defaultMessage: "Postal Code",
                },
            }),
            country: isRequiredIfIsEnableTrailerManisfest({
                field: {
                    id: "pickupAndDestroyLocation.billingAddress.country",
                    defaultMessage: "Country",
                },
            }),
        },
    },
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "location",
            enableReinitialize: true,
            validate: locationItemValidation,
        })(LocationsPresentation)
    )
)
