import React, { Fragment, useState } from "react"
import { connect } from "react-redux"
import csv from "csvtojson"
import { composeValidators } from "revalidate"
import { get } from "lodash"
import {
    Grid,
    Button,
    withStyles,
    Typography,
    Input,
    Tooltip,
    Dialog,
} from "@material-ui/core"
import { Table, Column, AutoSizer } from "react-virtualized"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import CircularProgress from "@material-ui/core/CircularProgress"
import FileUpload from "../util/file-upload"
import {
    hasLength,
    isRequired,
    isNumeric,
    isValidFreightClass,
    isValidPrefrenceType,
    isValidPackageType,
    isValidBoolean,
    isRequiredWithHazmat,
    isHazMatContainers,
    ishazMatPkgGrp,
    isHazMatClasses,
    checkHazmatId,
} from "../../actions/validation"
import {
    ItemImportCSVLoadError,
    contactsImportCSVTooLargeError,
    ItemImportError,
} from "../../messages/error-constants"
import { FormattedMessage, injectIntl } from "react-intl"
import { styles } from "./styles"
import { importItems } from "../../actions/item"

const headers = [
    "description",
    "pieces",
    "freight_class",
    "weight",
    "preferred_system_of_measurement",
    "package_type",
    "length",
    "width",
    "height",
    "nmfc_code",
    "nmfc_subcode",
    "stackable",
    "hazmat",
    "un_no",
    "hazmat_class",
    "pkg_group",
    "container_type",
]

const DataTableField = ({
    value,
    column,
    row,
    error,
    classes,
    intl,
    ...rest
}) => {
    const [isOpen, setOpen] = useState(false)
    const [canBeOpen, setCanBeOpen] = useState(false)

    const handleTooltipOpen = () => {
        if (error) {
            setOpen(true)
            setCanBeOpen(true)
        }
    }

    const handleTooltipClose = () => {
        if (error) {
            setOpen(false)
            setCanBeOpen(false)
        }
    }

    return (
        <Tooltip
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            title={
                error?.id && error?.defaultMessage ? (
                    <FormattedMessage {...error} />
                ) : (
                    ""
                )
            }
            placement="bottom"
            open={isOpen}
        >
            <Input
                placeholder={
                    error
                        ? intl.formatMessage({
                              id: "generalTerms__error",
                              defaultMessage: "Error",
                          })
                        : ""
                }
                classes={{ input: classes.data__input }}
                className={error ? classes.error__field : classes.data__field}
                margin="none"
                fullWidth
                disableUnderline
                value={value ? value : ""}
                {...rest}
            />
        </Tooltip>
    )
}

const DataTableCell = ({ value, column, classes, row, intl, ...rest }) => {
    let error = get(column, "validator") && column.validator(value, row)

    return (
        <DataTableField
            {...rest}
            intl={intl}
            column={column}
            value={value}
            error={error}
            classes={classes}
            inputProps={{ style: { fontSize: "smaller" } }}
        />
    )
}

const DataTable = ({
    data,
    columns,
    onValueChange,
    onDeleteRow,
    classes,
    intl,
}) => {
    return (
        <AutoSizer>
            {({ height, width }) => (
                <Table
                    height={height}
                    width={width}
                    headerHeight={40}
                    rowCount={data.length}
                    rowGetter={({ index }) => data[index]}
                    rowHeight={24}
                    rowClassName={classes.table__row}
                >
                    {columns.map(column => (
                        <Column
                            columnData={column}
                            flexGrow={1}
                            width={width}
                            key={column.field}
                            dataKey={column.field}
                            headerRenderer={({ columnData }) => (
                                <span className={classes.table__header}>
                                    {columnData.title?.id ? (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.headingTableTitle
                                            }
                                        >
                                            <FormattedMessage
                                                {...columnData.title}
                                            />
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            className={
                                                classes.headingTableTitle
                                            }
                                        >
                                            {columnData.title}
                                        </Typography>
                                    )}
                                </span>
                            )}
                            cellRenderer={({
                                columnData,
                                rowData,
                                dataKey,
                                cellData,
                                rowIndex,
                            }) =>
                                columnData.title === "" ? (
                                    <IconButton
                                        size="small"
                                        aria-label="Delete"
                                        className={classes.delete__button}
                                        onClick={() => onDeleteRow(rowIndex)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                ) : (
                                    <DataTableCell
                                        column={columnData}
                                        row={rowData}
                                        value={cellData || ""}
                                        classes={classes}
                                        onChange={e =>
                                            onValueChange(
                                                rowIndex,
                                                dataKey,
                                                e.target.value
                                            )
                                        }
                                        intl={intl}
                                    />
                                )
                            }
                        />
                    ))}
                </Table>
            )}
        </AutoSizer>
    )
}

const ImportItemCSVModal = ({
    open,
    setOpen,
    classes,
    language = "en-us",
    intl,
    onItemImport,
    gaCategory,
    logGAEvent,
}) => {
    const [importing, setImporting] = useState(false)
    const [imported, setImported] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [result, setResult] = useState(false)
    const [invalidRows, setInvalidRows] = useState([])
    const [fixedCount, setFixedCount] = useState(0)
    const [validRows, setValidRows] = useState([])
    const [sbError, setSbError] = useState(false)
    const [sbMessage, setSbMessage] = useState("")

    const columnDef = [
        {
            field: "description",
            title: {
                id: "items.import_description",
                defaultMessage: "description",
            },
            validator: isRequired({
                field: intl.formatMessage({
                    id: "items.import_description",
                    defaultMessage: "Description",
                }),
            }),
        },
        {
            field: "pieces",
            title: {
                id: "items.import_pieces",
                defaultMessage: "Pieces",
            },
            validator: composeValidators(
                isRequired,
                isNumeric
            )({
                field: intl.formatMessage({
                    id: "items.import_pieces",
                    defaultMessage: "Pieces",
                }),
            }),
        },
        {
            field: "freight_class",
            title: {
                id: "items.import_freightclass",
                defaultMessage: "Freight Class.",
            },
            validator: composeValidators(
                isRequired,
                isValidFreightClass
            )({
                field: intl.formatMessage({
                    id: "items.import_freightclass",
                    defaultMessage: "Freight Class.",
                }),
            }),
        },
        {
            field: "weight",
            title: {
                id: "items.import_weight",
                defaultMessage: "Weight",
            },
            validator: composeValidators(
                isRequired,
                isNumeric
            )({
                field: intl.formatMessage({
                    id: "items.import_weight",
                    defaultMessage: "Weight",
                }),
            }),
        },
        {
            field: "preferred_system_of_measurement",
            title: {
                id: "items.import_preferredSystemOfMeasurement",
                defaultMessage: "Preferred System of Measurement",
            },
            width: 150,
            validator: composeValidators(
                isRequired,
                isValidPrefrenceType
            )({
                field: intl.formatMessage({
                    id: "items.import_preferredSystemOfMeasurement",
                    defaultMessage: "Preferred System of Measurement",
                }),
            }),
        },
        {
            field: "package_type",
            title: {
                id: "items.import_packageType",
                defaultMessage: "Package Type",
            },
            validator: composeValidators(
                isRequired,
                isValidPackageType
            )({
                field: intl.formatMessage({
                    id: "items.import_packageType",
                    defaultMessage: "Package Type",
                }),
            }),
        },
        {
            field: "length",
            title: {
                id: "items.import_length",
                defaultMessage: "Length",
            },
            validator: composeValidators(
                isRequired,
                isNumeric
            )({
                field: intl.formatMessage({
                    id: "items.import_length",
                    defaultMessage: "Length",
                }),
            }),
        },
        {
            field: "width",
            title: {
                id: "items.import_width",
                defaultMessage: "Width",
            },
            validator: composeValidators(
                isRequired,
                isNumeric
            )({
                field: intl.formatMessage({
                    id: "items.import_width",
                    defaultMessage: "Width",
                }),
            }),
        },
        {
            field: "height",
            title: {
                id: "items.import_height",
                defaultMessage: "Height",
            },
            validator: composeValidators(
                isRequired,
                isNumeric
            )({
                field: intl.formatMessage({
                    id: "items.import_height",
                    defaultMessage: "Height",
                }),
            }),
        },
        {
            field: "nmfc_code",
            title: {
                id: "items.import_NMFC_code",
                defaultMessage: "NMFC code",
            },
            validator: composeValidators(
                isNumeric,
                hasLength(6)
            )({
                field: intl.formatMessage({
                    id: "items.import_NMFC_code",
                    defaultMessage: "NMFC code",
                }),
            }),
        },
        {
            field: "nmfc_subcode",
            title: {
                id: "items.import_NMFC_subcode",
                defaultMessage: "NMFC subcode",
            },
            width: 40,
            validator: composeValidators(
                isNumeric,
                hasLength(2)
            )({
                field: intl.formatMessage({
                    id: "items.import_NMFC_subcode",
                    defaultMessage: "NMFC subcode",
                }),
            }),
        },
        {
            field: "stackable",
            title: {
                id: "items.import_stackable",
                defaultMessage: "Stackable",
            },
            width: 110,
            validator: composeValidators(
                isRequired,
                isValidBoolean
            )({
                field: intl.formatMessage({
                    id: "items.import_stackable",
                    defaultMessage: "Stackable",
                }),
            }),
        },
        {
            field: "hazmat",
            title: {
                id: "items.import_hazmat",
                defaultMessage: "Hazmat",
            },
            width: 40,
            validator: composeValidators(
                isRequired,
                isValidBoolean
            )({
                field: intl.formatMessage({
                    id: "items.import_hazmat",
                    defaultMessage: "Hazmat",
                }),
            }),
        },
        {
            field: "un_no",
            title: {
                id: "items__unNumber",
                defaultMessage: "UN",
            },
            validator: (value, row) =>
                composeValidators(
                    checkHazmatId,
                    isRequiredWithHazmat(row?.hazmat)
                )({
                    field: intl.formatMessage({
                        id: "items__unNumber",
                        defaultMessage: "UN",
                    }),
                })(value, row),
        },
        {
            field: "hazmat_class",
            title: {
                id: "items__hazClass",
                defaultMessage: "Hazmat Class",
            },
            validator: (value, row) =>
                composeValidators(
                    isRequiredWithHazmat(row?.hazmat),
                    isHazMatClasses
                )({
                    field: intl.formatMessage({
                        id: "items__hazClass",
                        defaultMessage: "Hazmat Class",
                    }),
                })(value, row),
        },
        {
            field: "pkg_group",
            title: {
                id: "items__pkgGroup",
                defaultMessage: "Pkg Group",
            },
            validator: (value, row) =>
                composeValidators(
                    isRequiredWithHazmat(row?.hazmat),
                    ishazMatPkgGrp
                )({
                    field: intl.formatMessage({
                        id: "items__pkgGroup",
                        defaultMessage: "Pkg Group",
                    }),
                })(value, row),
        },
        {
            field: "container_type",
            title: {
                id: "items__containerType",
                defaultMessage: "Container Type",
            },
            validator: (value, row) =>
                composeValidators(
                    isRequiredWithHazmat(row?.hazmat),
                    isHazMatContainers
                )({
                    field: intl.formatMessage({
                        id: "items__containerType",
                        defaultMessage: "Container Type",
                    }),
                })(value, row),
        },
        {
            title: "",
            width: 60,
        },
    ]

    const validateRow = row => {
        const rowErrors = columnDef
            .map(column => {
                const revalidateError =
                    column.validator &&
                    column.validator(get(row, column.field), row)
                if (revalidateError) {
                    return revalidateError
                } else return undefined
            })
            .filter(x => x)
        return rowErrors.length ? { id: row.id, errors: rowErrors } : undefined
    }

    const submitData = async data => {
        setSubmitting(true)
        try {
            const { data: importResult } = await onItemImport(data)
            setResult(importResult)
        } catch (err) {
            setSbError(false)
            setSbMessage(ItemImportError)
        } finally {
            setSubmitting(false)
        }
    }

    const handleCSVLoad = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = event => resolve(event.target.result)
            reader.onerror = reject
            reader.readAsText(file)
        })
    }

    const handleImport = async file => {
        logGAEvent(gaCategory, "Upload File Clicked")
        setImporting(true)
        setImported(false)
        setFixedCount(0)
        setInvalidRows([])
        setValidRows([])
        setResult(null)
        setSbError(false)
        setSbMessage("")
        try {
            const csvString = await handleCSVLoad(file)
            const rows = await csv({
                headers,
                checkColumn: false,
                noheader: false,
                ignoreEmpty: true,
            }).fromString(csvString)
            if (rows.length > 1000) {
                setSbMessage(contactsImportCSVTooLargeError)
                setSbError(true)
                return
            }
            const { newValidRows, newInvalidRows } = rows
                .filter(row => headers.some(key => !!row[key]))
                .map(row => {
                    const rowLength = get(row, "postal_code.length", 0)

                    if (rowLength === 0 || rowLength === 5 || rowLength === 7)
                        return row
                    return {
                        ...row,
                        postal_code: row.postal_code.padStart(5, "0"),
                    }
                })
                .reduce(
                    (prev, row, id) => {
                        const isInvalid = validateRow(row)
                        if (isInvalid) {
                            prev.newInvalidRows.push({
                                ...row,
                                id,
                            })
                        } else {
                            prev.newValidRows.push({
                                ...row,
                                id,
                            })
                        }
                        return prev
                    },
                    { newValidRows: [], newInvalidRows: [] }
                )

            setInvalidRows(newInvalidRows)
            setValidRows(newValidRows)
            setImported(true)
            setImporting(false)

            if (!newInvalidRows.length && newValidRows.length) {
                await submitData(validRows)
            }
        } catch (err) {
            setSbError(true)
            setSbMessage(ItemImportCSVLoadError(err.message))
        } finally {
            setImporting(false)
        }
    }

    const handleSubmit = async () => {
        const allData = [...validRows, ...invalidRows]
        setValidRows(allData)
        setImported(false)
        return submitData(allData)
    }

    const handleDeleteRow = id => {
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            ...invalidRows.slice(id + 1),
        ]
        const targetRow = invalidRows[id]
        const rowInvalid = validateRow(targetRow)
        if (!rowInvalid) setFixedCount(fixedCount - 1)
        setInvalidRows(newInvalidRows)
    }

    const handleValueChange = (id, field, value) => {
        const newRow = { ...invalidRows[id], [field]: value }
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            newRow,
            ...invalidRows.slice(id + 1),
        ]
        const oldValidation = validateRow(invalidRows[id])
        const newValidation = validateRow(newRow)
        if (oldValidation && !newValidation) {
            setFixedCount(fixedCount + 1)
        } else if (!oldValidation && newValidation) {
            setFixedCount(fixedCount - 1)
        }

        setInvalidRows(newInvalidRows)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth={invalidRows.length === 0 ? "sm" : "lg"}
        >
            <Grid container className={classes.content}>
                <Grid
                    direction="column"
                    item
                    container
                    xs={12}
                    className={classes.header}
                >
                    <Typography variant="h5">
                        <FormattedMessage
                            id="item.import.title"
                            defaultMessage="Import Items"
                        />
                    </Typography>
                    <Typography variant="body2">
                        <FormattedMessage
                            id="items.import_instructions"
                            defaultMessage="To import your Items, please look at the sample file below. Your upload file needs to be in the .csv format and use the same column headers."
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                            window.open(
                                `/static/import_items_sample__${language}.csv`,
                                "_blank"
                            )
                            logGAEvent(gaCategory, "Download Sample clicked")
                        }}
                    >
                        <FormattedMessage
                            id="contacts.import__downloadSample"
                            defaultMessage="Download sample"
                        />
                    </Button>
                    {importing && (
                        <CircularProgress size={30} color="secondary" />
                    )}
                    <FileUpload
                        labelText={
                            <FormattedMessage
                                id="items.import_uploadFile"
                                defaultMessage="Upload file"
                            />
                        }
                        onSubmit={file => handleImport(file)}
                        accept=".csv"
                        allowSameFile
                    />
                </Grid>
                {sbMessage && (
                    <Grid item container>
                        <Typography
                            variant="body2"
                            color={sbError ? "error" : undefined}
                        >
                            {sbMessage}
                        </Typography>
                    </Grid>
                )}
                {imported && (
                    <Grid item container direction="column">
                        <Grid item className={classes.summary}>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="items.import_summary"
                                    defaultMessage="Summary:"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <FormattedMessage
                                    id="items.import_validRows"
                                    defaultMessage="Valid records: {validRows}, invalid records: {invalidRows}"
                                    values={{
                                        validRows:
                                            validRows.length + fixedCount,
                                        invalidRows:
                                            invalidRows.length - fixedCount,
                                    }}
                                />
                            </Typography>
                            {invalidRows.length > 0 && (
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="items.import_invalidRows"
                                        defaultMessage="Please correct the validation errors below, or remove the invalid items."
                                    />
                                </Typography>
                            )}
                        </Grid>
                        {invalidRows.length > 0 && (
                            <div className={classes.table__container}>
                                <DataTable
                                    data={invalidRows}
                                    onValueChange={(...args) =>
                                        handleValueChange(...args)
                                    }
                                    onDeleteRow={index =>
                                        handleDeleteRow(index)
                                    }
                                    columns={columnDef}
                                    classes={classes}
                                    intl={intl}
                                />
                            </div>
                        )}
                        <Grid item container direction="row-reverse">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        logGAEvent(
                                            gaCategory,
                                            "Import Modal Submit Click"
                                        )
                                        handleSubmit()
                                    }}
                                    disabled={invalidRows.length !== fixedCount}
                                >
                                    <FormattedMessage
                                        id="items.import_submit"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {submitting && (
                    <Grid item container direction="column" alignItems="center">
                        <Grid item>
                            <CircularProgress size={30} color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="items.import_pleaseWait"
                                    defaultMessage="Importing items. Please wait and do not close this window..."
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {result && (
                    <Grid item container direction="column" alignItems="center">
                        {get(result, "error.length") ? (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="items.import_finished"
                                            defaultMessage="Finished importing items."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="items.import_result"
                                            defaultMessage="Created {successful} new Items, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" gutterBottom>
                                        <FormattedMessage
                                            id="items.import_unableToImport"
                                            defaultMessage="Unfortunately we were not able to import the following Items:"
                                        />
                                    </Typography>
                                </Grid>
                                {get(result, "error", []).map(
                                    ({ index, error }, key) => (
                                        <Grid item>
                                            <Typography variant="body2">
                                                <FormattedMessage
                                                    id="items.import_rowError"
                                                    defaultMessage="Name: {name}, Company name: {companyName} - {error}"
                                                    values={{
                                                        name: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        companyName: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        error,
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="items.import_finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="items.import_success"
                                            defaultMessage="Successfully finished importing items."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="items.import_result"
                                            defaultMessage="Created {successful} new Items, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <FormattedMessage
                                            id="items.import_finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                )}
            </Grid>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    language: state?.user?.profile?.preferences?.language,
})

const mapDispatchToProps = dispatch => ({
    onItemImport: async data => dispatch(importItems(data)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportItemCSVModal))
)
