import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import { uniqBy } from "lodash"
import MenuItem from "@material-ui/core/MenuItem"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import {
    Grid,
    FormControl,
    InputLabel,
    FormHelperText,
    TextField,
} from "@material-ui/core"
import Select from "react-select"
import CreatableSelect from "react-select/lib/Creatable"
import "react-select/dist/react-select.css"
import { get } from "lodash"
import FormattedError from "./formatted-error"
import { FormattedMessage } from "react-intl"
import FormBillToLocationOption from "./form-bill-to-location-option"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

class Option extends React.Component {
    handleClick = event => {
        this.props.onSelect(this.props.option, event)
    }

    render() {
        const { children, isFocused, isSelected, onFocus } = this.props

        return (
            <MenuItem
                onFocus={onFocus}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {get(children, "id") ? (
                    <FormattedMessage {...children} />
                ) : (
                    children
                )}
            </MenuItem>
        )
    }
}

function SelectWrapped(props) {
    const { classes, options = [], category, ...other } = props
    const { logGAEvent } = useGAContext()

    const FormBillToLocationOptionWrapper = props => {
        return <FormBillToLocationOption {...props} />
    }

    const customFilter = (option, match) => {
        return (
            option.label
                .toString()
                .toLowerCase()
                .includes(match) ||
            get(option, "fedexBillToAccount", "")
                .toString()
                .includes(match) ||
            get(option, "fedexFreightAccount", "")
                .toString()
                .includes(match)
        )
    }

    const customStyles = {
        input: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
            backgroundColor: "red",
            ...provided,
        }),
    }

    return (
        <Select
            {...other}
            styles={customStyles}
            placeholder=""
            options={options.map(option => ({
                value: option.value,
                label: option.label.id ? (
                    <FormattedMessage {...option.label} />
                ) : (
                    option.label
                ),
                ...option,
            }))}
            optionComponent={FormBillToLocationOptionWrapper}
            onChange={option => {
                props.onChange(options.find(opt => opt?.value === option))
                logGAEvent(
                    category,
                    `Update Select Field: ${props.name ?? props.id}`,
                    option
                )
            }}
            noResultsText={
                <Typography variant="body2">
                    <FormattedMessage
                        id="generalTerms__noResults"
                        defaultMessage="No results found"
                    />
                </Typography>
            }
            arrowRenderer={arrowProps =>
                arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
            }
            style={{
                minWidth: "200px",
            }}
            filterOption={customFilter}
        />
    )
}

function CreatableSelectWrapped(props) {
    const { classes, ...other } = props

    return (
        <CreatableSelect
            optionComponent={Option}
            noResultsText={
                <Typography variant="body2">
                    <FormattedMessage
                        id="generalTerms__noResults"
                        defaultMessage="No results found"
                    />
                </Typography>
            }
            arrowRenderer={arrowProps =>
                arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
            }
            {...other}
        />
    )
}

const ITEM_HEIGHT = 48

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    chip: {
        margin: theme.spacing(0.25),
    },
    form__container: {
        padding: "3px",
        width: "100%",
    },
    "@global": {
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none",
            },
            paddingLeft: "0px",
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
        },
        ".Select--multi .Select-input": {
            margin: 0,
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
        },
        ".Select-noresults": {
            padding: theme.spacing(2),
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
        },
        ".Select--single": {
            paddingBottom: "6px",
            paddingTop: "5px",
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            // cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black,
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing(1)}px)`,
            width: "100%",
            zIndex: 2,
            maxHeight: ITEM_HEIGHT * 4.5,
        },
        ".Select--single > .Select-control .Select-value": {
            paddingLeft: "0px",
        },
        ".Select-value": {
            paddingLeft: "0px",
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
        },
        ".Select.is-focused > .Select-control": {
            backgroundColor: "rgb(242, 242, 242)",
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto",
        },
        ".Select-menu div": {
            boxSizing: "content-box",
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            // cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1,
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1,
        },
        ".Select.is-disabled > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "currentColor",
        },
    },
})

const FormSelectBillTo = ({
    input,
    label,
    options,
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    InputTextProps,
    selectClasses,
    customValue,
    meta: { touched, error },
    required,
    onBillToLocationChange,
    category,
    ...rest
}) => {
    const customSelectComponent = customValue
        ? CreatableSelectWrapped
        : SelectWrapped
    return (
        <Grid
            item
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                <TextField
                    error={error}
                    helperText={
                        error && touched ? <FormattedError error={error} /> : ""
                    }
                    id={input.name}
                    required={required}
                    htmlFor={input.name}
                    label={label}
                    InputLabelProps={{ shrink: input.value !== "" }}
                    InputProps={{
                        ...rest,
                        inputComponent: customSelectComponent,
                        classes: selectClasses,
                        id: input.name,
                        inputProps: {
                            classes,
                            name: input.name,
                            instanceId: input.name,
                            simpleValue: true,
                            required: true,
                            clearable: false,
                            category: category,
                            options: customValue
                                ? uniqBy(
                                      [
                                          ...options,
                                          {
                                              value: input.value,
                                              label: input.value,
                                          },
                                      ],
                                      "value"
                                  )
                                : options,
                            ...InputTextProps,
                        },
                        ...InputProps,
                        ...input,
                        onBlur: () => input.onBlur(input.value),
                    }}
                ></TextField>
            </Grid>
        </Grid>
    )
}

FormSelectBillTo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormSelectBillTo)
