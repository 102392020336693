import { defineMessages } from "react-intl"

const provideInternationalizedValues = (intl, array) => {
    return array.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

// Measurement System
// 1. Internationalization
export const measurementSystemMessages = defineMessages({
    IMPERIAL: {
        id: "items.measurementSystem.imperial",
        defaultMessage: "Imperial",
    },
    METRIC: {
        id: "items.measurementSystem.metric",
        defaultMessage: "Metric",
    },
})

// 2. Option Arrays
export const measurementSystemOptions = [
    {
        value: "IMPERIAL",
        label: measurementSystemMessages.IMPERIAL,
    },
    {
        value: "METRIC",
        label: measurementSystemMessages.METRIC,
    },
]

// 3. Providers
export const provideMeasurementSystem = intl => {
    return provideInternationalizedValues(intl, measurementSystemOptions)
}
// Freight Class
export const freightClasses = [
    { value: 50, label: "50" },
    { value: 55, label: "55" },
    { value: 60, label: "60" },
    { value: 65, label: "65" },
    { value: 70, label: "70" },
    { value: 77.5, label: "77.5" },
    { value: 85, label: "85" },
    { value: 92.5, label: "92.5" },
    { value: 100, label: "100" },
    { value: 110, label: "110" },
    { value: 125, label: "125" },
    { value: 150, label: "150" },
    { value: 175, label: "175" },
    { value: 200, label: "200" },
    { value: 250, label: "250" },
    { value: 300, label: "300" },
    { value: 400, label: "400" },
    { value: 500, label: "500" },
]

//Package Type
// 1. Internationalization
export const packageTypesMessages = defineMessages({
    PLT4840: {
        id: "items.packageTypes__PLT4840",
        defaultMessage: "Pallet 48x40",
    },
    PLT4848: {
        id: "items.packageTypes__PLT4848",
        defaultMessage: "Pallet 48x48",
    },
    PLT: {
        id: "items.packageTypes__PLT",
        defaultMessage: "Pallet",
    },
    SKID: {
        id: "items.packageTypes__SKID",
        defaultMessage: "Skid",
    },
    BAG: {
        id: "items.packageTypes__BAG",
        defaultMessage: "Bag",
    },
    BOX: {
        id: "items.packageTypes__BOX",
        defaultMessage: "Box",
    },
    BUNDLE: {
        id: "items.packageTypes__BUNDLE",
        defaultMessage: "Bundle",
    },
    COIL: {
        id: "items.packageTypes__COIL",
        defaultMessage: "Coil",
    },
    CRATE: {
        id: "items.packageTypes__CRATE",
        defaultMessage: "Crate",
    },
    CYLINDER: {
        id: "items.packageTypes__CYLINDER",
        defaultMessage: "Cylinder",
    },
    DRUM: {
        id: "items.packageTypes__DRUM",
        defaultMessage: "Drum",
    },
    PAIL: {
        id: "items.packageTypes__PAIL",
        defaultMessage: "Pail",
    },
    REEL: {
        id: "items.packageTypes__REEL",
        defaultMessage: "Reel",
    },
    ROLL: {
        id: "items.packageTypes__ROLL",
        defaultMessage: "Roll",
    },
    TUBE: {
        id: "items.packageTypes__TUBE",
        defaultMessage: "Tube & Pipe",
    },
    FBXP: {
        id: "items.packageTypes__Standard",
        defaultMessage: "Standard 38",
    },
    FBXI: {
        id: "items.packageTypes__Small",
        defaultMessage: "Small 28",
    },
})

// 2. Option Arrays
export const packageTypeOptions = [
    {
        value: "PLT4840",
        label: packageTypesMessages.PLT4840,
        dimensions: { length: 48, width: 40 },
    },
    {
        value: "PLT4848",
        label: packageTypesMessages.PLT4848,
        dimensions: { length: 48, width: 48 },
    },
    { value: "PLT", label: packageTypesMessages.PLT },
    { value: "SKID", label: packageTypesMessages.SKID },
    { value: "BAG", label: packageTypesMessages.BAG },
    { value: "BOX", label: packageTypesMessages.BOX },
    { value: "BUNDLE", label: packageTypesMessages.BUNDLE },
    { value: "COIL", label: packageTypesMessages.COIL },
    { value: "CRATE", label: packageTypesMessages.CRATE },
    { value: "CYLINDER", label: packageTypesMessages.CYLINDER },
    { value: "DRUM", label: packageTypesMessages.DRUM },
    { value: "PAIL", label: packageTypesMessages.PAIL },
    { value: "REEL", label: packageTypesMessages.REEL },
    { value: "ROLL", label: packageTypesMessages.ROLL },
    { value: "TUBE", label: packageTypesMessages.TUBE },
]
export const freightBoxPackageTypeOptions = [
    { value: "FBXP", label: packageTypesMessages.FBXP },
    { value: "FBXI", label: packageTypesMessages.FBXI },
]
export const packageTypeValues = packageTypeOptions.map(item => item.value)

export const freightBoxPackageTypeValues = freightBoxPackageTypeOptions.map(
    item => item.value
)

export const allPackageTypeOptions = [
    ...packageTypeOptions,
    ...freightBoxPackageTypeOptions,
]

// 3. Providers
export const providePackageTypes = intl => {
    return provideInternationalizedValues(intl, packageTypeOptions)
}

export const provideAllPackageTypes = intl => {
    return provideInternationalizedValues(intl, allPackageTypeOptions)
}

// HazMat: Package Group
// 1. Internationalization
export const hazMatPkgGrpMessages = defineMessages({
    I: {
        id: "items.hazMatPkgGrp__I",
        defaultMessage: "I",
    },
    II: {
        id: "items.hazMatPkgGrp__II",
        defaultMessage: "II",
    },
    III: {
        id: "items.hazMatPkgGrp__III",
        defaultMessage: "III",
    },
    NONE: {
        id: "items.hazMatPkgGrp__none",
        defaultMessage: "None",
    },
})
// 2. Option Array
export const hazMatPkgGrp = [
    {
        value: "I",
        label: hazMatPkgGrpMessages.I,
    },
    {
        value: "II",
        label: hazMatPkgGrpMessages.II,
    },
    {
        value: "III",
        label: hazMatPkgGrpMessages.III,
    },
    {
        value: "NONE",
        label: hazMatPkgGrpMessages.NONE,
    },
]
// 3. Provider
export const provideHazMatPkgGrp = intl => {
    return provideInternationalizedValues(intl, hazMatPkgGrp)
}
// HazMat Classes
// 1. Internationalization
export const hazMatClassesMessages = defineMessages({
    "1": {
        id: "items__hazmat__explosives",
        defaultMessage: "1 Explosives",
    },
    "1.1": {
        id: "items__hazmat__explosives_1.1",
        defaultMessage: "1.1 Mass Explosive Hazard",
    },
    "1.2": {
        id: "items__hazmat__explosives_1.2",
        defaultMessage: "1.2 Projection Hazard",
    },
    "1.3": {
        id: "items__hazmat__explosives_1.3",
        defaultMessage: "1.3 Fire and/or Minor Blast/Minor Projection Hazard",
    },
    "1.4": {
        id: "items__hazmat__explosives_1.4",
        defaultMessage: "1.4 Minor Explosion Hazard",
    },
    "1.5": {
        id: "items__hazmat__explosives_1.5",
        defaultMessage: "1.5 Very Insensitive With Mass Explosion Hazard",
    },
    "1.6": {
        id: "items__hazmat__explosives_1.6",
        defaultMessage: "1.6 Extremely Insensitive; No Mass Explosion Hazard",
    },
    "2": {
        id: "items__hazmat__gases",
        defaultMessage: "2 Gases",
    },
    "2.1": {
        id: "items__hazmat__gases_2.1",
        defaultMessage: "2.1 Flammable Gases",
    },
    "2.2": {
        id: "items__hazmat__gases_2.2",
        defaultMessage: "2.2 Nonflammable Gases",
    },
    "2.3": {
        id: "items__hazmat__gases_2.3",
        defaultMessage: "2.3 Toxic Gases",
    },
    "3": {
        id: "items__hazmat__liquids",
        defaultMessage: "3 Flammable and Combustible Liquids",
    },
    "4": {
        id: "items__hazmat__solids",
        defaultMessage: "4 Flammable Solids",
    },
    "4.1": {
        id: "items__hazmat__solids__4.1",
        defaultMessage: "4.1 Flammable Solids",
    },
    "4.2": {
        id: "items__hazmat__solids_4.2",
        defaultMessage: "4.2 Spontaneously Combustible",
    },
    "4.3": {
        id: "items__hazmat__solids__4.3",
        defaultMessage: "4.3 Dangerous When Wet",
    },
    "5": {
        id: "items__hazmat__subtstances__peroxides",
        defaultMessage: "5 Oxidizing Substances, Organic Peroxides",
    },
    "5.1": {
        id: "items__hazmat__subtstances__peroxides__5.1",
        defaultMessage: "5.1 Oxidizing Substances",
    },
    "5.2": {
        id: "items__hazmat__subtstances__peroxides__5.2",
        defaultMessage: "5.2 Organic Peroxides",
    },
    "6": {
        id: "items__hazmat__subtstances",
        defaultMessage: "6 Toxic Substances and Infectious Substances",
    },
    "6.1": {
        id: "items__hazmat__subtstances__6.1",
        defaultMessage: "6.1 Toxic Substances",
    },
    "6.2": {
        id: "items__hazmat__subtstances__6.2",
        defaultMessage: "6.2 Infectious Substances",
    },
    "7": {
        id: "items__hazmat__readioactive",
        defaultMessage: "7 Radioactive Material",
    },
    "8": {
        id: "items__hazmat__corrossives",
        defaultMessage: "8 Corrosives (Liquids And Solids)",
    },
    "9": {
        id: "items__hazmat__miscellaneous",
        defaultMessage: "9 Miscellaneous Hazardous Materials",
    },
})

// Option Arrays
export const hazMatClasses = [
    { value: "1", label: hazMatClassesMessages["1"] },
    { value: "1.1", label: hazMatClassesMessages["1.1"] },
    { value: "1.2", label: hazMatClassesMessages["1.2"] },
    { value: "1.3", label: hazMatClassesMessages["1.3"] },
    { value: "1.4", label: hazMatClassesMessages["1.4"] },
    { value: "1.5", label: hazMatClassesMessages["1.5"] },
    { value: "1.6", label: hazMatClassesMessages["1.6"] },
    { value: "2", label: hazMatClassesMessages["2"] },
    { value: "2.1", label: hazMatClassesMessages["2.1"] },
    { value: "2.2", label: hazMatClassesMessages["2.2"] },
    { value: "2.3", label: hazMatClassesMessages["2.3"] },
    { value: "3", label: hazMatClassesMessages["3"] },
    { value: "4", label: hazMatClassesMessages["4"] },
    { value: "4.1", label: hazMatClassesMessages["4.1"] },
    { value: "4.2", label: hazMatClassesMessages["4.2"] },
    { value: "4.3", label: hazMatClassesMessages["4.3"] },
    { value: "5", label: hazMatClassesMessages["5"] },
    { value: "5.1", label: hazMatClassesMessages["5.1"] },
    { value: "5.2", label: hazMatClassesMessages["5.2"] },
    { value: "6", label: hazMatClassesMessages["6"] },
    { value: "6.1", label: hazMatClassesMessages["6.1"] },
    { value: "6.2", label: hazMatClassesMessages["6.2"] },
    { value: "7", label: hazMatClassesMessages["7"] },
    { value: "8", label: hazMatClassesMessages["8"] },
    { value: "9", label: hazMatClassesMessages["9"] },
]

// 3. Provider
export const provideHazMatClasses = intl => {
    return hazMatClasses.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

//HazMat: Containers

// 1. Internationalization
export const hazMatContainersMessages = defineMessages({
    CTN: {
        id: "items__hazmat__containers__CTN",
        defaultMessage: "CTN",
    },
    Cylinder: {
        id: "items__hazmat__containers__Cylinder",
        defaultMessage: "Cylinder",
    },
    Drum: {
        id: "items__hazmat__containers__Drum",
        defaultMessage: "Drum",
    },
    Tote: {
        id: "items__hazmat__containers__Tote",
        defaultMessage: "Tote",
    },
    Bottle: {
        id: "items__hazmat__containers__Bottle",
        defaultMessage: "Bottle",
    },
    Bucket: {
        id: "items__hazmat__containers__Bucket",
        defaultMessage: "Bucket",
    },
    Can: {
        id: "items__hazmat__containers__Can",
        defaultMessage: "Can",
    },
    Carboy: {
        id: "items__hazmat__containers__Carboy",
        defaultMessage: "Carboy",
    },
    Crate: {
        id: "items__hazmat__containers__Crate",
        defaultMessage: "Crate",
    },
    Dewar: {
        id: "items__hazmat__containers__Dewar",
        defaultMessage: "Dewar",
    },
    IBC: {
        id: "items__hazmat__containers__IBC",
        defaultMessage: "IBC",
    },
    Keg: {
        id: "items__hazmat__containers__Keg",
        defaultMessage: "Keg",
    },
    Pail: {
        id: "items__hazmat__containers__Pail",
        defaultMessage: "Pail",
    },
    Tank: {
        id: "items__hazmat__containers__Tank",
        defaultMessage: "Tank",
    },
    "CTN/Crate": {
        id: "items__hazmat__containers__CTN/Crate",
        defaultMessage: "CTN/Crate",
    },
    "CTN/Drum": {
        id: "items__hazmat__containers__CTN/Drum",
        defaultMessage: "CTN/Drum",
    },
    "Drum/Crate": {
        id: "items__hazmat__containers__Drum/Crate",
        defaultMessage: "Drum/Crate",
    },
    Bag: {
        id: "items__hazmat__containers__Bag",
        defaultMessage: "Bag",
    },
    Jerrican: {
        id: "items__hazmat__containers__Jerrican",
        defaultMessage: "Jerrican",
    },
    Overpack: {
        id: "items__hazmat__containers__Overpack",
        defaultMessage: "Overpack",
    },
    "No PKG": {
        id: "items__hazmat__containers__No__PKG",
        defaultMessage: "No PKG",
    },
    Case: {
        id: "items__hazmat__containers__Case",
        defaultMessage: "Case",
    },
    "Large Packaging": {
        id: "items__hazmat__containers__large__packaging",
        defaultMessage: "Large Packaging",
    },
    "Jar/Drum": {
        id: "items__hazmat__containers__Jar/Drum",
        defaultMessage: "Jar/Drum",
    },
    PKG: {
        id: "items__hazmat__containers__PKG",
        defaultMessage: "PKG",
    },
    Barrel: {
        id: "items__hazmat__containers__Barrel",
        defaultMessage: "Barrel",
    },
    Cage: {
        id: "items__hazmat__containers__Cage",
        defaultMessage: "Cage",
    },
    "No Pkg/Skid": {
        id: "items__hazmat__containers__No__Pkg/Skid",
        defaultMessage: "No Pkg/Skid",
    },
    Box: {
        id: "items__hazmat__containers__Box",
        defaultMessage: "Box",
    },
    "Mini - Portafeed": {
        id: "items__hazmat__containers__Mini__Portafeed",
        defaultMessage: "Mini - Portafeed",
    },
})

// 2. Option Arrays
export const hazMatContainers = [
    { value: "CTN", label: hazMatContainersMessages.CTN },
    { value: "Cylinder", label: hazMatContainersMessages.Cylinder },
    { value: "Drum", label: hazMatContainersMessages.Drum },
    { value: "Tote", label: hazMatContainersMessages.Tote },
    { value: "Bottle", label: hazMatContainersMessages.Bottle },
    { value: "Bucket", label: hazMatContainersMessages.Bucket },
    { value: "Can", label: hazMatContainersMessages.Can },
    { value: "Carboy", label: hazMatContainersMessages.Carboy },
    { value: "Crate", label: hazMatContainersMessages.Crate },
    { value: "Dewar", label: hazMatContainersMessages.Dewar },
    { value: "IBC", label: hazMatContainersMessages.IBC },
    { value: "Keg", label: hazMatContainersMessages.Keg },
    { value: "Pail", label: hazMatContainersMessages.Pail },
    { value: "Tank", label: hazMatContainersMessages.Tank },
    { value: "CTN/Crate", label: hazMatContainersMessages["CTN/Crate"] },
    { value: "CTN/Drum", label: hazMatContainersMessages["CTN/Drum"] },
    { value: "Drum/Crate", label: hazMatContainersMessages["Drum/Crate"] },
    { value: "Bag", label: hazMatContainersMessages.Bag },
    { value: "Jerrican", label: hazMatContainersMessages.Jerrican },
    { value: "Overpack", label: hazMatContainersMessages.Overpack },
    { value: "No PKG", label: hazMatContainersMessages["No PKG"] },
    { value: "Case", label: hazMatContainersMessages.Case },
    {
        value: "Large Packaging",
        label: hazMatContainersMessages["Large Packaging"],
    },
    { value: "Jar/Drum", label: hazMatContainersMessages["Jar/Drum"] },
    { value: "PKG", label: hazMatContainersMessages.PKG },
    { value: "Barrel", label: hazMatContainersMessages.Barrel },
    { value: "Cage", label: hazMatContainersMessages.Cage },
    { value: "No Pkg/Skid", label: hazMatContainersMessages["No Pkg/Skid"] },
    { value: "Box", label: hazMatContainersMessages.Box },
    {
        value: "Mini - Portafeed",
        label: hazMatContainersMessages["Mini - Portafeed"],
    },
]

// 3. Providers
export const provideHazMatContainers = intl => {
    return provideInternationalizedValues(intl, hazMatContainers)
}

//Freight Box Package Type
// 1. Internationalization
export const freightBoxPackageTypesMessages = defineMessages({
    Standard: {
        id: "items.packageTypes__Standard",
        defaultMessage: "Standard 38",
    },
    Small: {
        id: "items.packageTypes__Small",
        defaultMessage: "Small 28",
    },
})
// 2. Option Arrays
export const freightBoxPackageTypes = [
    {
        value: "FBXP",
        label: freightBoxPackageTypesMessages.Standard,
    },
    {
        value: "FBXI",
        label: freightBoxPackageTypesMessages.Small,
    },
]

// 3. Providers
export const provideFreightBoxPackageTypes = intl => {
    return freightBoxPackageTypes.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}
