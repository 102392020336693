import React, { Component } from "react"
import { get } from "lodash"
import { connect } from "react-redux"
import { withStyles, Grid, Chip, Avatar, Button } from "@material-ui/core"
import { arrayPush, arrayRemove, Field, resetSection } from "redux-form"
import CancelIcon from "@material-ui/icons/Cancel"
import EmailIcon from "@material-ui/icons/Email"
import FormField from "../../form/form-field"
import createSelector from "re-reselect"
import { FormattedMessage } from "react-intl"
import { trackGAEvent } from "../../../actions/user"

const styles = theme => ({
    addRecipients__container: {
        width: "800px",
    },
    button__container: {
        marginTop: "10px",
    },
    margin: {
        margin: theme.spacing(1),
    },
    chip_container: {
        maxHeight: "141px",
        overflow: "auto",
    },
    chip: {
        margin: "5px 5px",
        backgroundColor: theme.palette.primary.light,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    select__container: {
        padding: "10px",
        width: "90%",
        minHeight: "225px",
    },
    select__container__direct: {
        padding: "10px",
        width: "90%",
        minHeight: "105px",
    },
    add__recipient: {
        marginTop: "5px",
        justifyContent: "flex-end",
    },
    title: {
        marginTop: "10px",
    },
    or__text: {
        marginTop: "18px",
    },
})

const mapRecipients = contacts => {
    const recipients = []
    contacts.forEach(contact => {
        const contactInfo = get(contact, "contact", {})
        recipients.push({
            values: [
                {
                    type: "phone",
                    value: contactInfo?.phone?.phoneNumber || "",
                },
                {
                    type: "email",
                    value: contactInfo?.email?.emailAddress || "",
                },
            ],
            label: contactInfo?.name,
        })
    })
    return recipients
}

const mapUserLocationRecipients = users => {
    const recipients = []
    users.forEach(user => {
        recipients.push({
            values: [
                {
                    type: "phone",
                    value: user?.user?.phone_number?.phone_number ?? "",
                },
                {
                    type: "email",
                    value: user?.user?.email?.email_address ?? "",
                },
            ],
            label: `${user?.user?.firstname ??
                user?.user?.email?.email_address ??
                ""}  ${user?.lastname ?? ""}`,
        })
    })
    return recipients
}

class AddRecipients extends Component {
    render() {
        const {
            classes,
            addNewRecipient,
            onDelete,
            selectedRecipients,
            newEmailRecipient,
            emailError,
            gaCategory,
            trackGA,
        } = this.props
        return (
            <Grid
                className={classes.addRecipients__container}
                item
                container
                xs={12}
                direction="column"
            >
                <Grid item>
                    <Grid item style={{ width: "100%" }}>
                        <Field
                            id="addUser__addNewEmail"
                            component={FormField}
                            name="newEmailRecipient"
                            label={
                                <FormattedMessage
                                    id="locations.addRecipients__addNewEmail"
                                    defaultMessage="Add New Email Address"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container className={classes.add__recipient}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={!!emailError}
                            onClick={() => {
                                addNewRecipient(newEmailRecipient)
                                trackGA(gaCategory, "Add Recipient Click")
                            }}
                            id="addUser__addRecipient"
                        >
                            {
                                <FormattedMessage
                                    id="locations.addRecipients__addRecipient"
                                    defaultMessage="Add Recipient"
                                />
                            }
                        </Button>
                    </Grid>
                </Grid>
                <Grid item className={classes.chip_container}>
                    {selectedRecipients &&
                        selectedRecipients.map((contact, i) => (
                            <Chip
                                tabIndex={-1}
                                avatar={
                                    <Avatar className={classes.chip__avatar}>
                                        <EmailIcon />
                                    </Avatar>
                                }
                                key={i}
                                label={contact.label}
                                className={classes.chip}
                                deleteIcon={<CancelIcon />}
                                onDelete={() => {
                                    onDelete(i)
                                    trackGA(
                                        gaCategory,
                                        "Delete Recipient Chip Click"
                                    )
                                }}
                            />
                        ))}
                </Grid>
            </Grid>
        )
    }
}

const getNonDuplicateContacts = (contactList, selectedRecipients) => {
    const newContacts = []
    contactList.forEach(c => {
        let contactEmail = c.values.find(x => x.type === "email").value
        const emailSelected = selectedRecipients.find(
            s =>
                get(s, "value.type", "") === "email" &&
                s.value.value === contactEmail
        )
        const newOption = {
            values: [
                ...(!emailSelected
                    ? [
                          {
                              value: contactEmail,
                              type: "email",
                          },
                      ]
                    : []),
            ],
            label: c.label,
        }
        if (newOption.values.length) newContacts.push(newOption)
    })
    return newContacts
}

const mapStateToProps = createSelector(
    state => state.addressBook.addresses,
    (state, props) =>
        get(state, `form.${props.formName}.syncErrors.newEmailRecipient`),
    (state, props) =>
        get(state, `form.${props.formName}.values.selectedRecipients`, []),
    (state, props) =>
        get(state, `form.${props.formName}.values.newEmailRecipient`),
    (state, props) => get(props, `location`),
    (state, props) => get(props, `isFreightDirect`),
    (
        contacts,
        emailError,
        selectedRecipients,
        newEmailRecipient,
        location,
        isFreightDirect
    ) => {
        let mappedContacts = mapRecipients(contacts)
        if (isFreightDirect) {
            mappedContacts = mapUserLocationRecipients(location?.users)
        }

        return {
            contacts: getNonDuplicateContacts(
                mappedContacts,
                selectedRecipients
            ),
            emailError,
            selectedRecipients,
            newEmailRecipient,
        }
    }
)((state, props) => props.formName)

const mapDispatchToProps = (dispatch, props) => ({
    addNewRecipient: email => {
        if (!email) return
        dispatch(resetSection(props.formName, "newEmailRecipient"))
        dispatch(
            arrayPush(props.formName, "selectedRecipients", {
                value: { value: email, type: "email" },
                label: email,
            })
        )
    },
    onDelete: (index, subject) => {
        dispatch(arrayRemove(props.formName, "selectedRecipients", index))
    },
    trackGA: (category, action, value) =>
        dispatch(trackGAEvent(category, action, value)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AddRecipients)
)
