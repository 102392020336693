import React from "react"
import { Typography, withStyles } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FormSwitch from "../../form/form-switch"
import { Field } from "redux-form"
import FormCapacityField from "../../form/form-switch-with-capacity-field"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    document__icon: {
        marginTop: 10,
    },
    spacer: {
        width: "64px",
        height: "44px",
    },
})

const ShipmentRestrictions = props => {
    const {
        classes,
        dirty,
        handleSave,
        handleSubmit,
        location,
        gaCategory,
    } = props

    const { capacityLoadLength } = useFlags()
    const { requireInvoiceDocument } = useFlags()
    const canadaRateEnabled =
        location?.fedexFreightAccountCapabilities?.domesticCA ||
        location?.fedexBillToAccountCapabilities?.domesticCA ||
        (!location?.fedexFreightAccountCapabilities &&
            !location?.fedexBillToAccountCapabilities)

    return (
        <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            alignContent="flex-start"
            className={classes.location__card__header}
        >
            <Grid item container alignItems="center" xs={12}>
                <Typography gutterBottom variant="h6">
                    <FormattedMessage
                        id="locations.shipmentRestrictions__title"
                        defaultMessage="Shipment Restrictions"
                    />
                </Typography>
            </Grid>

            <Grid
                container
                direction="row"
                alignItems="flex-start"
                alignContent="center"
            >
                <Grid item container>
                    <Grid item container>
                        <Field
                            name="shipmentRestrictions.requirePoNumber"
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="locations.requirePO__toggle"
                                    defaultMessage="Require PO # before dispatch"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container>
                        <Field
                            name="shipmentRestrictions.requireRefId"
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="locations.requireReferenceID__toggle"
                                    defaultMessage="Require reference ID before dispatch"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    {requireInvoiceDocument && (
                        <Grid item container>
                            <Field
                                name="shipmentRestrictions.requireSupportInvoice"
                                component={FormSwitch}
                                label={
                                    <FormattedMessage
                                        id="locations.requireSupportInvoice__toggle"
                                        defaultMessage="Require supporting invoice document"
                                    />
                                }
                                category={gaCategory}
                            />
                        </Grid>
                    )}

                    <Grid item container alignItems="center" xs={12}>
                        <Field
                            name="shipmentRestrictions.restrictOverLengthShipments"
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="locations.restrictOverLengthShipments__toggle"
                                    defaultMessage="Restrict over-length shipments"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    <Grid item container alignItems="center" xs={12}>
                        <Field
                            name="shipmentRestrictions.restrictExtremeLengthShipments"
                            component={FormSwitch}
                            label={
                                <FormattedMessage
                                    id="locations.restrictExtremeLengthShipments__toggle"
                                    defaultMessage="Restrict extreme-length shipments"
                                />
                            }
                            category={gaCategory}
                        />
                    </Grid>
                    {capacityLoadLength && (
                        <Grid item container alignItems="center" xs={12}>
                            <Grid className={classes.spacer}></Grid>
                            <Field
                                name="shipmentRestrictions.capacityShipmentLength"
                                location={location}
                                component={FormCapacityField}
                                min={15}
                                max={48}
                                label={
                                    <FormattedMessage
                                        id="locations.capacityShipmentLength__toggle"
                                        defaultMessage="Enforce capacity shipments at"
                                    />
                                }
                                unit="feet"
                                category={gaCategory}
                            />
                        </Grid>
                    )}

                    {canadaRateEnabled && (
                        <React.Fragment>
                            <Grid item container alignItems="center" xs={12}>
                                <Grid className={classes.spacer}></Grid>
                                <Field
                                    name="shipmentRestrictions.pcfIntraCanada"
                                    location={location}
                                    component={FormCapacityField}
                                    min={1}
                                    max={100}
                                    label={
                                        <FormattedMessage
                                            id="locations.pcf__intra__canada__toggle"
                                            defaultMessage="Pounds Per Cubic Food (PCF) Intra-Canada"
                                        />
                                    }
                                    unit=""
                                    category={gaCategory}
                                />
                            </Grid>

                            <Grid item container alignItems="center" xs={12}>
                                <Grid className={classes.spacer}></Grid>
                                <Field
                                    name="shipmentRestrictions.pcfIntraCanadaNewfoundland"
                                    location={location}
                                    component={FormCapacityField}
                                    min={1}
                                    max={100}
                                    label={
                                        <FormattedMessage
                                            id="locations.pcf__intra__canada__Newfoundland__toggle"
                                            defaultMessage="Pounds Per Cubic Food (PCF) Newfoundland"
                                        />
                                    }
                                    unit=""
                                    category={gaCategory}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(ShipmentRestrictions)
